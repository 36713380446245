.layout__tour-start-banner {
    background-color: #F4F6F8;
    height: 40px;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 2rem;
    box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.2);
    text-align: center;
}
.layout__tour-start-link {
    /* text-decoration: underline; */
    color: #ff2754;
    font-weight: bold;
    cursor: pointer;
}
.layout__tour-banner-close {
    width: 1.2rem;
}
.layout__tour-start-banner .Polaris-Icon {
    height: 1rem;
    width: 1rem;
}
.layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
}
.layout__main {
    width: 100%;
}
.layout__tour-start {
    text-align: center;
}
.layout__tour-start h3 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0.6rem;
    margin-top: 2rem;
}
.layout__tour-start p {
    color: #615b67;
    font-size: 12px;
    line-height: 16px;
}
.layout__tour-step {
    margin-top: 1.5rem;
    padding: 1rem 2rem;
}
.layout__tour-header {
    background-image: url('../../assets/tour-intro.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 300px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}
.reactour__helper {
    padding: 0 !important;
    padding-right: 0 !important;
}
/* .ipdSrf .sc-ifAKCX .bQzyOe .reactour__helper .reactour__helper--is-open .layout__tour-step {
    padding: 0 !important;
    padding-right: 0 !important;
} */
.layout__tour-list-item {
    display: flex;
    align-items: center;
}
.layout__tour-step h3 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0.6rem;
    margin-top: 1.5rem;
}
.layout__tour-step p {
    color: #615b67;
    font-size: 12px;
    line-height: 16px;
}
.layout__tour-spacer {
    margin-top: 1rem;
}
.layout__tour-item {
    margin: 0.25rem 0;
}
.layout__tour-item-rev {
    background-image: url('../../assets/tour-revenue-icon.svg');
    background-repeat: no-repeat;
    margin: 0.5rem 0;
}
.layout__tour-item-rev p {
    margin-left: 2.25rem;
}
.layout__tour-item-prod {
    background-image: url('../../assets/tour-products-icon.svg');
    background-repeat: no-repeat;
    margin: 0.5rem 0;
}
.layout__tour-item-prod p {
    margin-left: 2.25rem;
}
.layout__tour-item-amb {
    background-image: url('../../assets/tour-ambassadors-icon.svg');
    background-repeat: no-repeat;
    margin: 0.5rem 0;
}
.layout__tour-item-amb p {
    margin-left: 2.25rem;
}

.layout__tour-item p {
    margin-left: 2rem;
}
.layout__tour-list {
    margin-top: 1rem;
}
/* .layout__tour-list-img {
    height: 1.4rem;
} */
.layout__tour-button-wrapper {
    width: 170px;
    margin: 1rem auto;
}
.layout__tour-progress {
    display: block;
    margin: 2rem 0 1rem;
    height: 8px;
    width: 100%;
    border-radius: 4px;
    background-color: #f2f2f2;
    transition: width .2s ease-in-out;
}
.layout__progress-color {
    background-color: #ff2754;
}

#___reactour [data-tour-elem="controls"] {
    justify-content: flex-end;
    padding: 0 2rem 2rem 0;
    margin-top: 1rem;
}
#___reactour [data-tour-elem="left-arrow"] {
    color: #ff2754;
    margin-right: 0;
}
#___reactour [data-tour-elem="right-arrow"] {
    color: #ff2754;
    margin-left: 15px;
}
#___reactour [data-tour-elem="badge"] {
    background: transparent;
    background-color: transparent;
    font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
    box-shadow: none;
    transform: none;
    color: #000000;
    top: 14px;
    left: 11px;
    font-size: 1rem;
    padding-bottom: 1rem;
}
#___reactour [data-tour-elem="dot"] {
    border: 1px solid #5D447C;
}
.reactour__close {
    top: 15px !important;
    right: 15px !important;
}

@media screen and (max-width: 599px) {

    .layout {
        flex-direction: column;
        width: 100%;
    }

    .layout__sidebar {
        width: 100%;
    }

    .layout__header {
        display: none;
    }

    .layout__tour-start-banner {
        height: 60px;
        font-size: 1.2rem;
        line-height: 1.6rem;
    }

}