#chat .chat-window{
    /* page height - header bar height - header bar margin - page title area - chat margin bottom */
    height: calc(100vh - 8rem - 2rem - 7rem - 3rem);
}

@media screen and (max-width: 900px) {
    #chat .chat-window{
        /* page height - header bar height - header bar margin - page title area - chat margin bottom - recent chats height - recent chats margin */
        height: calc(100vh - 8rem - 2rem - 7rem - 3rem - 3.2rem - 3rem);
    }
}

@media screen and (max-width: 900px) {
    #chat .chat-window{
        /* page height - header bar height - header bar margin - page title area - chat margin bottom - recent chats height - recent chats margin */
        height: calc(100vh - 8rem - 2rem - 5.4rem - 3rem - 3.2rem - 3rem);
    }
}