.PerformanceDetail {
    width: 100%;
    padding: 1rem 4rem 1rem 0;
    min-height: 25rem;
    /* border-bottom: 1px solid #eaeaea; */
}

.PerformanceDetail__Wrapper {
    border: 1px solid #eaeaea;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
}

.PerforamnceDetail__GraphWrapper {
    flex: 1;
    height: 18rem;
    padding: 1rem 0;
}

.PerformanceDetail__Graph {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 25rem;
    margin-right: 2rem;
    flex: 1;
}

.PerformanceDetail__Graph:last-child {
    margin-right: 0;
}

.PerformanceDetail__GraphTabs {
    background: #EBECF0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 1rem;
}

.PerformanceDetail__GraphTab {
    border-bottom: 2px solid #EBECF0;
    color: rgb(173, 173, 175);
    flex: 1;
    margin-right: 1rem;
    padding: 0.6rem 1rem;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
}

.PerformanceDetail__GraphTab:last-child {
    margin-right: 0;
}

.PerformanceDetail__GraphTab--active {
    border-bottom: 2px solid #EB4159;
    color: #222222;
}

.PerformanceDetail__NoGraph {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.PerformanceDetail__Summary {
    padding: 2rem 2rem 1rem 2rem;
}

.PerformanceDetail__DateRange {
    color: #637481;
    font-size: 1rem;
}

@media only screen and (max-width: 600px) {

    /* .PerformanceSidebar {
        width: 100%;
    }

    .PerformanceSidebar__Section-Label {
        width: 100%;
        border-bottom: 2px solid #f0f0f0;
        padding: 1.2rem 2rem;
    }

    .PerformanceSidebar__Section-Label--active {
        border-bottom: 3px solid #FD2856;
    }

    .PerformanceSidebar__SectionTop {
        padding: 0;
    }

    .PerformanceSidebar__Section {
        padding: 0;
    }

    .PerformanceSidebar__Section-Content {
        display: none;
    } */

    .expanded {
        display: block;
        margin: 2rem;
    }



    /* .PerformanceMain__Header {
        padding: 2rem 1rem;
        font-size: 1.75rem;
    } */

    .PerformanceDetail__Wrapper {
        flex-direction: column;
    }

    .PerformanceDetail {
        padding: 1.5rem 1rem;
    }

}



.PerformanceDetail__Skeleton-Graph {
    border-top: 2px solid #ECECEC;
    border-bottom: 2px solid #ECECEC;
    width: 90%;
    display: flex;
    align-items: flex-end;
}

.PerformanceDetail__Skeleton-Bar1 {
    width: 3rem;
    height: 100%;
    background: #dfe3e8;

}

.PerformanceDetail__Skeleton-Bar2 {
    width: 3rem;
    height: 100%;
    background: #dfe3e8;
    margin-left: 1.6rem;
}

.PerformanceDetail__Skeleton-Bar3 {
    width: 3rem;
    height: 65%;
    background: #dfe3e8;
    margin-left: 1.6rem;
}

.PerformanceDetail__SkeletonProfileImage {
    width: 66%;
    height: auto;
    background: #dfe3e8;
    border-radius: 50%;

    margin: 2rem 0;
}

.PerformanceDetail__GraphTabs-Skeleton {
    background: #EBECF0;
    width: 100%;
    padding: 1.2rem;
}

.PerformanceDetail__Button-Skeleton {
    width: 12rem;
    margin: 1.6rem 0;
    padding: 1.5rem 4rem;
    background: #dfe3e8;
    display: block;
    border-radius: 50px;
}

.PerformanceMain__error-heading {
    font-size: 2.25rem;
    line-height: 2.85rem;
}

.PerformanceMain__ErrorWrapper {
    max-width: 700px;
    margin: 0 auto;
    padding: 4rem;
    text-align: center;
}

