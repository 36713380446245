.settings-terms__section-label {
    width: 100%;
    background-color: #f3f4f7;
    margin-top: 3rem;
}

.settings-terms__section-title {
    color: #7e8f9f;
    text-transform: uppercase;
    margin-left: 4rem;
}

.settings-terms__h1 {
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    padding-bottom: 8px;
}

.settings-terms__p {
    font-size: 12px;
    line-height: 18px;
    color: #828282;
    margin-bottom: 2rem;
}

.settings-terms__section-wrapper {
    margin: 4rem auto;
    max-width: 75%;
}

.settings-terms .Polaris-Layout {
    justify-content: flex-start;
}

.settings-terms .Polaris-TextField {
    min-height: 14rem;
    max-height: 50rem;
    align-items: flex-start;
}

.settings-terms .Polaris-TextField__Input {
    font-family: monospace;
    font-size: 1.6rem;
    max-height: 50rem;
}

.settings-terms__description {
    margin-bottom: 2rem;
}

.settings-terms .Polaris-Label__Text {
    font-weight: bold;
}

.settings-terms__textfield-wrapper {
    margin-bottom: 2rem;
    margin-top: 1rem;
    width: 100%;
}

.settings-terms td:nth-child(1) {
    font-weight: bold;
}

.settings-terms td:nth-child(3),
.settings-terms__mono-type {
    font-family: monospace;
    padding: 2px;
    border-radius: 2px;
    color: #5E70C3;
    font-size: 1.6rem;
}

.settings-terms li {
    list-style: none;
    margin: 0.6rem 0;
}

.settings-terms ul {
    padding: 0;
}

.settings-terms__heading {
    margin-top: 3rem;
    margin-bottom: 1rem;
}

@media screen and (max-width: 599px) {
    .settings-terms__section-title {
        margin-left: 1.25rem;
        font-size: 1.2rem;
    }

    .settings-items__section-header {
        font-size: 1.5rem;
    }
}