.OfferReports {
    padding: 2rem;
}

.OfferReports__Filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;

    padding: 2rem 0;
}



.OfferReports__Select {
    margin: 0 2rem;
    cursor: pointer;
}

.OfferReports__Select:first-child {
    margin-left: 0;
}

.OfferReports__Select:last-child {
    margin-right: 0;
}

.OfferReports__Select .Polaris-Select__Content {
    font-weight: bold;
    color: #222222;
    padding: 0;
    border: none;
    line-height: 2rem;
    min-height: auto;
}

.OfferReports__Select .Polaris-Select {
    border-bottom: 2px solid transparent;
    cursor: pointer;
}

.OfferReports__Select .Polaris-Select:hover {
    border-bottom: 2px solid #FB2956;
}

.OfferReports__Select .Polaris-Select__Backdrop {
    display: none;
}

.OfferReports__Select .Polaris-Select--disabled:hover {
    border-bottom: 2px solid transparent;
}

@media only screen and (max-width: 600px) {

    .OfferReports__Filters {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .TransactionReports__Table {
        max-width: 100%;
        overflow-x: scroll;
    }

}
