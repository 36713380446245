.campaign-products {

    padding: 24px;
    text-align: left;

}

.campaign-products__heading {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;

}

.campaign-products__selection ul{
    margin: .8rem 0 .5rem;
}

.campaign-products__selection li{
    margin-bottom: .6rem;
}

.campaign-products__selection li:last-child{
    margin-bottom: 0;
}

.campaign-products__selection .Polaris-ChoiceList__Title{
    font-size: 1.6rem;
}

.campaign-products__selection .Polaris-Choice__Label{
    font-weight: bold;
    font-size: 1.6rem;
}

.campaign-products__selection .search{
    margin-top: 1rem;
}

.campaign-products__selection .search a{
    color: #fa2f58;
}

.campaign-products__selection input{
    margin-right: 1rem;
}

.campaign-products__selection .search__item{
    cursor: pointer;
}

.campaign-products__form {

    width: 100%;

}