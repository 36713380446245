.settings-payment__wrapper{
    margin-top: 5rem;
}

.settings-payment__wrapper section{
    margin-bottom: 8rem;
    display: grid;
    grid-template-columns: minmax(auto, 27rem) 1fr;
    column-gap: 4rem;
}

.settings-payment__wrapper h2{
    text-transform: uppercase;
    font-size: 1.6rem;
    padding-bottom: 0.7rem;
    border-bottom: 1px solid #DFE3E8;
    font-weight: bold;
    margin-bottom: 2rem;
    grid-column: 2/3;
}

.settings-payment__wrapper section aside{
    color: #637381;
    grid-column: 1;
    margin-top: -4.8rem;
}

.settings-payment__wrapper section aside p{
    margin-bottom: 2rem;
}

.settings-payment__wrapper section aside + div{
    order: 1;
}

.settings-payment__wrapper .cc-info-wrapper{
    margin-bottom: 4rem;
}

.settings-payment__wrapper section h3{
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 2rem;
}

.settings-payment__wrapper .billing-history td{
    font-size: 1.4rem;
}

/* .settings-payment__section-label {
    width: 100%;
    background-color: #f3f4f7;
    margin-top: 5rem;
}

.settings-payment__section-title {
    color: #7e8f9f;
    text-transform: uppercase;
    margin-left: 6rem;
}

.settings-payment__card-wrapper {
    padding: 4rem 2rem 2rem 2rem;
} */

.pplogin{
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 2rem;
    margin-top: .8rem;
    text-align: left;
}

.Onboarding__PaypalWrapper .pplogin{
    border-top: none;
    padding-top: 0;
    margin-top: 0;
}

.Onboarding__PaypalWrapper .pplogin img{
    vertical-align: middle;
}

@media screen and (max-width: 1010px){
    .settings-payment__wrapper section{
        grid-template-columns: auto;
        margin-bottom: 4rem;
    }

    .settings-payment__wrapper h2,
    .settings-payment__wrapper section aside{
        grid-column: 1/1;
    }

    .settings-payment__wrapper section aside{
        margin-top: 1.5rem;
        order: 1;
        font-size: 1.3rem;
        line-height: 1.4em;
    }

    .settings-payment__wrapper section aside + div{
        order: 0
    }
}

@media screen and (max-width: 840px){
    .settings-payment__wrapper section h3{
        margin-bottom: 0;
        padding-bottom: 1rem;
        border-bottom: 1px solid #DFE3E8
    }
}