.llama-tos__wrapper {
    margin: 2rem;
    max-width: 1080px;
    margin: 0 auto;
}

.llama-tos__content {
    padding: 4rem;
}

.llama-tos__header {
    font-weight: bold;
    font-size: 1.5rem;
    margin: 1.5rem 0;
}

.llama-tos__section-header {
    font-weight: bold;
    font-size: 1.5rem;
    margin: 1rem 0;
}

.llama-tos__body {
    font-size: 1.5rem;
    margin: 0 0 1rem 0;
    /* line-height: 1.2rem; */
}

.b {
    font-weight: bold;
}

.mr {
    margin-right: .5rem;
}