ul.campaign-summary__list-wrapper{
    padding-left: 2rem;
    margin-bottom: 0;
}
ul.campaign-summary__list-wrapper li{
    margin-bottom: 0.8rem;
}

.campaign-summary__section--name div:empty{margin: 0;}

.offer-new__summary-wrapper[data-curstep="summary"] .campaign-summary__section--commission h2, 
.offer-new__summary-wrapper[data-curstep="summary"] .campaign-summary__section--commission li,
.offer-new__summary-wrapper[data-curstep="summary"] .campaign-summary__section--products h2,
.offer-new__summary-wrapper[data-curstep="summary"] .campaign-summary__section--products p,
.offer-new__summary-wrapper[data-curstep="summary"] .campaign-summary__section--audience h2,
.offer-new__summary-wrapper[data-curstep="summary"] .campaign-summary__section--audience li,

.offer-new__summary-wrapper[data-curstep="products"] .campaign-summary__section--commission .rates,
.offer-new__summary-wrapper[data-curstep="products"] .campaign-summary__section--audience h2,
.offer-new__summary-wrapper[data-curstep="products"] .campaign-summary__section--audience li,

.offer-new__summary-wrapper[data-curstep="commission"] .campaign-summary__section--audience h2,
.offer-new__summary-wrapper[data-curstep="commission"] .campaign-summary__section--audience li{
    opacity: .3;
}

.offer-paypal-message{
    margin-bottom: 1.5rem;
}
.offer-paypal-message-under{
    margin-top: 1.5rem;
}

@media screen and (max-width: 599px){
    .offer-new__summary-wrapper{
        background: #f9fafb;
        margin-top: 2rem;
    }
}