.Polaris-Page-Header{
    border-bottom: .1rem solid #dfe3e8;
    padding-bottom: 5px !important;
    margin-bottom: 3rem;
}

.dashboard-sales-graphs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
}

.dashboard-sales-graphs .revenue{
    grid-column: 1 / 3;
}

.nivo--mobile {
    display: none;
    background: #fafafa;
    margin-top: 2rem;
}

.adv-reports{
    max-width: 1330px;
    margin: 0 auto;
}
.nivo {
    display: block;
    height: 500px;
    background: #fafafa;
    margin: 2rem 0 3rem 0;
}

.revenue_chart_title {
    font-size: 20px;
    font-weight: bold;
}

.ambassador_sales_title{
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
}

.product_sales_title{
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0;
}

.product-sales-table{
    margin-bottom: 3rem;
}

.list {
    display: flex;
    flex-direction: row;
    background-color: #f9fafb;
    padding: 10px;
}

.sales_title{
    font-size: 18px;
}

.sales_price{
    margin: 0 0 0 45px;
    font-size: 18px;
    font-weight: bold;
}

.dashboard__empty-set{
    text-align: center;
    padding: 2rem 0;
    background: #f4f6f8;
}