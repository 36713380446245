.cc-info-wrapper{
    display: flex;
    align-items: center;
}

.cc-info-wrapper p.card-info{
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.15em;
    margin-right: 2rem;
}

.cc-info-wrapper .card-info:not(.unknown){
    padding-left: 5.2rem;
    position: relative;
}

.cc-info-wrapper .card-info:not(.unknown)::before{
    object-fit: contain;
    width: 41px;
    height: 26px;
    display: block;
    position: absolute;
    left: 0;
    top: calc(50% - 13px);
}

.cc-info-wrapper .visa::before{content: url(../../assets/cc-visa.svg);}
.cc-info-wrapper .mastercard::before{content: url(../../assets/cc-mastercard.svg);}
.cc-info-wrapper .american-express::before{content: url(../../assets/cc-amex.svg);}
.cc-info-wrapper .discover::before{content: url(../../assets/cc-discover.svg);}
.cc-info-wrapper .jcb::before{content: url(../../assets/cc-jcb.svg);}
.cc-info-wrapper .unionpay::before{content: url(../../assets/cc-unionpay.svg);}
.cc-info-wrapper .diners-club::before{content: url(../../assets/cc-dinersclub.svg);}

.cc-info-wrapper .expiration-date{
    font-size: .8em;
    display: block;
    font-weight: normal;
    color: #637381
}

.cc-info-wrapper .edit{
    background-image: url('../../assets/edit-white.svg');
    background-repeat: no-repeat;
    background-position: 1.6rem center;
    background-size: 1.3rem 1.3rem;
    padding-left: 3.6rem;
}

.cc-info-wrapper .delete{
    background: #262626 url(../../assets/delete-white.svg) 1.5rem center no-repeat;
    background-size: 1.1rem 1.3rem;
    border-color: #262626;
    padding-left: 3.5rem;
}

.cc-info-wrapper .actions{
    display: flex;
    align-items: center;
}

.cc-info-wrapper.skeleton .card-info::before{
    content: '';
    animation: var(--polaris-animation-skeleton-shimmer) 800ms linear infinite alternate;
    background-color: #dfe3e8;
    border-radius: 3px;
}

.cc-info-wrapper.skeleton .card-info .Polaris-SkeletonDisplayText__DisplayText{
    width: 13rem;
    height: 1.4rem;
    margin-bottom: 0.4rem;
}
.cc-info-wrapper.skeleton .card-info .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    width: 7rem;
    height: 1.2rem;
}

.cc-update-form .Polaris-Banner{
    margin-bottom: 2rem;
}

.cc-update-form .cards{
    font-size: 1.2rem;
    color: #637381;
    line-height: 1.3em;
    margin-top: 0.3rem;
}

.cc-update-form ul{
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.cc-update-form li{
    margin-bottom: 2rem;
}

.cc-update-form li:last-child{
    margin-bottom: 0;
}

.cc-update-form .StripeElement{
    margin-top: 0.4rem;
    margin-bottom: 0;
    background: #fff;
    border: 0.1rem solid #c4cdd5;
    border-radius: .3rem;
    box-shadow: inset 0 1px 0 0 rgba(99, 115, 129, 0.05);
    padding: 0.9rem 1.2rem;
}

@media screen and (max-width: 440px){
    .cc-info-wrapper{
        display: block;
    }

    .cc-info-wrapper p.card-info{
        margin-right: 0;
        margin-bottom: 1.5rem;
    }
}