

.pixels {
    width: 90%;
    margin: 2rem auto;
}

.pixels .Polaris-TextField {
    min-height: 14rem;
    max-height: 30rem;
    align-items: flex-start;
}

.pixels .Polaris-TextField__Input {
    font-family: monospace;
    font-size: 1.6rem;
    max-height: 30rem;
}

.pixels__description {
    margin-bottom: 2rem;
}

.pixels .Polaris-Label__Text {
    font-weight: bold;
}

.pixels__textfield-wrapper {
    margin-bottom: 2rem;
}

.pixels td:nth-child(1) {
    font-weight: bold;
}

.pixels td:nth-child(3),
.pixels__mono-type {
    font-family: monospace;
    border-radius: 2px;
    color: #5E70C3;
    font-size: 1.6rem;
}

.pixels li {
    list-style: none;
    margin: 0.6rem 0;
}

.pixels ul {
    padding: 0;
}

.pixels__heading {
    margin-top: 3rem;
    margin-bottom: 1rem;
}