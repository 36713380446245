.campaign-target__heading {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.campaign-target__selection {
    margin: 2.6rem 0 1.6rem;
}

.campaign-target__selection:first-child {
    margin-top: 0;
}

.campaign-target__selection:last-child {
    margin-bottom: 0;
}

.campaign-target__title {
    margin-bottom: 1rem;
}

.campaign-target__selection,
.campaign-target__selection ul{
    padding-left: 0;
    list-style: none;
}

.campaign-target__selection > li{
    margin-bottom: 2.8rem;
}

.campaign-target__selection > li:last-child{
    margin-bottom: 0;
}

.campaign-target__selection ul,
.campaign-target__selection .ranges{
    display: flex;
    align-items: center;
}

.campaign-target__selection ul{
    flex-wrap: wrap;
}

.campaign-target__selection ul li{
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.campaign-target__selection .ranges > div,
.campaign-target__selection .ranges p{
    margin-right: 1rem;
}

.campaign-target__selection .field-title{
    font-size: 1.6rem;
    display: block;
    margin-bottom: 1rem;
}

.campaign-target__country-tags{
    margin-bottom: 0;
}

.campaign-target__selection .genders ul li{
    margin-bottom: 0;
    margin-right: 1.5rem;
}

.campaign-target__selection .genders .Polaris-ChoiceList__Title{
    font-size: 1.6rem;
}

.campaign-target__selection .genders .Polaris-Choice__Label{
    font-weight: bold;
}

.campaign-target__country-tags > span {
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.campaign-target__country-tags > span:last-child {
    margin-right: 0;
}