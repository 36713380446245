.campaign-links {
    padding: 2rem;
}

.campaign-links__input-field {
    margin: 2rem 0;
    width: 99.8%;
}

.campaign-links__heading {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.campaign-links__display {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.campaign-links__copy-button {
    min-width: 10rem;
    padding-left: 1rem;
}

.campaign-links__copy-button button{
    background: #fd2a54 !important;
    border: none;
    color: #fff;
}

.campaign-links__copy-button button:hover{
    opacity: 0.8;
}

.campaign-links__copy-button button:focus{
    box-shadow: 0 0 0 1px #fd2a54;
}

.campaign-links__copy-button button svg{
    fill: #fff;
}