.offer-view__layout {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
}


.offer-data__chart {
    width: 100%;
    height: 30rem;
    padding: 2rem;
}

.offer-data__products {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;

    padding-top: 2rem;
}

.offer-data__product {
    margin: 0 2rem;
    width: 33.3333%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.offer-data__product-data {
    padding: 2rem;
}

.offer-data__product-image {
    display: block;
    height: 12rem;
    width: 8rem;
    align-self: center;
    margin-bottom: 0.4rem;

    display: flex;
    justify-content: center;
    align-items: center;

    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.offer-data__product-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
    overflow: hidden;
    width: 80%;
}

@media screen and (min-width: 861px) {
    .offer-view .llama-tabbed-nav[data-type="scrolling"]{
        width: calc(100vw - 30rem - 200px - 4rem - 5rem); /* 100% viewport width - summary container width - side nav width - content margin right - page padding */
    }
}

@media screen and (max-width: 1070px) and (min-width: 861px){
    .offer-view .llama-tabbed-nav[data-type="scrolling"]{
        width: calc(100vw - 25rem - 200px - 3rem - 5rem); /* 100% viewport width - summary container width - side nav width - content margin right - page padding left and right */
    }
}