.image-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.img {
    width: 900vw;
    height: 25vh;
}

.message-container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: #eb4059;
    line-height: normal;
}

.fourOhfour{
    font-size: 10em;
    font-weight: bold;
}

.fourOhfour-message{
    font-size: 25px;
    font-weight: bold;
    color: #33125d;
}