.offer-data{
    width: calc(100% - 30rem - 4rem);
    margin-right: 4rem;
}

.offer-view__summary{
    width: 30rem;
    background: #f9fafb;
}

.offer-view__summary .campaign-summary__banner img{
    margin-left: -2rem;
    width: calc(100% + 4rem);
}

.offer-view__page-buttons{
    display: flex;
}

.offer-view__page-buttons button{
    padding: 0.7rem 1.6rem;
}

.offer-view__page-buttons .boost{
    background: #34135D url('../../assets/up-arrow-in-circle.svg') no-repeat 13px center;
    background-size: 15px 15px;
    padding-left: 36px;
    border-color: #34135D;
}

.offer-view__page-buttons .edit{
    background-image: url('../../assets/edit-white.svg');
    background-repeat: no-repeat;
    background-position: 16px center;
    background-size: 13px 13px;
    padding-left: 36px;
}

@media screen and (max-width: 1070px) and (min-width: 861px){
    .offer-data{
        width: calc(100% - 25rem - 3rem);
        margin-right: 3rem;
    }
    .offer-view__summary{
        width: 25rem;
    }
}

@media only screen and (max-width: 860px) {
    .offer-data{
        margin-right: 0;
        width: 100%;
    }
    .offer-view__summary {
        display: none;
    }
}
