.offers-boosted-wrapper h1{
    font-weight: bold;
    font-size: 2rem;
}

.offers-boosted-wrapper .offer-list .number span{
    color: #637381;
    display: block;
    font-size: 0.8em;
    white-space: nowrap;
}

.offers-boosted-wrapper .bid{
    white-space: nowrap;
}

/* 2 column (extension of what's defined in offer-list.css) */
@media screen and (max-width: 1180px){
    .offers-boosted-wrapper .offer-list .number span{
        display: inline-block;
        vertical-align: top;
        margin-left: 0.5rem;
        font-size: 1em;
    }

    .offers-boosted-wrapper .offer-list .number span::before{ content: '('; }
    .offers-boosted-wrapper .offer-list .number span::after{ content: ')'; }

    .offers-boosted-wrapper td{
        margin-bottom: 0.6rem;
    }

    .offers-boosted-wrapper td::before{
        font-weight: bold;
    }

    .offers-boosted-wrapper .impressions::before{ content: 'Impressions: '; }
    .offers-boosted-wrapper .unique::before{ content: 'Unique Impressions: '; }
    .offers-boosted-wrapper .clicks::before{ content: 'Clicks: '; }
    .offers-boosted-wrapper .applicants::before{ content: 'Applicants: '; }
    .offers-boosted-wrapper .bid::before{ content: 'Current Bid: '; }
    .offers-boosted-wrapper .spent::before{ content: 'Total Spent: '; }

    .offers-boosted-wrapper .offer-list .name{
        padding-right: 0;
    }

    .offers-boosted-wrapper .status a{
        color: #737A80;
        font-weight: bold;
        border-radius: 6rem;
        border: 1px solid #737A80;
        padding: .3rem 1.5rem;
        margin-top: 1rem;
        display: inline-block;
    }
}

/* not 1 column yet (override offer-list.css) */
@media screen and (max-width: 1030px) and (min-width: 841px){
    .offers-boosted-wrapper .offer-list tbody{
        display: grid;
    }

    .offers-boosted-wrapper .offer-list tr{
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 420px){
    .offers-boosted-wrapper .status a{
        display: block;
        text-align: center;
        padding: .8rem 1.5rem;
    }
}