.discover__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
}

.discover-sidebar__mobile-open {
    display: none;
}

.discover-sidebar__desktop-apply-filters {
    display: block;
    margin: 1rem 2rem;
}


.discover-sidebar {
    width: 30rem;
    margin-right: 4rem;
    border: 1px solid #e3e3e3;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    background-color: #f3f3f3;
}

.discover-sidebar__section {
    width: 100%;
    padding: 0rem 2rem 3rem;
}

.discover-sidebar__reset-wrapper {
    margin-top: 1rem;
}

.discover-sidebar__label {
    color: #494949;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.6rem;
    font-size: 1.2rem;
    display: block;
}

.discover-sidebar__search {
    padding: 2rem;
    width: 100%;
    background-color: #e8e8e8;
    /* background: #EFEFEF; */
    position: relative;
}

.discover-sidebar__search .Polaris-Connected__Item{
    z-index: 20;
}

.discover-sidebar__search .search-button{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border:none;
    background: none;
    cursor: pointer;
    padding-left: 0.5rem;
}

.discover-sidebar__section-top {
    padding-top: 2rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.discover-sidebar__section-top .Polaris-Button {
    border-radius: 3px;
    background: #FFFFFF;
    padding: 0;
}

.discover-sidebar__category {
    width: 100%;
}

.discover-sidebar__age-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.discover-sidebar__age-check {
    margin: 0 5% 0 0;
    width: 45%;
}



.discover-main {
    /* flex: 1; */
    /* padding: 0 2rem; */
    display: flex;
    flex-direction: column;
}

.discover-main .import__pagination{
    padding: 0;
}

/* .discover-main__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    /* flex-grow: 1; *
} */

.discover-main__wrapper{
    display: grid;
    grid-template-columns: repeat(2, minmax(28rem, 1fr));
    row-gap: 3rem;
    column-gap: 3rem;
    margin-bottom: 2.5rem;
}

.discover-main__wrapper .Polaris-SkeletonDisplayText__DisplayText{
    width: 150px;
    max-width: none;
}

.discover-affiliate {
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;

    /* margin: 2rem; */
    /* margin: 0 2rem 2rem 2rem; */
    width: 100%;
    max-width: 40rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.discover-affiliate__top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 2rem;
}

.discover-affiliate__top-info {
    width: calc(100% - 130px);
}

.discover-affiliate__profile-image {
    width: 110px;
    height: 110px;
    background: rgb(110, 77, 145);
    border-radius: 50%;
    margin: 0 2rem 0 0;
    align-self: flex-start;
    justify-self: flex-start;
    cursor: pointer;
}

.skeleton .discover-affiliate__profile-image{
    cursor: default;
}

.discover-affiliate__name {
    font-size: 2rem;
    font-weight: bold;
    color: #222222;
}

.discover-affiliate__name span{
    cursor: pointer;
}

.discover-affiliate__categories {
    color: #637381;
    font-size: 1.2rem;
    margin-top: 1rem;
}

.discover-affiliate__category--selected {
    font-weight: bold;
    color: #32175D;
}

.discover-affiliate__categories-action {
    color: #FB2757;
    cursor: pointer;
}

.discover-affiliate__categories-action:active {
    opacity: 0.7;
}

.discover-affiliate__section {
    border-top: 1px solid #E7E7E7;
    padding: 1rem 2rem;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.discover-affiliate__key-icon {
    display: flex;
    align-items: center;
}

.discover-affiliate__section-icon {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1rem;
}

.discover-affiliate__info-key {
    color: #637381;
}

.discover-affiliate__info-value {
    color: #222222;
    font-weight: bold;
}

.discover-affiliate__buttons {
    justify-content: center;
    align-items: flex-start;
}



.AffiliateInvite__SideDrawer {
    z-index: 200;
    position: fixed;
    max-width: 600px;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 200;
    background-color: rgb(232,232,232);
    padding: 0;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AffiliateInvite__TransparentBg {
    position: absolute;
    z-index: 199;
    top: 0;
    left: 0;
    background-color:rgba(0, 0, 0,0.5);
    width:100%;
    height:100%;
    display: none;
}

.AffiliateInvite__OpenMenu {
    transform: translateX(0);
}

.AffiliateInvite__CloseMenu {
    transform: translateX(100%);
}

.AffiliateInvite__ShowBg {
    display: block;
    position: fixed;
}

.AffiliateInvite__CloseBtn {
    cursor: pointer;
    height: 16px;
}




.AffiliateInvite__Layout {
    display: flex;
    flex-direction: column;
    background-color:white;
    width: 90%;
    height: auto;
    /* width: 85%;
    height: 250px; */
    margin: 2rem 1.5rem;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;
}

.AffiliateInvite__CardSection,
.AffiliateInvite__ErrorCard {
    display: flex;
    flex-direction: column;
    background-color: rgb(250,250,250);
    width: 90%;
    height: auto;
    /* width: 85%; */
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;
    overflow: hidden;
}

.AffiliateInvite__ErrorCard {
    padding: 2rem 3rem;
    color: #F6323F;
    margin-top: 2rem;
}

.AffiliateInvite__CardTop {
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    background-color: white;
}

.AffiliateInvite__CardTop h1 {
    font-size: 1.6rem;
    font-weight: 600;
}




.AffiliateInvite__OfferCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    font-size: 18px;
    font-weight: 600;

    padding: 0 2rem;
    height: auto;
    overflow-y: auto;
}

.AffiliateInvite__ButtonGroup {
    margin: 2rem 0;
    width: 90%;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
}

.invite_offer_button-primary .llama-button-spinner,
.invite_offer_button-secondary .llama-button-spinner {
    transform: scale(0.6);
    top: 4px;
}

.AffiliateInvite__OfferSearch {
    width: 100%;
    margin: 2rem 0;
}

.AffiliateInvite__OfferSearch .Polaris-TextField {
    width: 100%;
    border-radius: 0;
}

.AffiliateInvite__OfferSearch .Polaris-TextField__Backdrop {
    border-radius: 0;
    border: 1px solid rgb(206, 206, 206);
}

.AffiliateInvite__OfferChoices {
    width: 100%;
    overflow: auto;
}

.AffiliateInvite__OfferChoices span {
   font-size: 1.4rem;
   font-weight: bold;
}

.AffiliateInvite__OfferChoices .Polaris-Choice {
    align-items: center;
    padding: 0;
}

.AffiliateInvite__OfferChoices span .Polaris-Icon {
    height: 2rem;
    width: 2rem;
}

.AffiliateInvite__OfferChoices .Polaris-ChoiceList__Choices li {
    margin-bottom: 1.5rem;
}

.AffiliateInvite__OfferChoices .Polaris-Choice__Control {
    width: 2rem;
    height: 2rem;
    margin-top: 0;
}

.AffiliateInvite__OfferChoices .Polaris-Checkbox__Backdrop {
    background-color: rgb(206, 206, 206);
    border: 2px solid transparent;
}




.AffiliateInvite__Metric-Card {
    padding: 2rem;
    display: flex;
    align-items: center;
}

.AffiliateInvite__AffiliateImg {
    margin-right: 2rem;
}

.AffiliateInvite__AffiliateImg img {
    width: 106px;
    height: 106px;
    border-radius: 50%;
    object-fit: contain;
}

.AffiliateInvite__AffiliateImg img.placeholder{
    background: #6e4d91;
}

.AffiliateInvite__Metric-Info {
    width: calc(100% - 106px - 20px);
}

/* .AffiliateInvite__Metric-Info {
    font-size: 16px;
    font-weight: 700;
} */

.AffiliateInvite__AffiliateName {
    font-size: 22px;
    font-weight: bold;
    border-bottom: 1px solid #E7E7E7;
    padding-bottom: 6px;
    line-height: 1.2em;
}

.AffiliateInvite__Metric-Card dl{
    margin-bottom: 0;
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    white-space: nowrap
}
.AffiliateInvite__Metric-Card dt{
    font-size: 12px;
    padding-bottom: 13px;
}
.AffiliateInvite__Metric-Card dd{
    font-weight: bold;
    font-size: 16px;
    margin-left: 0;
    grid-row: 1;
}
.AffiliateInvite__Metric-Card dd:nth-of-type(3){
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 3;
}
.AffiliateInvite__Metric-Card dt:nth-of-type(3){
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 4;
    padding-bottom: 0;
}

/* .AffiliateInvite__MetricLabel {
    font-size: 12px;
    font-weight: 200;
    color: #637381;
}

.AffiliateInvite__MetricRow {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.AffiliateInvite__MetricItem {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    margin: 15px 0;
} */

.AffiliateInvite__LayoutTop {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: white;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    padding: 1rem 2rem;
}

.AffiliateInvite__LayoutTop h1 {
    margin-left: 2rem;
    font-size: 1.8rem;
    font-weight: bold;
    
}

.discover-offers__reset-filters{
    padding-left: 2rem;
    background: url(../../assets/reset-filters-icon.svg) no-repeat left center;
    background-size: 14px 12px;
    margin-left: .7rem;
    color: #331b5b;
    cursor: pointer;
  }
  
  .discover-offers__reset-filters[data-enabled="false"]{
    opacity: .2;
    cursor: default;
  }

.discover-offers__filter-group {
    margin: 2rem 0 0;
    display: flex;
    align-items: center;
}

.discover-offers__filter-group .llama-button:disabled{
  opacity: 0.2;
}


.discover-sidebar__section:last-of-type .discover-offers__filter-group{
    margin-top: 0;
}

@media screen and (max-width: 1260px){
    .discover-affiliate__name{
        font-size: 1.8rem
    }
    .discover-affiliate__categories{
        font-size: 1rem;
        margin-top: 0.5rem;
        line-height: 1.6rem;
    }
    .discover-affiliate__profile-image{
        width: 80px;
        height: 80px;
        margin-right: 1.5rem;
    }
    .discover-affiliate__section{
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 1260px) and (min-width: 899px){
    .discover-sidebar {
        margin-right: 3rem;
        width: 28rem;
    }
}

@media only screen and (max-width: 1160px) and (min-width: 899px){
    .discover-sidebar{
        width: 35rem;
        margin-right: 5rem;
    }
  }

@media only screen and (max-width: 1160px) and (min-width: 899px),
            screen and (max-width: 855px){
  .discover-main__wrapper{
    grid-template-columns: repeat(1, 100%);
  }
}

@media only screen and (max-width: 960px) and (min-width: 899px){
    .discover-sidebar{
        width: 28rem;
        margin-right: 3rem;
    }
}

/* @media only screen and (max-width: 1020px),
            screen and (max-width: 915px) {
  .discover-main__wrapper{
    grid-template-columns: repeat(1, 100%);
  }
  .discover-sidebar{
      width: 35rem;
      margin-right: 5rem;
  }
} */

@media only screen and (max-width: 900px) {

    .discover-sidebar{
        margin-right: 0;
    }

    .discover-sidebar__desktop-apply-filters {
        display: none;
    }

    .discover-sidebar__mobile-open {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem;
        width: 100%;
    }

    .discover-sidebar__display-text {
        font-weight: bold;
    }

    .discover__wrapper {
        flex-wrap: wrap;
    }

    .discover-main {
        margin-top: 2rem;
        padding: 0;
        width: 100%;
    }

    .discover-sidebar {
        width: 100%;
    }

    .discover-sidebar__section {
        padding: 0;
    }

    .discover-sidebar--mobile-tab {
        border-bottom: 2px solid #f0f0f0;
        padding: 1.2rem 2rem;
    }

    .discover-sidebar__section--active {
        border-bottom: 3px solid #FD2856;
    }

    .discover-sidebar__section-content {
        display: none;
    }

    .discover-offers__filter-group--desktop {
        display: none;
    }

    .expanded {
        display: block;
        margin: 1rem 2rem;
    } 


    /* .discover-affiliate {
        margin: 1rem 0;
    } */
}

@media screen and (max-width: 855px){
    .discover-affiliate{
        max-width: none;
    }
}

@media screen and (max-width: 855px) and (min-width: 745px){
    .discover-main__wrapper{
        padding: 0 calc( (100% - 50rem) / 2);
    }
}

@media screen and (max-width: 720px){
    .AffiliateInvite__SideDrawer {
        width: 100%;
        z-index: 500;
    }

    .AffiliateInvite__Metric-Card {
        align-items: flex-start;
        padding: 2rem 1rem;
    }

    .AffiliateInvite__AffiliateImg img {
        width: 90px;
        height: 90px;
    }
}

@media screen and (max-width: 450px){
    .discover-affiliate__top-info {
        width: calc(100% - 100px);
    }
    
    .discover-affiliate__profile-image {
        width: 80px;
        height: 80px;
    }

    .discover-affiliate__name{
        font-size: 1.8rem;
    }

    .discover-affiliate__categories{
        font-size: 1rem;
        line-height: 1.7em;
        margin-top: 0.7rem;
    }
}

@media screen and (max-width: 420px){
    .AffiliateInvite__AffiliateName {
        font-size: 20px;
    }

    .AffiliateInvite__Metric-Card dt{
        font-size: 10px;
    }

    .AffiliateInvite__Metric-Card dd{
        font-size: 14px;
    }

    .AffiliateInvite__ButtonGroup{
        flex-wrap: wrap;
        flex-direction: column;
    }
    .AffiliateInvite__ButtonGroup button:first-child{
        order: 2;
    }

    .AffiliateInvite__ButtonGroup button:last-child{
        order: 1;
        width: 100%;
        padding: 0.8rem 1.6rem;
        margin-bottom: 1.5rem;
    }
}