.affiliate-tab__name[data-is-facebook-verified="true"],
.affiliate-tab__facebook-posts .name b[data-is-verified="true"]{
    display: inline-flex;
    align-items: center
}
.affiliate-tab__name[data-is-facebook-verified="true"]::after,
.affiliate-tab__facebook-posts .name b[data-is-verified="true"]::after{
    content: '';
    width: 15px;
    height: 15px;
    margin-left: 6px;
    background: url(../../assets/facebook-verified-icon.svg);
}

.affiliate-facebook .Polaris-Banner{
    margin: 0 3rem;
    transform: translateY(-2rem);
}

.affiliate-facebook .more-posts img{
    display: none;
}

.affiliate-tab__facebook-posts{
    list-style: none;
    padding: 0;
    margin-top: 1rem;
}

.affiliate-tab__facebook-posts .post{
    padding: 3rem;
    border-bottom: 1px solid #e0e0e0;
}

.affiliate-tab__facebook-posts .post:first-child{
    padding-top: 0;
    background-position: calc(100% - 3rem) 0;
}

.affiliate-tab__facebook-posts .name span{
    font-size: 1.2rem;
    color: #637381;
}

.affiliate-tab__facebook-posts .label{
    margin-bottom: 0.2rem;
    display: flex;
    align-items: center;
}

.affiliate-tab__facebook-posts .label img{
    border-radius: 100%;
    border: 1px solid #E0E0E0;
    margin-right: .5rem;
}

.affiliate-tab__facebook-posts .label a,
.affiliate-tab__facebook-posts .content a{
    font-weight: bold;
    color: #fd2a54;
}

.affiliate-tab__facebook-posts .content{
    font-size: 1.9rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    line-height: 1.4em;
}

.affiliate-tab__facebook-posts .photo{
    max-width: 410px;
    max-height: 290px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
}

.affiliate-tab__facebook-posts .photo img{
    max-width: 100%;
    border: 1px solid #E0E0E0;
}

.affiliate-tab__facebook-posts video{
    max-width: 410px;
    margin-bottom: 1.5rem;
    width: 100%;
}

.affiliate-tab__facebook-posts .attachment a{
    display: flex;
    align-items: center;
    color: #212b36;
    margin-bottom: 1.5rem;
    border: 1px solid #E0E0E0;
    background: #EBECF0;
}

.affiliate-tab__facebook-posts .attachment .share-image{
    width: 226px;
    max-height: 180px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.affiliate-tab__facebook-posts .attachment .photo-album{
    margin: 10px 0;
    padding-left: 0;
    overflow: auto;
    height: 80px;
}

.affiliate-tab__facebook-posts .attachment .photo-album li{
    width: 80px;
    max-height: 80px;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    vertical-align: middle;
}

.affiliate-tab__facebook-posts .attachment img{
    max-width: 100%;
}

.affiliate-tab__facebook-posts .attachment .share-content{
    padding: 2rem 2.5rem;
    width: calc(100% - 226px);
}

.affiliate-tab__facebook-posts .attachment .op{
    font-size: 1.6rem;
    font-weight: bold;
}

.affiliate-tab__facebook-posts .attachment .date{
    font-size: 1.2rem;
    color: #637381;
}

.affiliate-tab__facebook-posts .attachment .text{
    margin-top: 1rem;
    margin-bottom: 0;
    line-height: 1.3em;
    max-height: calc(1.3em * 4);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.affiliate-tab__facebook-posts .attachment .read-more{
    font-weight: bold;
    color: #fd2a54;
    margin-top: 5px;
}

.affiliate-tab__facebook-posts .metrics{
    display: flex;
    justify-content: space-between;
}

.affiliate-tab__facebook-posts .metrics ul{
    list-style: none;
    padding-left: 0;
    margin: 0 0 1.5rem;
    display: flex;
}

.affiliate-tab__facebook-posts .metrics li{
    margin-right: 2.4rem;
}

.affiliate-tab__facebook-posts .metrics a{
    color: var(--p-text);
}

@media only screen and (max-width: 1270px) and (min-width: 1065px),
            screen and (max-width: 868px) {
    .affiliate-tab__stats-facebook{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    .affiliate-tab__stats-facebook .affiliate-tab__stat-item{
        margin-bottom: 0;
        padding-left: 0;
    }
}

@media only screen and (max-width: 1180px) and (min-width: 1065px),
            screen and (max-width: 805px) {
    .affiliate-tab__facebook-posts .attachment a{
        flex-wrap: wrap;
    }

    .affiliate-tab__facebook-posts .attachment .share-content{
        width: 100%;
    }
    .affiliate-tab__facebook-posts .attachment .share-image{
        width: 100%;
        max-height: 300px;
    }
}

@media only screen and (max-width: 1064px) {
    .affiliate-tab__facebook-posts .post{
        padding: 3rem 0;
    }
}

@media only screen and (max-width: 470px) {
    .affiliate-tab__stats-facebook{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 450px) {
    .affiliate-tab__facebook-posts .metrics li{
        margin-right: 1.5rem;
    }
    .affiliate-tab__facebook-posts .metrics{
        flex-wrap: wrap;
    }

    .affiliate-tab__facebook-posts .metrics ul{
        width: 100%;
    }
}