.ambassadors .Polaris-Page {
    max-width: 1440px;
}

.ambassadors__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
}

.ambassadors-sidebar__sidebar {
    width: 30rem;
    border-left: 1px solid #EFEFEF;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.ambassadors-sidebar__sidebar-section {
    align-self: center;
    padding: 4rem 2rem;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    border-bottom: solid 1px #D8D8D8;
    width: 100%;
}

.ambassadors-sidebar__label {
    color: #494949;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.6rem;
    font-size: 1.2rem;
    display: block;
}

.ambassadors-sidebar__search {
    padding: 2rem;
    width: 100%;
    background: #EFEFEF;
}

.ambassadors-sidebar__section {
    width: 100%;
    padding: 0rem 2rem 3rem;
}

.ambassadors-sidebar__section-top {
    padding-top: 2rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ambassadors-sidebar__section-top .Polaris-Button {
    border-radius: 3px;
    background: #FFFFFF;
    padding: 0;
}

.ambassadors-sidebar__section .Polaris-Select__Content,
.ambassadors-sidebar__section .Polaris-Select__Backdrop {
    background: #FFFFFF;
    box-shadow: none;
    border: 0.1rem solid #c4cdd5;
    border-radius: 3px;
}

.ambassadors-sidebar__dropdown-arrow {
    display: none;
}

.ambassadors-sidebar__dropdown-up {
    display: none;
}



.ambassadors-main {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ambassadors-main__tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    border-bottom: 2px solid #EFEFEF;
}

.ambassadors-main__tab {
    padding: 2rem 0;
    width: 20%;
    max-width: 12rem;

    margin-right: 1rem;

    text-align: center;
    color: #BEBEBE;

    text-transform: uppercase;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.ambassadors-main__tab:hover {
    cursor: pointer;
}

.ambassadors-main__tab--active {
    border-bottom: 4px solid rgba(253,42,84,1);
}

.ambassadors-main__tab-value {
    color: #121212;
    font-size: 2rem;
    margin-bottom: 1.4rem;
    font-weight: bold;
}

.ambassadors-main__tab-name {
    color: #797979;
}

.ambassadors-main__pagination {
    padding: 2rem 4rem;
    width: 100%;
    
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.ambassadors-main__pagination-info {
    margin-right: 2rem;
}

.ambassadors-main__primary {
    flex-grow: 1;
}



.ambassadors-application {
    width: 100%;
    padding: 2rem 0 2rem;
    border-bottom: 1px solid #eaeaea;
}

.ambassadors-application:first-child {
    padding: 4rem 0 2rem;
}

.ambassadors-application__wrapper {
    /* box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3); */
    /* border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea; */
    border-radius: 0.5rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

}

.ambassadors-application__left {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0 2rem;
}

.ambassadors-application__profile-image {
    width: 115px;
    height: 115px;
    background: rgb(110, 77, 145);
    border-radius: 50%;

    margin: 2rem 0; 
}

.ambassadors-application__name {
    margin-bottom: 0.6rem;
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
}

.ambassadors-application__button-wrapper {
    margin-bottom: 2rem;
}

.ambassadors-application__rating {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.6rem;
}

.ambassadors-application__star {
    background: #ffffff;
    border: 1px solid #32114D;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    margin-right: 0.4rem; 
}

.ambassadors-application__star--active {
    background: #32114D;
}

.ambassadors-application__rating-label {
    font-size: 1rem;
    color: #717171;
    text-transform: uppercase;
    margin-bottom: 3rem;
}

.ambassadors-application__right {
    flex: 3;
    padding: 2rem 4rem;

    border-left: 1px solid #F0F0F0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.ambassadors-application__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    /* margin-bottom: 2rem; */
    width: 100%;
}

.ambassadors-application__more {
    width: 24px;
    height: auto;
    opacity: 0.5;
    cursor: pointer;
}

.ambassadors-application__more:active {
    opacity: 0.8;
}

.ambassadors-application__offer {
    font-weight: bold;
    font-size: 1.6rem;
    /* text-align: center; */
}

.ambassadors-application__info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
}

.ambassadors-application__info-item {
    width: 28%;
    margin-right: 3%;
    margin-bottom: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.ambassadors-application__top .ambassadors-application__info-item{
    width: 100%;
}

.ambassadors-application__info-value {
    font-size: 1.4rem;
    font-weight: bold;
}

.ambassadors-application__info-key {
    font-size: 1rem;
    color: #717171;
    text-transform: uppercase;
}

.ambassadors-application__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    width: 100%;
    flex-grow: 1;
}

.ambassadors-application__button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.ambassadors-application__button {
    border-radius: 9999px;
    padding: 0.4rem 1.6rem;
    cursor: pointer;
    opacity: 1;
    transition: all 100ms ease-out;
    position: relative;
    margin-right: 1rem;
    background: #EAEDF0;
    color: #EAEDF0;
}

.ambassadors-application__button:last-child {
    margin-right: 0;
}

.ambassadors-application__button:active {
    opacity: 0.7;
}

.ambassadors-application__button-spinner {
    transform: scale(0.4);
    height: 2rem;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    text-align: center;
    top: -1px;
}

.ambassadors-application__fav-button {
    background: #32114D;
    border: 2px solid #32114D;
    color: #FFFFFF;
}

.ambassadors-application__fav-button--active {
    background: none;
    color: #32114D;
}

.ambassadors-application__approve-button {
    background: #4DA009;
    border: 2px solid #4DA009;
    color: #FFFFFF;
}

.ambassadors-application__decline-button {
    background: #000;
    border: 2px solid #000;
    color: #fff;
}

.ambassadors-application__affiliate-button {
    background: #FD2856;
    border: 2px solid #FD2856;
    color: #FFFFFF;
}


.ambassadors-empty {
    padding: 2rem;
    
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 600px) {

    .ambassadors-main__tabs {
        display: none;
    }

    .ambassadors__wrapper {
        flex-wrap: wrap-reverse;
    }

    .ambassadors-sidebar__sidebar {
        width: 100%;
    }

    .ambassadors-sidebar__section {
        padding: 0;
    }

    .ambassadors-sidebar--mobile-tab {
        border-bottom: 2px solid #f0f0f0;
        padding: 1.2rem 2rem;
    }

    .ambassadors-sidebar__section-label {
        color: #494949;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.35rem;
        display: block;
    }
    
    .ambassadors-sidebar__section--active {
        border-bottom: 3px solid #FD2856;
    }

    .ambassadors-sidebar__content {
        display: none;
    }

    .ambassadors-sidebar__content--expanded {
        display: block;
        margin: 1rem 2rem;
    }

    .ambassadors-sidebar__dropdown-arrow {
        display: block;
        height: 10px;
        transition: all 300ms ease-in-out;
    }
    
    .ambassadors-sidebar__dropdown-up {
        display: block;
        height: 10px;
        transform: rotate(180deg);
        transition: all 300ms ease-in-out;
    }



    .ambassadors-application:first-child {
        margin: 2rem 1rem;
        padding: 0;
    }

    .ambassadors-application {
        margin: 2rem 1rem;
        padding: 0;
        width: 95%;
    }

    .ambassadors-application__wrapper {
        flex-direction: column;
        /* flex-wrap: wrap; */
        align-items: center;
    }

    .ambassadors-application__name {
        font-size: 1.2rem;
        line-height: 1.6rem;
    }

    .ambassadors-application__profile-image {
        /* width: 6rem; */
        margin: 1rem 0;
    }

    .ambassadors-application__info-item {
        justify-content: flex-start;
        width: 46%;
        margin-right: 4%;
    }

    .ambassadors-application__left {
        padding-top: 1rem;
    }

    .ambassadors-application__right {
        padding: 1.75rem 1.2rem;
        border-top: 1px solid #f0f0f0;
    }

    .ambassadors-application__info-key {
        line-height: 1.4rem;
    }

    .ambassadors-application__info-value {
        font-size: 1.2rem;
    }

    .ambassadors-application__offer {
        font-size: 1.4rem;
    }

    .ambassadors-application__button-group:first-child {
        display: none;
    }

    .ambassadors-application__more {
        width: 22px;
    }

    .social-icons__icon {
        margin-right: 0.75rem;
    }

}