.search {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.search__textfield-wrapper {
    position: relative;
    border-radius: 3px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: box-shadow, border-color;
    transition-property: box-shadow, border-color;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.64,0,.35,1);
    margin-bottom: 1rem;
}

.search__pagination-wrapper {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: none;
    
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.search__page-numbers {
    margin-right: 1rem;
}

.search__matches {
    border: 1px solid #c4cdd5;
    border-radius: 3px;
    margin-bottom: 1rem;
    width: 100%;
    border-spacing: 0;
}

.search__input {
    border: none;
    line-height: 2.4rem;
    font-size: 1.4rem;
    width: 100%;
}

.search__input:focus {
    outline: none;
    border: none;
}

.search__textfield-wrapper:focus-within {
    border-color: #5c6ac4;
    box-shadow: inset 0 0 0 0 transparent, 0 0 0 1px #5c6ac4;
}

.search__item {
    box-sizing: border-box;
}

.search__item td{
    padding: 8px 12px 7px 0;
}

.search__item td:first-child,
.search__item-header th:first-child{
    padding-left: 12px;
    padding-right: 1px;
}
.search__item td:last-child,
.search__item-header th:last-child{
    padding-right: 12px;
}

.search__item-header {
    width: 100%;
    box-sizing: border-box;

    background: #C4CDD4;
    font-weight: bold;
}

.search__item-header th{
    padding: 5px 12px 5px 0;
    vertical-align: middle;
}

.search__check{
    width: 3rem;
}

.search__check input{
    border-radius: 3px;
}

.search__title{
    text-align: left;
}

td.search__title{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.search__title img{
    height: 3rem;
    width: 3rem;
    object-fit: contain;
}

.search__item:first-child {
    padding-top: 12px;
}

.search__item:last-child {
    padding-bottom: 12px;
}

.search__qty{
    text-align: center;
}
.search__price{
    text-align: right;
}

.search .Polaris-Tabs {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-top: 1px solid #c4cdd5;
    border-left: 1px solid #c4cdd5;
    border-right: 1px solid #c4cdd5;
}

.search__nothing {
    width: 100%;
    padding: 12px;
    text-align: center;
}

.search .Polaris-Tabs__TabMeasurer {
    display: none;
}

@media screen and (max-width: 700px) and (min-width: 600px),
       screen and (max-width: 478px) {
        .search__matches th{
            text-align: left;
        }
        .search__item td{
            vertical-align: top;
        }
        .search__image{
            width: 5rem;
            height: 5rem;
            object-fit: contain;
        }

        .search__item td{
            padding: 1.2rem 1.2rem 1.2rem 0;
        }

        .search__product-info div{
            display: flex;
            gap: 1rem;
        }

        .search__product-info span{
            display: block;
        }

        .search__title{
            font-weight: bold;
        }

        .search__qty,
        .search__price{
           text-align: left;
           font-size: 1.3rem;
        }

        .search__qty::before{
            content: 'Qty: ';
            font-weight: bold;
        }
        .search__price::before{
            content: 'Price: ';
            font-weight: bold;
        }
}

@media screen and (max-width: 490px){
    .search__page-numbers{
        width: 100%;
    }
}

@media screen and (max-width: 360px){
    .search__image{
        width: 4rem;
        height: 4rem;
    }
}