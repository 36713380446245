.FeaturedAmbassador__Card {
    display: flex;
    justify-content: space-between;
}
.FeaturedAmbassador__Card img {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    margin-right: 15px;
    background: rgb(110, 77, 145);
}
.FeaturedAmbassador__Content {
    width: calc(100% - 5rem);
}
.FeaturedAmbassador__Content h1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1rem;
}
.FeaturedAmbassador__CatRow {
    display: flex;
    align-items: flex-end;
    margin-bottom: .3rem;
}
.FeaturedAmbassador__Category {
    font-size: 10px;
    line-height: 12px;
    color: #637381;
    text-transform: uppercase;
}
.FeaturedAmbassador__SeeAllCats {
    font-size: 10px;
    line-height: 12px;

    color: #FD2A54;
    margin-left: 4px;
}
.FeaturedAmbassador__Stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.FeaturedAmbassador__Stat .label {
    font-size: 14px;
    line-height: 17px;

    color: #637381;
}
.FeaturedAmbassador__Stat .value {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    text-align: left;

    color: #000000;
    display: flex;
    align-items: center;

    height: 24px;
}
.FeaturedAmbassador__ViewProfile {
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    color: #FD2A54;
}

.FeaturedAmbassador__Engagement {
    display: flex;
}