.offer-item__loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2rem;
}

.offer-list__wrapper{
    margin-bottom: 8rem;
}

.offer-list__search-and-sort {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 4rem;
}

.offer-list__search-and-sort .search {
    width: 100%;
    margin-right: 2rem;
}

.offer-list__search-and-sort .sort {
    display: flex;
    align-items: center;
}

.offer-list__search-and-sort .sort p {
    font-weight: bold;
    margin-right: 0.9rem;
    white-space: nowrap;
}

/* table */
.offer-list{
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid #dfe3e8;
}
.offer-list th{
    text-align: left;
    padding-bottom: .6rem;
    padding: 0 2rem .6rem 2rem;
}

.offer-list td{
    font-size: 1.4rem;
    border-top: 1px solid #dfe3e8;
    padding: 1.5rem 2rem 1.5rem 2rem;
    cursor: pointer;
}
.offer-list td:first-child,
.offer-list th:first-child{
    padding-left: 0;
}
.offer-list td:last-child,
.offer-list th:last-child{
    padding-right: 0;
}

@media screen and (min-width: 1181px) {
    .offer-list tr:hover td{
        background: rgba(223,227,232,.3);
    }
}

.offer-list .name{
    line-height: 1.4em;
    display: flex;
    min-width: 320px;
}

.offer-list .name img{
    margin-right: 0.9rem;
    width: 100px;
    height: 50px;
    object-fit: cover;
}
.offer-list .name p{
    font-weight: bold;
    margin-bottom: .3rem;
}

.offer-private-tag{
    color: #637381;
    border: 1px solid #e0e3e6;
    background: #F4F6F8;
    padding: 0.2rem .8rem;
    border-radius: 100px;
    text-transform: uppercase;
    font-weight: normal;
    font-size: .8em;
    display: inline-block;
    line-height: normal;
}

.offer-product-giveaway-tag{
    text-indent: -99999px;
    display: inline-block;
    width: 1.5rem;
    height: 1.75rem;
    background: url('../../assets/gift-icon.svg') no-repeat center center;
}

.offer-list .commission-rates,
.offer-list p.included-products{
    padding-left: 0;
    list-style-type: none;
    margin: 0;
    font-size: 0.9em;
    line-height: 1.3em;
    color: #637381;
    font-weight: normal;
}

.offer-list .ineligible{
    display: block;
    font-size: 0.9em;
    color: #637381;
    max-width: 10rem;
    line-height: 1.3em;
}

.offer-list .boost{
    line-height: 1.3em;
    min-width: 135px;
}

.offer-list .boost-status{
    display: block;
    line-height: normal;
    position: relative;
    padding-left: 1.7rem;
}

.offer-list .boost-status::before,
.offer-list .boost-status::after{
    content: '';
    position: absolute;
    border-radius: 100%;
}

.offer-list .boost-status::before{
    top: calc(50% - 0.6rem);
    left: 0;
    width: 1.2rem;
    height: 1.2rem;
}

.offer-list .boost-status::after{
    top: calc(50% - 0.3rem);
    left: 0.3rem;
    width: 0.6rem;
    height: 0.6rem;
}

.offer-list .boost-status[data-status="active"]::before{
    border: 1px solid #5EA521;
}
.offer-list .boost-status[data-status="active"]::after{
    background: #5EA521;
}
.offer-list .boost-status[data-status="inactive"]::before{
    border: 1px solid #FFA72C;
}
.offer-list .boost-status[data-status="inactive"]::after{
    background: #FFA72C;
}

.offer-list .boost-status + a{
    color: #fd2a54;
    font-size: .9em;
}

.offer-list .Polaris-Badge{
    text-transform: uppercase;
}

.offer-list .boost button{
    background-image: url('../../assets/up-arrow-in-circle.svg');
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 15px 15px;
    padding-left: 32px;
    background-color: #34135D;
    border-color: #34135D;
}

.offer-list .number{
    text-align: center;
}

.offer-list .money{
    text-align: right;
}

.offer-list .actions-wrapper{
    display: flex;
    align-items: center;
}

.offer-list .actions a{
    color: #737A80;
    font-weight: bold;
    border-radius: 6rem;
    border: 1px solid #737A80;
    padding: .3rem 1.5rem;
    margin-right: 2rem;
    display: block;
}

.offer-list .actions .Polaris-Button--plain{
    text-indent: -99999px;
    background: url(../../assets/ellipsis.svg) no-repeat center center;
    width: 4.1rem;
    height: 2.8rem;
}

.offer-list .actions .Polaris-Button__Content{
    width: 100%;
}

.offer-list .actions .Polaris-Button--plain .Polaris-Button__Icon{
    display: none;
}

.Polaris-Modal-Section p{
    margin-bottom: 2rem;
}

.Polaris-Modal-Section p:last-child{
    margin-bottom: 0;
}

/* pagination */
.offers-main__pagination {
    width: 100%;
    
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1.5rem;
}

.offers-main__pagination-info {
    margin-right: 2rem;
}

.offers-main .Polaris-Pagination__Button,
.offers-main .Polaris-Pagination__Button:disabled {
    background: none;
    border: none;
    border-radius: 3px;
}

.offer-item__error-message {
    margin-left: 1rem;
    color: #ff2754;
}

@media screen and (max-width: 1375px) {
    .offer-list td,
    .offer-list th{
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .offer-list .money{
        text-align: center;
    }
}

/* narrow banner */
@media screen and (max-width: 1220px) and (min-width: 1181px),
       screen and (max-width: 1090px) and (min-width: 1031px),
       screen and (max-width: 640px){
    .offer-list .name img{
        width: 70px;
    }

    .offer-list .name{
        min-width: 280px;
    }
}

/* 2 column */
@media screen and (max-width: 1180px){
    .offer-list{
        border-bottom: none;
    }
    .offer-list thead{
        display: none;
    }

    .offer-list tbody{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 5rem;
        row-gap: 5rem;
    }

    .offer-list tr{
        display: block;
        position: relative;
    }

    .offer-list td{
        display: block;
        border-top: none;
        padding: 0;
    }

    .offer-list td.money,
    .offer-list td.number{
        text-align: left;
    }

    .offer-list .name{
        min-width: auto;
        align-items: flex-start;
        margin-bottom: 0.5rem;
        padding-right: 11.5rem;
    }

    .offer-list .name p{
        margin-left: -0.3rem;
    }

    .offer-list .name p > span{
        display: inline-block;
        margin-left: 0.5rem;
    }

    .offer-list .name .Polaris-Badge{
        font-size: 1.2rem;
        line-height: 1.6em;
        margin-bottom: 0.3rem;
        vertical-align: top;
        margin-left: 0;
    }

    .offer-list .actions{
        position: absolute;
        right: 0;
        top: 0;
    }

    .offer-list .products,
    .offer-list .ambassadors,
    .offer-list .revenue{
        display: inline-block;
        margin-right: 2rem;
        padding-left: 1.5rem;
        font-size: 1.2rem;
    }

    .offer-list .products{
        padding-left: calc(11rem + 1.7rem);
        background: url(../../assets/cart-icon.svg) no-repeat 11rem 50%;
        background-size: auto 12px;
    }
    
    .offer-list .ambassadors{
        background: url(../../assets/audience-icon.svg) no-repeat 0 50%;
        background-size: auto 12px;
        padding-left: 2.1rem;
    }

    .offer-list .revenue{
        background: url(../../assets/revenue-icon.svg) no-repeat 0 50%;
        background-size: 14px;
        padding-left: 1.8rem;
    }

    .offer-list .boost{
        padding-left: 11rem;
        margin-top: 1rem;
    }

    .offer-list .boost a,
    .offer-list .boost .ineligible{
        display: none;
    }
}

/* hide ellipsis button */
@media screen and (max-width: 1180px) and (min-width: 1031px),
       screen and (max-width: 760px) and (min-width: 601px),
       screen and (max-width: 400px){
    .offer-list .actions a{
        margin-right: 0;
    }

    .offer-list .actions-wrapper div{
        display: none;
    }
}

/* narrow banner (padding fix) */
@media screen and (max-width: 1090px) and (min-width: 1031px),
       screen and (max-width: 640px){
    .offer-list .products{
        padding-left: calc(8rem + 1.7rem);
        background-position: 8rem 50%;
    }

    .offer-list .boost{
        padding-left: 8rem;
    }
}

/* 1 column */
@media screen and (max-width: 1030px){
    .offer-list tbody{
        display: block;
    }

    .offer-list tr{
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .offer-list .actions{
        top: 0;
    }
}

/* hide sort label */
@media screen and (max-width: 890px){
    .offer-list__search-and-sort .sort p{
        display: none;
    }
}

/* stack sort block */
@media screen and (max-width: 700px) and (min-width: 600px),
       screen and (max-width: 510px){
    .offer-list__search-and-sort{
        flex-wrap: wrap;
    }

    .offer-list__search-and-sort .search{
        margin-right: 0;
        margin-bottom: 1.4rem;
    }
    .offer-list__search-and-sort .sort p{
        display: block;
    }
}

/* stacked banner */
@media screen and (max-width: 630px) and (min-width: 601px),
       screen and (max-width: 500px){
    .offer-list .name img{
        width: 100%;
        height: 11rem;
        margin-bottom: 0.8rem;
        margin-right: 0;
    }

    .offer-list .name{
        min-width: auto;
        flex-wrap: wrap;
        padding-right: 0;
    }

    .offer-list .products{
        padding-left: 1.7rem;
        background-position: 0 50%;
    }

    .offer-list .boost{
        padding-left: 0;
    }

    .offer-list .actions{
        bottom: 1.5rem;
        top: auto;
    }
}

@media screen and (max-width: 420px){
    .offer-item__options {
        flex-wrap: wrap;
    }
    .offer-item__search-section{
        margin-bottom: 1.5rem;
        margin-right: 0;
    }
}