.head_container{
    margin-bottom: 3rem;
    font-size: 16px;
    margin-top: 1rem;
}

.amb{
    font-size: 20px;
}
.amb_Bold{
    font-size: 20px;
    font-weight: bold;
}

.display_inline{
    display: inline;
}

.campaign_rates_container{
    padding: 24px;
}

.selection_container{
    padding: .6rem 2.5rem 0;
}

.selector_text{
    font-size:20px;
    margin-top:10px;
}

.custom_container{
    margin-top: 50px;
}

.button_container{
    padding-top:50px;
}

.campaign-rates__heading {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.campaign-rates__selection {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.campaign-rates__selection li{
    margin-bottom: 2.5rem;
}

.campaign-rates__selection li:last-child{
    margin-bottom: 0;
}

.campaign-rates__selection .selection_container.percent .Polaris-TextField{
    width: 92px;
}

.campaign-rates__selection .selection_container.flat{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.campaign-rates__selection .selection_container.flat .Polaris-TextField{
    width: 110px;
}

.campaign-rates__selection .selection_container.flat .Polaris-TextField, 
.campaign-rates__selection .selection_container.flat span.per{
    margin-right: 1rem;
}

.campaign-rates__selection .selection_container.flat .Polaris-Labelled__Error{
    display: none;
}
.campaign-rates__selection .selection_container.flat .Polaris-InlineError{
    width: 100%;
    margin-top: 0.4rem;
}


.campaign-rates__cookie-summary {
    font-size: 16px;
    padding-top: 2.4rem;
    border-top: 1px solid #dfe3e8;
}

.campaign-rates__selection .Polaris-Choice{
    padding-bottom: 0;
}

.campaign-rates__selection .Polaris-Choice__Label{
    font-weight: bold;
}

.campaign-rates__selection .Polaris-Choice__HelpText{
    font-size: 1.4rem;
    margin-top: -0.3rem;
}