.PerformanceSidebar {
    width: 30rem;
    border-left: 1px solid #EFEFEF;
}

.PerformanceSidebar .expand-filters{
    display: none;
}

.PerformanceSidebar__Filters{
    list-style: none;
    padding: 2rem 2rem 0;
    margin: 0;
}

.PerformanceSidebar__Filters > li{
    margin-bottom: 3rem;
}

.PerformanceSidebar__Filters li .filter-label{
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: .6rem;
    display: block;
    color: #494949;
    font-size: 1.2rem;
}

.PerformanceSidebar__Search {
    padding: 2rem;
    width: 100%;
    background: #EBECF0;
}

.PerformanceSidebar .Polaris-DatePicker {
    width: 100%;
}

.PerformanceSidebar__Filter-Icon {
    height: 16px;
}

@media only screen and (max-width: 1370px) and (min-width: 1221px) {
    .PerformanceSidebar{
        width: 25rem;
    }
}

@media only screen and (max-width: 1220px) {
    .PerformanceSidebar{
        width: 100%;
        border: 1px solid #EFEFEF;
    }

    .PerformanceSidebar .expand-filters{
        display: block;
        position: relative;
    }

    .PerformanceSidebar .expand-filters button{
        width: 100%;
        padding: 1.8rem 2rem 1.8rem calc(2rem + 18px + 1.2rem);
        border: none;
        background: none;
        text-align: left;
    }

    .PerformanceSidebar .expand-filters::after{
        content: '';
        position: absolute;
        top: calc(50% - 8px);
        left: 2rem;
        width: 18px;
        height: 16px;
        background: url(../../assets/icon_filter.svg);
    }

    .PerformanceSidebar__Filters{
        display: none;
    }

    .PerformanceSidebar__Filters[data-displayed="true"]{
        display: block;
        border-top: 3px solid #FD2856;
    }
}