.settings-payment__primary {
    display: block;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 20px;
}

.pplogin{text-align: center;}
.pplogin img, .paypal-button img{cursor: pointer;}
.paypal_close{text-align: center; margin-top: 10px; color: #007ace; cursor: pointer;}
.paypal-loading{display: flex; height: 100%; justify-content: center; align-items: center;}

.settings-payment__section-label {
    width: 100%;
    background-color: #f3f4f7;
    margin-top: 3rem;
}

.settings-payment__section-title {
    color: #7e8f9f;
    text-transform: uppercase;
    margin-left: 4rem;
}

.settings-payment__input {
    border: none !important;
    font-size: 1em;
    text-align: right;
    padding-right: 2em;
}

.settings-payment__advertiser-address {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.settings-payment--input-label {
    color: hsl(0, 0%, 45%);
}

.settings-payment__input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 4rem;
    border-bottom: 1px solid #EFEFEF;
}

.settings-payment__edit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.settings-payment__edit-text {
    font-size: .75rem;
    color: #ff2754;
    margin-left: 1rem;
}

.onboarding--paypal-connected{
    background: url(../../assets/paypal-logo.svg) no-repeat center top;
    background-size: 82px 20px;
    padding-top: 12.7rem;
    position: relative;
    font-size: 1.6rem;
}

.onboarding--paypal-connected .settings-payment__disconnect{
    font-size: .875em;
    display: block;
    text-align: center;
    cursor: pointer;
    color: #fd2a54;
}

.onboarding--paypal-connected::before,
.onboarding--paypal-connected::after{
    content: '';
    position: absolute;
}

.onboarding--paypal-connected::before{
    top: 4.3rem;
    left: calc(50% - 3rem);
    width: 6.1rem;
    height: 6.1rem;
    border: 3px solid #008826;
    border-radius: 100%;
}

.onboarding--paypal-connected::after{
    width: 3.1rem;
    height: 1.7rem;
    border-left: 3px solid #008826;
    border-bottom: 3px solid #008826;
    transform: rotate(-45deg);
    top: 6rem;
    left: calc(50% - 1.5rem);
}

@media screen and (max-width: 599px){
    .onboarding--paypal-connected{
        font-size: 1.3rem;
    }
} 