.products-list__products-wrapper a{
    color: #fd2a54;
}

.products-list__products-wrapper .intro{
    margin-bottom: 3rem;
}

/* filters */
.products-list__products-wrapper .filters{
    margin-bottom: 1rem;
}

.products-list__products-wrapper .search-and-sort{
    display: flex;
    margin-bottom: 1rem;
}

.products-list__products-wrapper .search{
    margin-right: 2rem;
    display: flex;
}
.products-list__products-wrapper .search > div:first-child{
    width: 100%;
}

.products-list__products-wrapper .sort{
    display: flex;
    white-space: nowrap;
    align-items: center;
    gap: 1rem;
}

.products-list__products-wrapper .sort p{
    font-weight: bold;
}

/* table */
.products-list__products-wrapper .products-table{
    width: 100%;
    border-spacing: 0;
    margin-bottom: 1.5rem;
}

.products-list__products-wrapper .products-table .product .Polaris-Choice__Label{
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
}

.products-list__products-wrapper .products-table th{
    text-align: left;
    white-space: nowrap;
}

.products-list__products-wrapper .products-table tbody tr{
    cursor: pointer;
}

@media screen and (min-width: 851px){
    .products-list__products-wrapper .products-table tr:hover td{
        background: #F4F6F8;
    }
}

.products-list__products-wrapper .products-table td,
.products-list__products-wrapper .products-table th{
    padding: 1.2rem 1rem;
    border-bottom: 1px solid #E0E0E0;
}

.products-list__products-wrapper .products-table .product-wrap{
    display: flex;
    gap: 1rem;
    align-items: center;
    font-weight: bold;
}

.products-list__products-wrapper .products-table .Polaris-Thumbnail::after{
    box-shadow: none;
}

.products-list__products-wrapper .products-table .price,
.products-list__products-wrapper .products-table .qty,
.products-list__products-wrapper .products-table .revenue{
    text-align: center;
}

/* bulk actions*/
.products-list__products-wrapper .products-table .product-actions{
    background: #F4F6F8;
    cursor: default;
    position: sticky;
    top: 0;
    z-index: 100;
}

.products-list__products-wrapper .products-table .product-actions-wrapper,
.products-list__products-wrapper .products-table .actions{
    display: flex;
    align-items: center;
}

.products-list__products-wrapper .products-table .actions{
    margin-left: 1.4rem;
}

.products-list__products-wrapper .products-table .deactivate{
    background-color: #212B36;
    border-color: #212B36;
    background-image: url('../../assets/circle-x-white.svg');
}

.products-list__products-wrapper .products-table .activate{
    background-image: url('../../assets/power-icon-white.svg');
}

.products-list__products-wrapper .products-table .activate,
.products-list__products-wrapper .products-table .deactivate{
    background-repeat: no-repeat;
    background-position: 1.5rem center;
    padding-left: 3.6rem;
}

/* updating */
.products-list__products-wrapper[data-processing="true"]:before{
    content: '';
    background: rgba(255, 255, 255, .7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.products-list__products-wrapper[data-processing="true"] .Polaris-Spinner{
    position: fixed;
    z-index: 105;
    top: calc(50% - 2.2rem);
    left: calc(50% - 2.2rem);
}

/* skeleton loaders */
.products-list__products-wrapper[data-loading="true"] .product .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    width: 120px;
}
.products-list__products-wrapper[data-loading="true"] .price .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    width: 43px;
}
.products-list__products-wrapper[data-loading="true"] .qty .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    width: 25px;
}
.products-list__products-wrapper[data-loading="true"] .revenue .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    width: 38px;
}
.products-list__products-wrapper[data-loading="true"] .status .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    width: 65px;
}

.products-list__products-wrapper .sync {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sync-wrapper {
    margin-top: 3rem;
}


@media screen and (min-width: 851px) {
    .products-list__products-wrapper[data-loading="true"] .product-wrap{
        display: flex;
        align-items: center;
    }
    .products-list__products-wrapper[data-loading="true"] .product .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
        margin-left: 1rem;
    }
    .products-list__products-wrapper[data-loading="true"] .Polaris-SkeletonThumbnail--sizeSmall{
        margin-left: 3rem;
    }
    .products-list__products-wrapper[data-loading="true"] .price .Polaris-SkeletonBodyText__SkeletonBodyTextContainer,
    .products-list__products-wrapper[data-loading="true"] .qty .Polaris-SkeletonBodyText__SkeletonBodyTextContainer,
    .products-list__products-wrapper[data-loading="true"] .revenue .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
        margin: 0 auto;
    }
}

/* attach collections dropdown to search field */
@media screen and (min-width: 851px){
    .products-list__products-wrapper .search .Polaris-TextField__Backdrop{
        border-radius: 3px 0 0 3px;
    }

    .products-list__products-wrapper .search .Polaris-Select__Backdrop,
    .products-list__products-wrapper .search .Polaris-Select__Content{
        border-radius: 0 3px 3px 0;
        border-left: none;
    }
}

/* different layout */
@media screen and (max-width: 850px) {
    /* filters */
    .products-list__products-wrapper .search-and-sort{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 3.6rem 3.6rem;
        row-gap: 1rem;
    }
    .products-list__products-wrapper .search{
        grid-column-start: 1;
        grid-column-end: 3;
        position: relative;
    }
    .products-list__products-wrapper .search > div:nth-child(2){
        position: absolute;
        top: 4.6rem;
        width: calc(50% - 2rem);
    }
    .products-list__products-wrapper .sort{
        grid-column-start: 2;
        grid-column-end: 3;
    }

    .products-list__products-wrapper .sort > div{
        width: 100%;
    }

    /* table */
    .products-list__products-wrapper .products-table th.price,
    .products-list__products-wrapper .products-table th.revenue,
    .products-list__products-wrapper .products-table th.qty,
    .products-list__products-wrapper .products-table th.status{
        display: none;
    }

    .products-list__products-wrapper .products-table tbody td{
        display: block;
        border-bottom: none;
        padding: 0;
    }

    .products-list__products-wrapper .products-table tbody tr{
        padding: 1.2rem 1rem;
        border-bottom: 1px solid #E0E0E0;

        display: grid;
        grid-template-columns: 11.5rem 10rem 1fr;
    }

    .products-list__products-wrapper .products-table tbody tr:hover{
        background: #F4F6F8;
    }

    .products-list__products-wrapper .products-table .product-wrap,
    .products-list__products-wrapper[data-loading="true"] .product-wrap{
        display: grid;
        grid-template-columns: 11.5rem 1fr;
        grid-gap: 0;
        position: relative;
    }

    .products-list__products-wrapper .products-table tbody .product{
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .products-list__products-wrapper .products-table tbody .product-name,
    .products-list__products-wrapper[data-loading="true"] .product .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
        grid-column-start: 2;
        grid-column-end: 3;
        margin-bottom: 0.5rem;
    }

    .products-list__products-wrapper .products-table .Polaris-Thumbnail--sizeSmall,
    .products-list__products-wrapper .products-table .Polaris-SkeletonThumbnail--sizeSmall{
        width: 7.5rem;
        height: 7.5rem;
        position: absolute;
        top: 0;
        left: 2.7rem;
    }

    .products-list__products-wrapper .products-table .price,
    .products-list__products-wrapper .products-table .qty,
    .products-list__products-wrapper .products-table .revenue,
    .products-list__products-wrapper .products-table .status{
        text-align: left;
        grid-column-start: 2;
        font-size: 1.3rem;
    }

    .products-list__products-wrapper .products-table .price{
        margin-bottom: 0.2rem;
    }

    .products-list__products-wrapper .products-table .revenue{
        grid-column-start: 3;
    }

    .products-list__products-wrapper .products-table tbody .status{
        margin-top: 0.5rem;
    }
    
    .products-list__products-wrapper .products-table tbody .price::before{
        content: 'Price: ';
        color: #637381;
    }
    .products-list__products-wrapper .products-table tbody .qty::before{
        content: 'Qty Sold: ';
        color: #637381;
    }
    .products-list__products-wrapper .products-table tbody .revenue::before{
        content: 'Revenue: ';
        color: #637381;
    }

    /* skeleton loaders */
    .products-list__products-wrapper[data-loading="true"] .price .Polaris-SkeletonBodyText__SkeletonBodyTextContainer,
    .products-list__products-wrapper[data-loading="true"] .qty .Polaris-SkeletonBodyText__SkeletonBodyTextContainer,
    .products-list__products-wrapper[data-loading="true"] .revenue .Polaris-SkeletonBodyText__SkeletonBodyTextContainer,
    .products-list__products-wrapper[data-loading="true"] .status .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
        display: inline-block;
    }
}

/* bulk actions on the next line */
@media screen and (max-width: 680px) and (min-width: 600px),
       screen and (max-width: 475px){
    .products-list__products-wrapper .products-table .product-actions-wrapper{
        flex-wrap: wrap;
    }
    .products-list__products-wrapper .products-table .actions{
        width: 100%;
        margin-left: 2.2rem;
    }
}

/* stacked filters, smaller product photo */
@media screen and (max-width: 430px) {
    /* filters */
    .products-list__products-wrapper .search-and-sort{
        display: block;
    }
    .products-list__products-wrapper .search{
        display: block;
        margin-bottom: 1rem;
    }
    .products-list__products-wrapper .search > div:first-child{
        margin-bottom: 1rem;
    }
    .products-list__products-wrapper .search > div:nth-child(2){
        position: static;
        top: auto;
        width: 100%;
        display: flex;
        gap: 1rem;
        align-items: center;
    }
    .products-list__products-wrapper .search > div:nth-child(2)::before{
        content: 'Filter By: ';
        font-weight: bold;
        white-space: nowrap;
    }

    .products-list__products-wrapper .search > div:nth-child(2) .Polaris-Select{
        width: 100%;
    }

    /* table */
    .products-list__products-wrapper .products-table tbody tr{
        grid-template-columns: 9.5rem 10rem 1fr;
    }

    .products-list__products-wrapper .products-table .product-wrap{
        grid-template-columns: 9.5rem 1fr;
    }

    .products-list__products-wrapper .products-table tbody .product .Polaris-Thumbnail--sizeSmall,
    .products-list__products-wrapper .products-table .Polaris-SkeletonThumbnail--sizeSmall{
        width: 5.5rem;
        height: 5.5rem;
    }
}