.llama-tabbed-nav{
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 3rem;
}
.llama-tabbed-nav ul{
    list-style: none;
    padding-left: 0;
    display: flex;
    margin: 0;
}

.llama-tabbed-nav li{
    text-align: center;
    flex: 1;
}

.llama-tabbed-nav li a{
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.4rem;
    color: #637381;
    padding: 1.8rem 0 1.4rem;
    border-bottom: 4px solid transparent;
    white-space: nowrap;
}

@media screen and (min-width: 861px) {
    .llama-tabbed-nav[data-type="condensed"] li{
        text-align: left;
        flex: none;
        margin-right: 3rem;
    }

    .llama-tabbed-nav[data-type="condensed"] li:last-child{
        margin-right: 0;
    }

    .llama-tabbed-nav[data-type="scrolling"]{
        overflow: auto;
        width: calc(100vw - 200px - 5rem); /* 100% viewport width - side nav width - page padding */
    }
    
    .llama-tabbed-nav[data-type="scrolling"] li{
        padding: 0 1.5rem;
    }

    .llama-tabbed-nav[data-type="scrolling"] li:first-child{
        padding-left: 0;
    }

    .llama-tabbed-nav[data-type="scrolling"] li:last-child{
        padding-right: 0;
    }

    .llama-tabbed-nav li a.active,
    .llama-tabbed-nav li a:hover{
        color: #fd2a54;
    }

    .llama-tabbed-nav li a.active{
        border-bottom-color: #fd2a54;
    }
}

@media screen and (max-width: 860px) {
    .llama-tabbed-nav ul{
        display: block;
    }

    .llama-tabbed-nav li{
        border-bottom: 1px solid #e0e0e0;
    }

    .llama-tabbed-nav .current-tab{
        border: none;
        border-bottom: 4px solid #fd2a54;
        color: #fd2a54;
        padding-top: 2rem;
        padding-bottom: 1.5rem;
        background-color: transparent;
        width: 100%;
        text-align: left;
        font-weight: bold;
        position: relative;
        font-size: 1em;
        text-transform: uppercase;
    }

    .llama-tabbed-nav .current-tab::after{
        position: absolute;
        content: url(../../assets/dropdown-arrow.svg);
        width: 20px;
        height: 12px;
        transition: transform 300ms ease-in-out;
        right: 12px;
        top: calc(50% - 6px);
    }

    .llama-tabbed-nav[data-open="true"] .current-tab::after{
        transform: rotate(180deg);
    }
}