/* LLAMA BRANDING */
:root{
  --llama-pink: #fd2a54;
  --llama-subdued: #637381;
  --llama-main: #212B36;
  --llama-purple: #34135D;
  --llama-border: #E0E0E0;
  --llama-gray-bg: #F4F6F8;
}

/** 
 *  COLORS:
 *
 *  DARK PURPLE: #34135D
 *  PURPLE: #4C2A93
 *  LIGHT PURPLE: #6D4AB6
 *  GRADIENT: linear-gradient(90deg, rgba(254,161,56,1) 0%, rgba(253,42,84,1) 100%);
 *  YELLOW: #F3AA4A
 *  PINK: #EB4159
 *  GREEN: #49AE6B
 *  RED: #F6323F
 */

 #drift-widget {
  bottom: 0 !important;
  right: 0 !important;
}

#drift-widget-container,
.drift-conductor-item{
  display: none !important;
  z-index: 1000 !important;
}


@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/ProximaNova-Regular.otf');
}

@font-face {
  font-family: 'Proxima Nova Light';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/ProximaNova-Light.otf');
}

@font-face {
  font-family: 'Proxima Nova Semibold';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/ProximaNova-Semibold.otf');
}

@font-face {
  font-family: 'Proxima Nova Bold';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/ProximaNova-Bold.otf');
}

a {
  text-decoration: none;
}

.App {
  display: flex;
  min-height: calc(100vh - 8rem);
  background: #fff;
}

.App__Main {
  flex: 9;
  min-height: 100%;
  background: #FFFFFF;
}

/* Info tooltip */
.info-tooltip {
  background: #4A4A4A;
  color: #FFFFFF;
  font-size: 1rem;
  display: inline-block;
  line-height: 1rem;
  padding: .3rem .7rem;
  border-radius: 1rem;
  font-weight: bold;
  margin-left: -.1rem;
  transform: scale(0.8) translateY(-3px);
  vertical-align: top;
  cursor: default;
}

button.plain-text{
  border: none;
  background: none;
  color: var(--llama-pink);
  padding: 0;
  cursor: pointer;
}

/* Global Polaris overrides */
.Polaris-Card + .Polaris-Card {
  margin-bottom: 2rem;
}

.campaign-container--active .Polaris-Page__Header--hasSeparator + .Polaris-Page__Content {
  margin-top: 0;
}

.campaign-container--active .Polaris-Page__Content > div > .Polaris-Tabs {
  margin-bottom: 1rem;
}

.App__Main .Polaris-Card {
  border: 1px solid #DCE5ED;
  border-radius: .7rem;
  box-shadow: 0 0 1.1rem rgba(0, 0, 0, .15);
}

.App__Main .Polaris-Button {
  border-radius: 999px;
}

.App__Main .Polaris-Button--primary,
.App__Main .Polaris-Button--primary:hover {
  background: rgba(253,42,84,1);
  border: none;
  box-shadow: none;
  z-index: 100;
  opacity: 1;
  transition: all 100ms ease-out;
}

.Polaris-Button{
  border-radius: 999px !important;
}

.Polaris-Button--primary{
  background: var(--llama-pink) !important;
  border: none !important;
  box-shadow: none !important;
}
.Polaris-Button--primary.Polaris-Button--disabled {
  background: rgba(253,42,84,1) !important;
  border: none !important;
  box-shadow: none !important;
  opacity: .5 !important;
}

.App__Main .Polaris-Button--primary:hover {
  opacity: 0.8;
}

.App__Main .Polaris-Checkbox__Backdrop {
  border-radius: 1px;
}

.App__Main .Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop,
.Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop,
.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop,
.App__Main .Polaris-RadioButton__Icon{
  background: var(--llama-pink) !important;
}

.App__Main .Polaris-Checkbox__Backdrop::after {
  background: #FFFFFF;
  border-radius: 1px;
}

.App__Main .Polaris-Select__Content,
.App__Main .Polaris-Select__Backdrop {
    background: #FFFFFF;
    box-shadow: none;
    border: 0.1rem solid #c4cdd5;
    border-radius: 3px;
}

.App__Main .Polaris-Select--error .Polaris-Select__Content{
  background: none;
  border: none;
}
.App__Main .Polaris-Select--error .Polaris-Select__Backdrop{
  background: #bf0711;
  border-color: transparent;
}

.App__Main .Polaris-Labelled__HelpText,
.App__Main .Polaris-Choice__HelpText{
  font-size: 1.3rem;
  line-height: 1.4em;
}

.App__Main .Polaris-Frame-Toast,
.Polaris-Frame-ToastManager .Polaris-Frame-Toast {
  background:  #33185C;
}

.App__Main .Polaris-Frame-Toast--error {
  background:#f8333c; 
}

.App__Main .Polaris-Frame {
  min-height: auto;
}

.App__Main .plain-button{
  all: unset;
  color: var(--llama-pink);
  cursor: pointer;
}

.accessible-click-wrapper{
  all: unset;
  cursor: pointer;
}

.nivo-graph-tooltip-wrapper{
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  padding: 5px 9px;
}

.nivo-graph-tooltip-item{
  display: flex;
  white-space: pre;
  align-items: center;
  margin-bottom: 9px;
}

.nivo-graph-tooltip-item:last-child{
  margin-bottom: 0;
}

.nivo-graph-tooltip-color-block{
  display: block;
  width: 17px;
  height: 17px;
  margin-right: 9px;
}

.nivo-graph-tooltip-date{
  font-size: .8em;
  font-weight: bold;
  display: block;
  margin-bottom: 6px;
}

/* .drift-widget {
  display: none;
} */

@media screen and (max-width: 768px) {
  .Polaris-Page-Header--mobileView .Polaris-Page-Header__PrimaryActionWrapper{
    margin-bottom: 1rem;
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 599px) {
  .App {
    display: flex;
    flex-wrap: wrap;
    min-height: calc(100vh - 6rem);
  }

  .App__Main {
    flex: 10;
    width: 100%;
  }

  .App__Navigation-Sidebar {
    width: 100%;
    flex: 10;
  }

  .App__Main .Polaris-Card {
    border-radius: 0;
    border: none;
    box-shadow: none;
  }

  .App__Main .Polaris-Page-Header__Header--hasSeparator {
    border-bottom: none;
  }

}

@media screen and (max-width: 490px){
  .Polaris-Page__Content{
      padding: 0 2rem;
  }
}
