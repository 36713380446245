.settings-general__wrapper .Polaris-FormLayout{
    margin-top: 0;
}

.settings-general{
    /* max-width: 500px; */
    margin: 0 auto;
}
.settings-general fieldset {
    display: flex;
    flex-direction: column;
    border: none;
    padding: 0;
    margin-bottom: 3rem;
}

.settings-general fieldset legend {
    width: 100%;
    background-color: #f3f4f7;
    margin-top: 3rem;
    color: #7e8f9f;
    text-transform: uppercase;
    padding: 0.3rem 1.5rem;
}

.settings-general__icon{
    padding-top: 12px;
    margin-left: 30px;
    padding-bottom: 12px;
}

.settings-general__display {
    border: none;
    font-size: 1em;
    text-align: right;
    /* margin-right: 1.5em; */
    border: 1px solid transparent;
    border-top: none;
    border-bottom: none;
    line-height: 1.4em;
}

.settings-general__input {
    border: 1px solid #7e8f9f;
    font-size: 1em;
    text-align: left;
    padding: 0.4rem;
    border-radius: 3px;
    max-width: 30rem;
    height: 3rem;
}

.settings-general__edit .llama-button{
    margin-left: 1.5rem;
}

/* .settings-general__input-align {
    display: flex;
    justify-content: flex-end;
    max-width: 200px;
} */

/* .settings-general--input{
    margin-top: 8px;
    padding-bottom: 12px;
    width: 200px;
    margin-left: 30px;
} */

.settings-general__advertiser-address {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.settings-general--input-label .hint{
    color: #637381;
    font-size: 0.9em;
    display: block;
    line-height: 1.4em;
}

.settings-general__input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    padding: 2rem 1.5rem;
    border-bottom: 1px solid #EFEFEF;
}

.settings-general__edit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.settings-general__edit-text,
.settings-general__edit-password {
    font-size: 1.5rem;
    color: #ff2754;
    cursor: pointer;
    padding: 0;
    background: none;
    border: none;
    margin-left: 1.5rem;
}

/*CHANGE PASSWORD*/

.settings-general__password-modal {
    max-width: 38rem;
    margin: 0 auto;
    /* background: #f7f7f7;
    padding: 1rem 2rem;
    border-radius: 8px; */
}

.settings-general__password-modal .amplify-form-row{
    margin-bottom: 0 !important;
}

.settings-general__password-modal .amplify-form-row input{
    margin-bottom: 3px;
}

.settings-general__password-modal .amplify-form-row input#old-password,
.settings-general__password-modal .amplify-form-row input#confirm-password{
    margin-bottom: 15px;
}

.settings-general__password-modal span[data-test="password-match-span"],
.settings-general__password-modal .amplify-password-requirements{
    margin-bottom: 15px;
    display: block;
}

.settings-general__password-modal span[data-test="password-match-span"]{
    margin-top: -12px;
}

.settings-general__password-modal .Polaris-InlineError{
    margin-bottom: 15px;
    margin-top: -10px;
}
.settings-general__password-modal .Polaris-Banner{
    margin-bottom: 1.5rem;
}

@media screen and (max-width: 650px) {
    .settings-general fieldset{
        font-size: 1.3rem;
    }

    .settings-general__input-row {
        padding: 1rem 1.25rem;
    }
    
    .settings-general--input-label {
        font-size: 1.2rem;
    }

    .settings-general__edit input,
    .settings-general__edit .Polaris-Select__SelectedOption {
        font-size: 1.2rem;
    }

    .settings-general__display {
        font-size: 1.2rem;
        margin-right: .5rem;
    }

    .settings-general__edit-text,
    .settings-general__edit-password,
    .settings-general__edit .llama-button {
        font-size: 1.2rem;
    }

    .settings-general__save {
        width: 2rem;
    }
}

@media screen and (max-width: 440px) {
    .settings-general__input-row[data-editing="true"]{
        flex-wrap: wrap;
    }
    .settings-general__input-row[data-editing="true"] .settings-general__edit{
        display: block;
        width: 100%;
    }
    .settings-general__input-row[data-editing="true"] .llama-button{
        margin-left: 0;
        width: 100%;
        margin-top: 1rem;
        padding: 0.7rem;
    }
}