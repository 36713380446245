.num-coupon-product-selections{
    display: flex;
    justify-content: space-between;
    background: #EBECF0;
    padding: 1rem 1.2rem;
    border-radius: .5rem;
    position: relative;
    transform: translateX(-.5rem);
    width: calc(100% + .5rem);
    margin-top: 0.4rem;
}

.num-coupon-product-selections::after{
    content: '';
    border-bottom: 1.1rem solid #EBECF0;
    border-left: .8rem solid transparent;
    border-right: .8rem solid transparent;
    position: absolute;
    top: -.8rem;
    left: .5rem;
}

.coupon-products-list{
    max-height: 33rem;
    overflow: auto;
    margin-top: 2rem;
}

.coupon-products-list table{
    border-spacing: 0;
    width: 100%;
}

.coupon-products-list .Polaris-Choice__Label{
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
}

.coupon-products-list th{
    text-align: left;
    white-space: nowrap;
    line-height: normal;
}
.coupon-products-list th,
.coupon-products-list td{
    padding: 1rem 1.2rem;
    border-bottom: 1px solid #E0E0E0;
}

.coupon-products-list thead th{
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 10;
}

/* .coupon-products-list tbody{
    display: block;
    height: 33rem;
    overflow: auto;
}

.coupon-products-list thead,
.coupon-products-list tbody tr{
    display: table;
    width: 100%;
    table-layout: fixed;
} */

.coupon-products-list tbody tr:last-child td{
    border-bottom: none;
}

.coupon-products-list td{
    vertical-align: top;
}

.coupon-products-list .product{
    width: 36rem;
}

.coupon-products-list .product span{
    display: flex;
}

.coupon-products-list .product img{
    align-self: flex-start;
    margin-right: 1.1rem;
}

.coupon-products-list .amount{
    text-align: right;
}

.coupon-products-list .checkbox{
    padding-right: 0;
    padding-left: 0;
    width: 2.6rem;
}

.coupon-products-list img{
    width: 2.6rem;
    height: 2.6rem;
    object-fit: contain;
}

.coupon-modal-section-wrap .Polaris-Modal-Section{
    padding-bottom: 0;
}