.import-affiliates__wrapper {
    margin: 0 0 2rem 0;
}

.import-affiliates__navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.import-affiliate__migration-type {
    width: 196px;
    height: 196px;
    border-radius: 8px;
    border: 1px solid #dfdfdf;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: bold;
    cursor: pointer;
    margin: 1rem;
}

.import-affiliate__migration-type--active {
    border: 1px solid #c4c4c4;
    background-color: #f0f4fa;
}

.import-affiliate__refersion-logo {
    width: 140px;
}

.import-affiliate__leaddyno-logo {
    height: 60px;
}

.import-affiliate__affiliatly-logo {
    width: 140px;
}

.import-affiliates__migration-instructions {
    margin-bottom: 2rem;
}

.import-affiliates__instruction-title {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 0.6rem;
}

.import-affiiates__csv-link {
    color: #FB2757;
    text-decoration: underline;
}

.import-affiliates__step {
    color: #637381;
}

.import-affiliates__step--bold {
    font-weight: bold;
}

.import__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.import__info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.import__info_photo {
    padding: 10px;
}

.import__spinner-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 1.6rem;
}

.import__info .Polaris-TextStyle--variationStrong {
    font-size: 1.6rem;
    line-height: 2.4rem;
}

.import__info {
    flex: 3;
}

/*.import__invite {*/
/*    flex: 1;*/
/*}*/

.import__invite_button {
    height: 60px;
    /* flex: 1; */
    align-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.import__header {
    background: #F9FAFB;
    border-bottom: solid 1px #C4CDD5;
    padding: 1.2rem 2.2rem;
    font-weight: bold;
}

.import__pagination {
    padding: 1.6rem;
    text-align: right;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.import__empty-set {
    text-align: center;
    padding: 2rem 0;
}

.import_button-primary,
.import_button-secondary {
    width: 50%;
    height: 3rem;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.example-image {
    height: 150px;
}

.campaign-creatives__exampleImage {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.imported-affiliates-list{
    margin-top: 2rem;
}

.import--status{
    margin-bottom: 2rem;
    margin-top: -1rem;
}


@media screen and (max-width: 599px){
    .import-affiliates__navigation {
        flex-wrap: wrap;
    }
    
    .import-affiliate__migration-type {
        width: 140px;
        height: 140px;
        margin: 0.6rem;
        font-size: 2rem;
    }

    .import-affiliate__refersion-logo {
        width: 75%;
    }

    .import-affiliate__affiliatly-logo {
        width: 75%;
    }

    .import-affiliate__leaddyno-logo {
        height: 46px;
    }
}
