.campaign-creatives__heading {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.campaign-creatives__delete {
    width: 1rem;
    height: auto;
    opacity: 0.4;
    cursor: pointer;
}

.campaign-creatives__delete:hover {
    opacity: 0.6;
}

.campaign-creatives__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.campagin-creatives__subtitle {
    padding-left: none;
}












