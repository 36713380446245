.NavigationSidebar__Main {
    height: 100%;
    min-height: 100%;
    width: 200px;
    background: #34135D;
    color: #D6D6D6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    z-index: 500;
    overflow: auto;
}

.NavigationSidebar__FixedSpacer {
    z-index: 0;
    position: relative;
}

.NavigationSidebar__Nav-Flex {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}

.NavigationSidebar__Section-Header {
    border-bottom: 2px solid rgba(255,255,255,0.2);
    width: 100%;
}

.NavigationSidebar__Logo-Wrapper {
    height: 6rem;
    padding: 2rem;
}

.NavigationSidebar__Logo {
    width: 100%;
    height: auto;
}

.NavigationSidebar__Ham-Wrapper {
    display: none;
}

.NavigationSidebar__User-Mobile {
    display: none;
}

.NavigationSidebar__h1 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    padding-top: 6rem;
    padding-left: 2rem;
    padding-bottom: 2rem;
}

.NavigationSidebar__Links-List {
    list-style: none;
    margin: 0;
    padding: 0;
}

.NavigationSidebar__Link {
    text-decoration: none !important;
    color: #D6D6D6;
    font-size: 16px;
    display: block;
    padding: 10px 20px;
    position: relative;
    cursor: pointer;
}

.NavigationSidebar__Link .alert{
    background: var(--llama-pink);
    color: #fff;
    padding: 0.2rem .9rem;
    margin-left: .8rem;
    border-radius: .5rem;
    font-size: .8em;
    vertical-align: middle;
}

.NavigationSidebar__active {
    background: rgba(255,255,255,0.2);
    color: #ffffff;
}

.NavigationSidebar__trial-badge {
    padding-left: 1rem; 
    display: inline-block; 
    transform: scale(0.8);
    position: relative;
    top: -1px;  
}

.NavigationSidebar__Gear-Flex {
    display: flex;
    align-items: center;
}

.NavigationSidebar__Link-Icon {
    height: 16px;
    margin-right: 12px;
}

.NavigationSidebar__Logo-Mobile {
    display: none;
}

.NavigationSidebar__Notification-Logo {
    display: none;
}

.NavigationSidebar__Nav-Bottom {
    margin-bottom: 2rem;
}

.NavigationSidebar__Popout {
    position: fixed;
    background: #ffffff;
    bottom: 1%;
    left: 210px;
    box-shadow: 0rem 0rem 1.5rem rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 18rem;
    align-items: flex-start;
}

.NavigationSidebar__PopoutItem {
    color: #222222;
    padding: 1rem 2rem;
    width: 100%;
    transition: all 100ms ease-out;
    cursor: pointer;
    left: 0;
}

.NavigationSidebar__PopoutItem:hover {
    color: #FB2757;
    position: relative;
    left: 0.3rem;
}

.NavigationSidebar__PopoutArrow {
    position: absolute;
    bottom: 1rem;
    left: -1rem;

    width: 0;
    height: 0;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;

    border-right: 1rem solid #FFFFFF;
}

.NavigationSidebar__PopoutItemLink {
    text-decoration: none;
}

.NavigationSidebar__HelpDesktop {
    display: block;
}

.NavigationSidebar__HelpMobile {
    display: none;
}

@media screen and (max-width: 1366px) {
    .NavigationSidebar__Link {
        font-size: 14px;
        padding: 0.6rem 2rem;
    }

    .NavigationSidebar__h1 {
        padding-top: 3rem;
    }
}

@media screen and (max-width: 599px) {

    .NavigationSidebar__Popout {
        display: none;
    }

    .NavigationSidebar__Logo-Wrapper {
        padding: 1rem;
    }

    .NavigationSidebar__Main {
        background: #34135D;
        color: #D6D6D6;
        height: 60px;
        width: 100% !important;
        transition: all 300ms ease-out;
        position: relative;
        overflow: hidden;
    }

    .NavigationSidebar__h1 {
        padding-top: 2rem;
    }

    .NavigationSidebar__FixedSpacer {
        display: none;
    }

    .NavigationSidebar__Main--open {
        height: 100vh;
        transition: all 300ms ease-out;
    }

    .NavigationSidebar__Mobile-Menu {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .NavigationSidebar__Logo-Wrapper {
        height: 60px;
    }

    .NavigationSidebar__Logo {
        display: none;
    }

    .NavigationSidebar__Logo-Mobile {
        width: 96px;
        display: block;
        align-self: flex-end;
    }

    .NavigationSidebar__User-Mobile {
        width: 100%;
        display: block;
    }

    .NavigationSidebar__Logo-Wrapper-Mobile {
        display: flex;
        justify-content: center;
        flex: 1;
        padding: 8px 0;
    }

    .NavigationSidebar__Ham-Wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    
    .NavigationSidebar__Ham-Ingredient {
        height: 2px;
        background-color: #ffa72c;
        width: 22px;
        display: block;
        margin-top: 4px;
        z-index: 100;
        transition: all 300ms ease-out;
    }

    .NavigationSidebar__Ham-Ingredient--open {
        width: 0px;
        transition: all 100ms ease-out;
    }

    .NavigationSidebar__Ham-Ingredient--close01 {
        transform: rotate(45deg);
        transition: all 300ms ease-out;
        position: absolute;
        top: 6;
        margin-top: 8px;
        transition-delay: 400ms ease-out;
    }

    .NavigationSidebar__Ham-Ingredient--close02 {
        transform: rotate(-45deg);
        transition: all 300ms ease-out;
        position: absolute;
        top: 22px;
        transition-delay: 400ms ease-out;
    }

    .NavigationSidebar__Popover-Wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
    }

    .NavigationSidebar__Popover-Activator {
        width: 40px;
        padding: 8px;
    }

    .NavigationSidebar__Hamburger {
        padding: 8px;
        box-sizing: content-box;
        width: 22px;
    }

    .NavigationSidebar__Notification-Logo {
        display: block;
        width: 20px;
        cursor: pointer;
        margin-right: .75rem;
    }

    .NavigationSidebar__HelpDesktop {
        display: none;
    }
    
    .NavigationSidebar__HelpMobile {
        display: block;
    }

    .NavigationSidebar__Link--SubMenu {
        padding-left: 4.75rem;
        opacity: 0.7;
    }

}