.campaign-summary {
    color: #50555b;
    padding: 0 2rem;
}

.offer-form-layout-wrapper .campaign-summary{
    padding-right: 0;
}

@media screen and (max-width: 1003px) {
    .offer-form-layout-wrapper .campaign-summary{
        padding-left: 0;
    }
}

.campaign-summary__stat {
    margin: 1.6rem 0;
}

.campaign-summary__banner img{
    width: 100%;
    height: 110px;
    object-fit: cover;
}

.campaign-summary__heading {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    /* margin-bottom: 2rem; */
}

.campaign-summary__keywords {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    margin-top: 1.6rem;
}

.campaign-summary__url {
    word-wrap: break-word;
    margin: 1.6rem 0 0;
}
.campaign-summary a{
    color: #fa2f58;
}
.campaign-summary a:hover{
    color: #e2244a;
}

.campaign-summary__description {
    margin-bottom: 1.6rem;
    margin-top: .1rem;
    line-height: 1.5em;
    max-height: 7.5em;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
}

.campaign-summary__category {
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 2rem;
}

.campaign-summary__section--name {
    width: 100%;
    padding: 0 0 2rem 0;
}

.campaign-summary__section--tags,
.campaign-summary__section--commission,
.campaign-summary__section--products,
.campaign-summary__section--audience {
    width: 100%;
    padding: 2rem 0;
    border-top: 1px solid #EFEFEF;
}

.campaign-summary__list-wrapper,
.campaign-summary__section--products p {
    margin-top: 1rem;
}