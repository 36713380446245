.header {
    width: 100%;
    height: 8rem;
    background: #ffffff;
    box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.2);
    padding: 1rem 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header__title {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
}

.header__notifications {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.header__notification {
    padding: 0.8rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.header__notification-icon {
    height: 2.4rem;
    width: 2.4rem;
}

.header__notification-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.header__notifications-indicator {
    background: #FB2956; 
    box-shadow: 0rem .25rem .25rem rgb(43, 42, 42, 0.3);
    font-size: 1rem;
    line-height: 1rem;
    color: #ffffff;
    padding: 0.4rem 0.55rem;
    font-weight: bold;
    border-radius: 999px;
    min-width: 18px;
    text-align: center;

    position: absolute;
    top: 0;
    left: 2rem;
}

.header__username {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-right: 2rem;
}