.FeaturedAmbassadors__Grid {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(27rem,1fr));
    grid-row-gap: 5rem;
    row-gap: 5rem;
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
    column-gap: 5rem;
    padding-left: 0;
    margin-bottom: 5rem;
}
.FeaturedAmbassadors__Wrapper h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    
    color: #000000;
}
.FeaturedAmbassadors__Description {
    font-size: 14px;
    line-height: 17px;

    color: #637381;
    margin-bottom: 30px;
}

/* Loading Styles */
.FeaturedAmbassador__LoadingCard {
    display: flex;
    justify-content: space-between;
}
.FeaturedAmbassador__LoadingImg {
    border-radius: 50%;
    width: 75px;
    min-width: 75px;
    height: 75px;
    margin-right: 15px;
    background: rgba(246, 246, 247, 1);
}
.FeaturedAmbassador__LoadingContent h1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1rem;
}
.FeaturedAmbassador__LoadingCategory {
    margin-bottom: 0.6rem;
}
.FeaturedAmbassador__LoadingContent {
    width: calc(100% - 5rem);
}
.FeaturedAmbassador__LoadingStats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.FeaturedAmbassador__LoadingStat {
    height: 41px;
}
.FeaturedAmbassador__LoadingStat .label {
    font-size: 14px;
    line-height: 17px;

    color: #637381;

    margin-bottom: 0.6rem;
}
.FeaturedAmbassador__LoadingStat .value {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    text-align: left;

    color: #000000;

    height: 24px;
}