.invoices__spinner-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 1.6rem;
}

.invoices__no-data {
    padding: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.invoices__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.invoices__info {
    display: flex;
    flex-direction: column;
}

.invoices__info .Polaris-TextStyle--variationStrong {
    font-size: 1.6rem;
    line-height: 2.4rem;
}

.invoices__status {
    display: flex;
    justify-content: flex-end;
}

.invoices__info {
    flex: 3;
}

.invoices__date,
.invoices__due-date,
.invoices__amount {
    text-align: right;
}

.invoices__date,
.invoices__due-date,
.invoices__amount, 
.invoices__status {
    flex: 1;
}

.invoices__header {
    background: #F9FAFB;
    border-bottom: solid 1px #C4CDD5;
    padding: 1.2rem 2.2rem;
    font-weight: bold;
}

.invoices__pagination {
    padding: 1.6rem;
    text-align: right;
    
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.invoices__empty-set {
    text-align: center;
    padding: 2rem 0;
}

/* Override the default css to remove the handpointer of cursor as there is nothing to click */
.invoices__wrapper .Polaris-ResourceList-Item {
    position: relative;
    outline: none;
    cursor: auto;
}

@media only screen and (max-width: 600px) {

    .invoices__wrapper {
        font-size: 1.2rem;
        max-width: 100%;
        width: 100%;
        overflow-x: scroll;
        font-size: 1.2rem;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .invoices__item, .invoices__header{
        width: auto;
    }
    
    .invoices__info {
        min-width: 10rem;
    }

    .invoices__date {
        min-width: 8rem;
    }

    .invoices__due-date {
        min-width: 8rem;
    }

    .invoices__amount {
        min-width: 8rem;
    }

    .invoices__status {
        min-width: 10rem;
    }

}