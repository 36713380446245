.offer-form-buttons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2rem;
}

.offer-status-card__conatiner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.offer-status__warning-banner{
    margin-top: 2rem;
}

@media screen and (min-width: 600px) and (min-height: 818px){
    .offer-form-layout-wrapper .Polaris-Layout__Section--secondary{
        position: sticky;
        top: 2rem;
    }
}

@media screen and (max-width: 599px){
    .offer-form-layout-wrapper .Polaris-Layout__Section{
        max-width: 100%;
    }

    .Polaris-Layout__Section{margin-left: 0 !important;}
    .offer-form .Polaris-Card__Section{
        padding: 2rem 0;
    }

    .offer-form{
        border-bottom: 1px solid #dfe3e8;
    }

    .offer-form-buttons{
        display: block;
    }

    .offer-form-buttons .Polaris-ButtonGroup{
        justify-content: center;
    }

    .offer-form-buttons .Polaris-ButtonGroup__Item{
        width: calc(50% - .8rem);
    }

    .offer-form-buttons .llama-button{
        width: 100%;
        padding: .6rem;
    }

    .offer-form-save{
        margin-top: 2rem;
    }

    .offer-form-save button{
        width: 100%;
        padding: .6rem;
    }
}