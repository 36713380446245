.social-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.2rem;
}

.social-icons__icon {
    height: 1.8rem;
    width: auto;
    position: relative;
    top: -1px;
    margin-right: 1rem;
}

.social-icons__icon:last-child {
    margin-right: 0;
}

.social-icons__empty-state {
    font-weight: bold;
    font-size: 1.4rem;
}