#new-user-progress {
    margin-bottom: 4rem;
}

#new-user-progress h2{
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.8rem;
}

#new-user-progress h2 + p{
    margin-bottom: 3rem;
}

#new-user-progress progress{
    appearance: none;
    width: 100%;
    vertical-align: top;
    height: 21px;
}

#new-user-progress progress::-webkit-progress-bar{
    background: #F3F3F3;
    border: 1px solid #E0E0E0;
    border-radius: 21px;
}

#new-user-progress progress::-webkit-progress-value{
    background: #480F7A;
    border-radius: 21px 0 0 21px;
}

#new-user-progress .progress{
    position: relative;
    margin-bottom: 3rem;
}

#new-user-progress .progress::after{
    content: url(../../assets/llama-progress-indicator.svg);
    width: 39px;
    height: 55px;
    position: absolute;
    top: calc(50% - 31px);
    left: 0;
    filter: drop-shadow(-2px 0 7px rgba(0, 0, 0, .15));
}
#new-user-progress .progress[data-progress="1"]::after{
    left: calc(20% - 20px);
}
#new-user-progress .progress[data-progress="2"]::after{
    left: calc(40% - 20px);
}
#new-user-progress .progress[data-progress="3"]::after{
    left: calc(60% - 20px);
}
#new-user-progress .progress[data-progress="4"]::after{
    left: calc(80% - 20px);
}

#new-user-progress .progress .notch{
    position: absolute;
    width: 7px;
    height: 100%;
    top: 0;
    left: 20%;
    border: 1px solid #E0E0E0;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    background: #fff;
}

#new-user-progress .progress .notch:nth-child(2){
    left: 40%
}
#new-user-progress .progress .notch:nth-child(3){
    left: 60%
}
#new-user-progress .progress .notch:nth-child(4){
    left: 80%
}

#new-user-progress ul{
    padding-left: 0;
    list-style: none;
}

#new-user-progress li{
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    margin-bottom: 1.5rem;
    padding: 1.5rem 1.5rem 1.5rem 11.5rem;
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1rem;
    font-size: 1.4rem;
}

#new-user-progress li[data-complete="true"]{
    padding-left: 4.4rem;
}

#new-user-progress li[data-complete="true"]::before{
    content: '';
    position: absolute;
    top: calc(50% - 12px);
    left: 10px;
    width: 24px;
    height: 24px;
    background: #480F7A;
    border-radius: 100%;
}

#new-user-progress li[data-complete="true"]::after{
    content: '';
    position: absolute;
    width: 7px;
    height: 13px;
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
    transform: rotate(45deg);
    left: 18px;
    top: calc(50% - 12px + 4px);
}

#new-user-progress li[data-complete="true"] p.heading{
    opacity: .7;
}

#new-user-progress li.paypal[data-complete="false"]::after{
    content: url('../../assets/progress/paypal.svg');
    position: absolute;
    left: 20px;
    top: calc(50% - 38px / 2);
}
#new-user-progress li.logo[data-complete="false"]::after{
    content: url('../../assets/progress/logo.svg');
    position: absolute;
    left: 28px;
    top: calc(50% - 20px);
}
#new-user-progress li.offer[data-complete="false"]::after{
    content: url('../../assets/progress/offer.svg');
    position: absolute;
    left: 32px;
    top: calc(50% - 25px);
}
#new-user-progress li.page[data-complete="false"]::after{
    content: url('../../assets/progress/page.svg');
    position: absolute;
    left: 34px;
    top: calc(50% - 45px / 2);
}
#new-user-progress li.ambassadors[data-complete="false"]::after{
    content: url('../../assets/progress/ambassadors.svg');
    position: absolute;
    left: 36px;
    top: calc(50% - 20px);
}

#new-user-progress li p.heading{
    font-size: 1.3em;
    font-weight: bold;
}

#new-user-progress li p.description{
    margin-top: 0.5rem;
    grid-column: 1;
}
#new-user-progress li p.description + p{
    justify-self: end;
    align-self: center;
    grid-area: 1 / 2 / 3 / 3;
}

/* smaller icons */
@media screen and (max-width: 830px) and (min-width: 471px){
    #new-user-progress li[data-complete="false"]{
        padding-left: calc(11.5rem * .8);
    }
    #new-user-progress li[data-complete="false"]::after{
        transform: scale(.8);
        transform-origin: 0 0;
        top: 2.2rem !important;
    }
}

/* buttons down, smaller notches */
@media screen and (max-width: 720px) and (min-width: 600px),
       screen and (max-width: 530px){
        #new-user-progress .progress .notch{
            width: 5px;
        }
        #new-user-progress li p.description + p{
            justify-self: start;
            align-self: start;
            grid-area: 3 / 1 / 3 / 3;
            margin-top: 1rem;
        }
}

/* icons centered on top, center headings, button full width */
@media screen and (max-width: 470px){
    #new-user-progress li[data-complete="false"]{
        padding-left: 1.5rem;
        text-align: center;
        display: block;
    }

    #new-user-progress li[data-complete="false"]::after{
        transform: scale(1.2);
        transform-origin: 0 0;
        top: 1.5rem !important;
    }

    #new-user-progress li.paypal[data-complete="false"]{
        padding-top: calc(5rem + 2.5rem);
    }
    #new-user-progress li.logo[data-complete="false"]{
        padding-top: calc(5.4rem + 2.5rem);
    }
    #new-user-progress li.offer[data-complete="false"]{
        padding-top: calc(6.6rem + 2.5rem);
    }
    #new-user-progress li.page[data-complete="false"]{
        padding-top: calc(6rem + 2.5rem);
    }
    #new-user-progress li.ambassadors[data-complete="false"]{
        padding-top: calc(5.4rem + 2.5rem);
    }

    #new-user-progress li.paypal[data-complete="false"]::after{
        left: calc(50% - (8.7rem / 2));
    }
    #new-user-progress li.logo[data-complete="false"]::after{
        left: calc(50% - (6.7rem / 2));
    }
    #new-user-progress li.offer[data-complete="false"]::after{
        left: calc(50% - (6.1rem / 2));
    }
    #new-user-progress li.page[data-complete="false"]::after{
        left: calc(50% - (5.4rem / 2));
    }
    #new-user-progress li.ambassadors[data-complete="false"]::after{
        left: calc(50% - (4.8rem / 2));
    }

    #new-user-progress li p.description + p,
    #new-user-progress button{
        width: 100%;
    }
    #new-user-progress button{
        padding: 7px 16px;
    }

    #new-user-progress li p.description{
        text-align: left;
    }
}