.affiliate-tab__name[data-is-twitter-verified="true"],
.affiliate-tab__twitter-posts .name b[data-is-verified="true"]{
    display: inline-flex;
    align-items: center
}
.affiliate-tab__name[data-is-twitter-verified="true"]::after,
.affiliate-tab__twitter-posts .name b[data-is-verified="true"]::after{
    content: '';
    width: 15px;
    height: 15px;
    margin-left: 6px;
    background: url(../../assets/twitter-verified-icon.svg);
}

.affiliate-twitter .Polaris-Banner{
    margin: 0 3rem;
    transform: translateY(-2rem);
}

.affiliate-tab__twitter-posts{
    list-style: none;
    padding: 0;
    margin-top: 1rem;
}

.affiliate-tab__twitter-posts .tweet{
    padding: 3rem;
    border-bottom: 1px solid #e0e0e0;
    background: url(../../assets/twitter-bird-icon.svg) no-repeat calc(100% - 3rem) 3rem;
    background-size: auto 24px;
}

.affiliate-tab__twitter-posts .tweet:first-child{
    padding-top: 0;
    background-position: calc(100% - 3rem) 0;
}

.affiliate-tab__twitter-posts img{
    border-radius: 100%;
    border: 1px solid #E0E0E0;
    margin-right: 1rem;
}

.affiliate-tab__twitter-posts a{
    color: #212B36;
}

.affiliate-tab__twitter-posts .user{
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.affiliate-tab__twitter-posts .name a{
    display: flex;
    flex-direction: column;
    line-height: 1.2em;
}

.affiliate-tab__twitter-posts .name span{
    font-size: 1.2rem;
    color: #637381;
}

.affiliate-tab__twitter-posts .label{
    margin-bottom: 0.2rem;
}

.affiliate-tab__twitter-posts .label.retweet{
    padding-left: 2.2rem;
    background: url(../../assets/twitter-retweet-icon.svg) no-repeat left center;
    background-size: 17px auto;
}

.affiliate-tab__twitter-posts .label a,
.affiliate-tab__twitter-posts .content a{
    font-weight: bold;
    color: #fd2a54;
}

.affiliate-tab__twitter-posts .content{
    font-size: 1.9rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    line-height: 1.4em;
}

.affiliate-tab__twitter-posts .content + .photo{
    max-width: 410px;
    max-height: 290px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
}

.affiliate-tab__twitter-posts .content + .photo img{
    max-width: 100%;
    border: 1px solid #E0E0E0;
    border-radius: 0;
}

.affiliate-tab__twitter-posts video{
    max-width: 410px;
    margin-bottom: 1.5rem;
    width: 100%;
}

.affiliate-tab__twitter-posts .photo-album{
    margin: 10px 0;
    padding-left: 0;
    overflow: auto;
    height: 80px;
}

.affiliate-tab__twitter-posts .photo-album li{
    width: 80px;
    max-height: 80px;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    vertical-align: middle;
}

.affiliate-tab__twitter-posts .metrics{
    display: flex;
    justify-content: space-between;
}

.affiliate-tab__twitter-posts .metrics ul{
    list-style: none;
    padding-left: 0;
    margin: 0 0 1.5rem;
    display: flex;
}

.affiliate-tab__twitter-posts .metrics li{
    margin-right: 2.4rem;
}

.affiliate-tab__twitter-posts .actions a{
    margin-right: 3.2rem;
    text-indent: -99999px;
    display: inline-block;
}

.affiliate-tab__twitter-posts .actions .reply{
    background: url(../../assets/twitter-comment-icon.svg) no-repeat;
    width: 13px;
    height: 13px;
}
.affiliate-tab__twitter-posts .actions .retweet{
    background: url(../../assets/twitter-retweet-icon.svg) no-repeat;
    width: 17px;
    height: 13px;
}
.affiliate-tab__twitter-posts .actions .like{
    background: url(../../assets/twitter-like-icon.svg) no-repeat;
    width: 13px;
    height: 13px;
}

@media only screen and (max-width: 1270px) and (min-width: 1065px),
            screen and (max-width: 868px) {
    .affiliate-tab__stats-twitter{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    .affiliate-tab__stats-twitter .affiliate-tab__stat-item{
        margin-bottom: 0;
        padding-left: 0;
    }
}

@media only screen and (max-width: 1064px) {
    .affiliate-tab__twitter-posts .tweet{
        padding: 3rem 0;
        background-position: right 3rem;
    }
    .affiliate-tab__twitter-posts .tweet:first-child{
        background-position: right 0;
    }
    .affiliate-twitter .Polaris-Banner{
        margin: 0 0 2rem;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 450px) {
    .affiliate-tab__stats-twitter{
        grid-template-columns: repeat(2, 1fr);
    }
}