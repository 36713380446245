.settings-plan__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-top: 2rem;
}

.settings-plan .Polaris-Card,
.settings-plan .Polaris-Card + .Polaris-Card {
    margin: 0 1rem;
    width: 100%;
    flex: 1;
}

.settings-plan__plan {
    border: 5px solid #EBE8ED;
    border-radius: 50px;
    background-color: #ffffff;
    display: block;
    width: 320px;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(14,13,16,0.3);
    -moz-box-shadow: 0px 10px 20px 0px rgba(14,13,16,0.3);
    box-shadow: 0px 10px 20px 0px rgba(14,13,16,0.3);
    margin: 1rem;
    margin-top: 3rem;
    padding-bottom: 3rem;
}

.settings-plan__starter--active {
    border: 5px solid #FF2754;
    transition: all 300ms ease-out;
}

.settings-plan__pro--active {
    border: 5px solid #7445BD;
    transition: all 300ms ease-out;
}

/* .settings-plan__plan03--active {
    border: 5px solid #FFA72C;
    transition: all 300ms ease-out;
} */

.settings-plan__plan-top {
    height: 44px;
    border-radius: 50px 50px 0px 0px;
    margin-bottom: 1rem;
    display: block;
}

.settings-plan__starter-top {
    background-color: #FF2754;
}

.settings-plan__pro-top {
    background-color: #7445BD;
}

.settings-plan__plan-03-top {
    background-color: #FFA72C;
    height: 44px;
    border-radius: 50px 50px 0px 0px;
    margin-bottom: 1em;
    display: block;
}

.settings-plan__plan-price {
    font-family: 'Proxima Nova Light', 'Helvetica', sans-serif;
    font-size: 3rem;
    line-height: 3.2rem;
    text-align: center;
    padding: 0;
    margin: 0;
}

.settings-plan__starter-price {
    color: #FF2754;
}

.settings-plan__pro-price {
    color: #7445BD;
}

/* .settings-plan__plan-price-03 {
    font-family: 'Proxima Nova Light', 'Helvetica', sans-serif;
    font-size: 2em;
    line-height: 1.2em;
    color: #FFA72C;
    text-align: center;
    padding: 0;
    margin: 0;
} */

.settings-plan__plan-name {
    font-family: 'Proxima Nova Bold', 'Helvetica', sans-serif;
    font-size: 1.375rem;
    letter-spacing: .0625rem;
    text-align: center;
    color: #522499;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
}

.settings-plan__plan-caption {
    font-family: 'Proxima Nova Light', 'Helvetica', sans-serif;
    color: #522499;
    font-size: 1.5rem;
    letter-spacing: .0625rem;
    text-align: center;
    padding: 0;
    margin: 0;
}

.settings-plan__feature-list {
    list-style-type: none;
    font-family: 'Proxima Nova Light', 'Helvetica', sans-serif;
    color: #522499;
    font-size: 1.4rem;
    letter-spacing: .0625rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    padding-top: .5rem;
}

.settings-plan__feature {
    border-top: 2px solid #EBE8ED;
    padding-left: 1rem;
    padding-bottom: 1.3rem;
    padding-top: 1.3rem;
}

.settings-plan__feature:last-child {
    border-bottom: 2px solid #EBE8ED;
    padding-bottom: 10px;
}

.settings-plan__plan-controller-row {
    display: flex;
    justify-content: center;
    width: 160px;
    margin: 0 auto;
}

.settings-plan__type-selected {
    font-family: 'Proxima Nova Light', 'Helvetica', sans-serif;
    color: #522499;
    font-size: .5rem;
    letter-spacing: .0625rem;
    transition: all 300ms ease-out;
}

.settings-plan__type-selected--active {
    font-family: 'Proxima Nova Bold', 'Helvetica', sans-serif;
    color: #522499;
    font-size: .5rem;
    letter-spacing: .0625rem;
    transition: all 300ms ease-out;
}

.settings-plan__plan-disclaimer {
    font-family: 'Proxima Nova Light', 'Helvetica', sans-serif;
    color: #522499;
    font-size: .625rem;
    padding-left: 1rem;
    letter-spacing: .0625rem;
    transition: 300ms ease-out;
}

.settings-plan__plan-disclaimer--hidden {
    opacity: 0;
    transition: 300ms ease-out;
    font-family: 'Proxima Nova Light', 'Helvetica', sans-serif;
    font-size: .625rem;
    padding-left: 1rem;
    letter-spacing: .0625rem;
}

.settings-plan__plan-controller {
    display: block;
    margin: 0 auto;
    border-radius: 50px;
    background-color: #F2F2F2;
    width: 60px;
    height: 30px;
    cursor: pointer;
}

.settings-plan__plan-controller--active {
    background-color: #6AB645;
    display: block;
    margin: 0 auto;
    border-radius: 50px;
    width: 60px;
    height: 30px;
    cursor: pointer;
    transition: all 300ms ease-out;
}

.settings-plan__plan-button {
    display: block;
    background-color: #ffffff;
    border: 2px solid #DBDBDB;
    width: 30px;
    height: 30px;
    z-index: 10;
    border-radius: 50px;
    cursor: pointer;
    transition: all 300ms ease-out;
}

.settings-plan__plan-button--active {
    display: block;
    background-color: #ffffff;
    border: 2px solid #DBDBDB;
    width: 30px;
    height: 30px;
    z-index: 10;
    border-radius: 50px;
    cursor: pointer;
    transform: translateX(100%);
}

.settings-plan__plan .Polaris-Button,
.settings-plan__cta {
    cursor: pointer;
    text-align: center;
    margin-top: 3rem;
    font-family: 'Proxima Nova Bold', 'Helvetica', sans-serif;
    text-transform: uppercase;
    display: block;
    width: 80%;
    margin: 0 auto;
    padding: 18px 24px;
    border-radius: 50px;
    font-size: 1.75rem;
    letter-spacing: .0625rem;
    background: #FF2754;
    color: #ffffff;
    border: none;
    box-shadow: none;
    transition: all 300ms ease-out;
}

.settings-plan__plan .Polaris-Button__Content {
    text-transform: uppercase;
    font-size: 1.75rem;
    letter-spacing: .0625rem;
}

.settings-plan__cta--selected {
    cursor: pointer;
    text-align: center;
    margin-top: 3rem;
    font-family: 'Proxima Nova Bold', 'Helvetica', sans-serif;
    text-transform: uppercase;
    display: block;
    width: 80%;
    margin: 0 auto;
    padding: 18px 24px;
    border-radius: 50px;
    font-size: 1.75rem;
    letter-spacing: .0625rem;
    background-color: #DDDDDD;
    color: #ffffff;
    border: none;
    box-shadow: none;
    transition: all 300ms ease-out;
}

.settings-plan__cta:hover {
    opacity: 0.8;
}

.settings-plan__price {
    margin: 2rem 0 1rem;
    font-weight: bold;
    line-height: 2rem;
}

.settings-plan__price span {
    font-size: 2rem;
}

.settings-plan__highlight {
    background: #F3AA4A;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.5rem;
    text-align: center;
    font-weight: bold;
    color: #FFFFFF;
}

.settings-plan__trial-info {
    padding: 2rem 2rem 0 2rem;
}

.settings-plan__trial-info:last-child {
    padding: 2rem;
}

.settings-plan__trial-date {
    color: #FD2656;
    font-weight: bold;
}

.settings-plan__billing-date {
    font-weight: bold;
}

.settings-plan__account-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.settings-plan__account-plan {
    padding: 1rem 2rem;
    margin: 2rem;
}

.settings-plan__account-plan--left {
    border-right: 1px solid #F0F0F0;
}

.settings-plan__account-label {
    color: #494949;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.6rem;
    font-size: 1.2rem;
    display: block;
}

.settings-plan__account-value {
    color: #717171;
    cursor: pointer;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.settings-plan__account-name {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

.settings-plan__upgrade-link {
    color: #FD2656;
    cursor: pointer;
}




.settings-plan__breakdown-wrapper {
    padding: 3.5rem 0;
 }
 
 .settings-plan__section-label {
     width: 100%;
     background-color: #f3f4f7;
     margin-top: 3rem;
 }
 
 .settings-plan__plan-breakdown {
     margin-top: 2rem;
 }
 
 .settings-plan__plan-attribute {
     margin-bottom: 1rem;
 }
 
 .settings-plan__attribute .Polaris-Badge {
     margin-left: 1rem;
 }
 
 .settings-plan__attribute-name {
     font-weight: bold;
 }
 
 .settings-plan__table-wrapper {
     margin-top: 2rem;
 }

.statements__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 100%;
}

.statements__list-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 700px;
    margin: 1rem auto;
}

.statements__header {
    background: #F9FAFB;
    border-bottom: solid 1px #C4CDD5;
    padding: 1.2rem 2.2rem;
    font-weight: bold;
    max-width: 100%;
}

.statements__date,
.statements__due-date,
.statements__amount
 {
    text-align: right;
}

.statements__date,
.statements__due-date,
.statements__amount,
.statements__status
 {
    flex: 1;
}

.statements__status {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 599px) {
    .settings-plan__wrapper {
        flex-wrap: wrap;
        justify-content: center;
    }
}