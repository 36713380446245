.NotificationsSideDrawer ul{
    list-style: none;
    padding-left: 0;
    margin: 0
}

.NotificationsSideDrawer li:first-child{
    border-top: 1px solid rgba(0, 0, 0, .05);
}
.NotificationsSideDrawer li{
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    padding: 1.2rem 1.8rem;
    display: flex;
}
.NotificationsSideDrawer li[data-clickable="true"]{
    cursor: pointer;
}
.NotificationsSideDrawer li[data-clickable="true"]:hover{
    background: #EBECF0;
}
.NotificationsSideDrawer li[data-unread="true"]{
    background: #f2f3f7;
    border-left: 4px solid #b3b8ca;
    padding-left: 1.4rem;
}

.NotificationsSideDrawer .notification--image{
    margin-right: 1rem;
    padding-right: 0.9rem;
    position: relative;
}
.NotificationsSideDrawer .notification--image::after{
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    right: 0;
    top: 24px;
    border-radius: 100%;
}
.NotificationsSideDrawer .notification--image[data-icon="plus"]::after{
    background: #00ab30 url(../../../assets/notifications/plus.svg) no-repeat center center;
    background-size: 10px 10px;
}
.NotificationsSideDrawer .notification--image[data-icon="checkmark"]::after{
    background: #00ab30 url(../../../assets/notifications/checkmark.svg) no-repeat calc(50% + 1px) center;
    background-size: 10px 10px;
}
.NotificationsSideDrawer .notification--image[data-icon="money"]::after{
    background: #00ab30 url(../../../assets/notifications/money.svg) no-repeat center center;
    background-size: 10px 10px;
}
.NotificationsSideDrawer .notification--image[data-icon="alert"]::after{
    background: #e59422 url(../../../assets/notifications/warning.svg) no-repeat calc(50% + 1px) calc(50% - 1px);
    background-size: 10px 10px;
}
.NotificationsSideDrawer .notification--image[data-icon="denied"]::after{
    background: #f50000 url(../../../assets/notifications/denied.svg) no-repeat center center;
    background-size: 10px 10px;
}

.NotificationsSideDrawer li img{
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    background: #6e4d91;
    border: 1px solid #c5c5c3;
}

.NotificationsSideDrawer time{
    color: rgba(0, 0, 0, .6);
    display: block;
    font-size: 1.2rem;
}