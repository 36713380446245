.llama-dmca__wrapper {
    margin: 2rem;
    max-width: 1080px;
    margin: 0 auto;
}

.llama-dmca__content {
    padding: 4rem;
}

.llama-dmca__header {
    font-weight: bold;
    font-size: 1.5rem;
    margin: 1.5rem 0;
}

.llama-dmca__body {
    font-size: 1.5rem;
    margin: 0 0 1rem 0;
    /* line-height: 1.2rem; */
}

.llama-dmca__body-address {
    font-size: 1.5rem;
}

.llama-dmca__address-wrapper {
    margin: 1rem 0;
}