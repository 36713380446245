.offer-view-ad-boost h2{
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 0.7rem;
}

.offer-view-ad-boost .info{
    color: #637381;
    margin-bottom: 3rem;
}

/* first time preview */
.boost-preview-graphic{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2.2rem;
    row-gap: 2.8rem;
    list-style: none;
    padding-left: 0;
    margin-top: 4.5rem;
    margin-left: 1rem;
    margin-bottom: 3rem;
}

.boost-preview-graphic .your-offer{
    position: relative;
}

.boost-preview-graphic .your-offer *{
    position: relative;
    z-index: 2;
}

.boost-preview-graphic .your-offer::before{
    content: '';
    position: absolute;
    z-index: 0;
    width: calc(100% + 3rem);
    height: calc(100% + 3rem);
    top: -1.5rem;
    left: -1.5rem;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .25);
    border-radius: 10px;
}

.boost-preview-graphic .skeleton{
    opacity: .4;
}

.boost-preview-graphic li{
    display: flex;
}

.boost-preview-graphic img{
    width: 7rem;
    height: 7rem;
    border: 1px solid #E0E0E0;
    border-radius: 100%;
    margin-right: 1.3rem;
}

.boost-preview-graphic .offer-info{
    font-size: .8rem;
    line-height: normal;
}

.boost-preview-graphic .offer-info .sponsored {
    color: #34135D;
    letter-spacing: .09em;
    padding: 0.2rem 0.4rem;
    border-radius: .3rem;
    display: inline-block;
    border: 1px solid #693E9D;
    font-size: .1rem;
    line-height: normal;
    vertical-align: top;
    margin-left: .2em;
    transform: scale(.85) translateY(-.1rem);
    transform-origin: 0 50%;
}

.boost-preview-graphic .offer-info .category,
.boost-preview-graphic .offer-info .commission-rate {
    color: #637381;
}

.boost-preview-graphic .offer-info .category{
    text-transform: uppercase;
    font-size: .8rem;
    margin-bottom: 0.4em;
}

.boost-preview-graphic .offer-info .name{
    margin-bottom: .5em;
    line-height: 1.3em;
    font-weight: bold;
    color: #212B36;
    font-size: 1.7em;
}

.boost-preview-graphic .offer-info .commission-rate{
    font-size: 1.5em;
    margin-bottom: 0.7em;
    line-height: 1.3em;
}

.boost-preview-graphic .offer-info .details{
    color: #fd2a54;
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 0;
}

.boost-preview-graphic .skeleton p,
.boost-preview-graphic .skeleton .line{
    height: 1rem;
    border-radius: 4rem;
}

.boost-preview-graphic .skeleton .category{
    background: #637381;
    width: 6.8rem;
    height: 0.6rem;
    margin-bottom: 0.7rem;
}

.boost-preview-graphic .skeleton .name{
    margin-bottom: 1.4rem;
    white-space: nowrap
}

.boost-preview-graphic .skeleton .name .line{
    height: 1rem;
    width: 14rem;
    background: #212B36;
    display: inline-block;
}

.boost-preview-graphic .skeleton .commission-rate{
    height: auto;
    margin-bottom: 1.2rem;
}

.boost-preview-graphic .skeleton .commission-rate .line{
    height: .8rem;
    width: 14.5rem;
    background: #637381;
    display: block;
    margin-bottom: 0.4rem;
}

.boost-preview-graphic .skeleton .commission-rate .line:last-child{
    width: 6.8rem;
}

.boost-preview-graphic .skeleton .details{
    background: #fd2a54;
    height: 0.8rem;
    width: 5.7rem;
}

.offer-view-ad-boost button.boost{
    background: #34135D url('../../assets/up-arrow-in-circle.svg') no-repeat 13px center;
    background-size: 15px 15px;
    padding-left: 36px;
    border-color: #34135D;
}

/* title and toggle */
.offer-view-ad-boost .Polaris-Banner{
    margin-bottom: 3rem;
}

.offer-view-ad-boost .Polaris-Banner button{
    all: unset;
    cursor: pointer;
    color: #00848e;
    font-size: 0.9em;
    margin-top: 1rem;
}

.offer-view-ad-boost .heading{
    display: flex;
    align-items: center;
    margin-bottom: 0.9rem;
}

.offer-view-ad-boost .heading h2{
    margin-right: 1.2rem;
    margin-bottom: 0;
}

.offer-view-ad-boost .toggle{
    all: unset;
    text-indent: -99999px;
    width: 4.4rem;
    height: 2.2rem;
    border-radius: 10rem;
    vertical-align: middle;
    position: relative;
}

.offer-view-ad-boost .toggle::after{
    content: '';
    height: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    position: absolute;
    top: 0;
}

@supports not (aspect-ratio: 1/1){
    .offer-view-ad-boost .toggle::after{
        width: 2.2rem;
    }
}

.offer-view-ad-boost .toggle[data-enabled="false"]{
    background: #D6D6D6;
}
.offer-view-ad-boost .toggle[data-enabled="false"]::after{
    background: #5D5D5D;
    left: 0;
    box-shadow: 2px 0 3px rgba(0, 0, 0, .25);
}
.offer-view-ad-boost .toggle[data-enabled="true"]{
    background: #C2B2D6;
}
.offer-view-ad-boost .toggle[data-enabled="true"]::after{
    background: #34135D;
    right: 0;
    box-shadow: -2px 0 3px rgba(0, 0, 0, .25);
}

/* current bid */
.offer-view-ad-boost .bid{
    margin-bottom: 3rem;
}

.offer-view-ad-boost .bid h3{
    font-size: 1.6rem;
}

.offer-view-ad-boost .bid .amount{
    font-size: 1.6rem;
    margin-top: .3rem;
    margin-bottom: 0.8rem;
}

.offer-view-ad-boost .bid .amount button{
    all: unset;
    cursor: pointer;
    color: #fd2a54;
    font-weight: bold;
    margin-left: 0.5rem;
}

.offer-view-ad-boost .bid .history{
    color: #637381;
}

.offer-view-ad-boost .bid .history a{
    color: #fd2a54;
    font-weight: bold;
}

/* bid modal */
.offer-budget-fields{
    display: flex;
}

.offer-budget-fields .payment-method{
    margin-right: 5rem;
    white-space: nowrap
}

.offer-budget-fields .payment-method p{
    margin-bottom: 0;
}

.offer-budget-fields .payment-method p.help-text{
    color: #637381;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.offer-budget-fields .payment-method p.help-text a{
    color: #fd2a54;
}

.offer-budget-fields .Polaris-Labelled__HelpText{
    font-size: 1.2rem;
}

/* metrics */
.offer-view-ad-boost h3{
    font-weight: bold;
    font-size: 1.8rem;
}

.offer-boost-metrics{
    margin: .5rem 0 1.5rem;
    display: flex;
    color: #637381;
    list-style: none;
    padding-left: 0;
    flex-wrap: wrap;
}

.offer-boost-metrics li{
    margin-right: 2rem;
    margin-bottom: .5rem;
}

.offer-boost-metrics li > span{
    font-weight: bold;
}

.offer-boost-metrics div{
    display: inline-block;
    width: 5rem;
}

@media screen and (max-width: 1390px){
    .boost-preview-graphic{
        grid-template-columns: repeat(2, 25rem);
    }
}
@media screen and (max-width: 1130px) and (min-width: 860px),
       screen and (max-width: 800px){
    .boost-preview-graphic{
        grid-template-columns: 95%
    }

    .boost-preview-graphic li:nth-child(n+4){
        display: none;
    }
}

@media screen and (max-width: 600px){
    .boost-preview-graphic{
        margin-bottom: 0;
    }
    .boost-preview-graphic + p{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        box-shadow: 0 -4px 10px rgba(0,0,0,.08);
        text-align: center;
        padding: 2rem;
    }
}