.LlamaDatePicker__DateFilter {
    font-weight: bold;
    color: #222222;
    border-bottom: 2px solid transparent;
    position: relative;

    display: flex;
    flex-direction: row;
}

.LlamaDatePicker__DateFilter:hover {
    color: #222222;
    border-bottom: 2px solid #FB2956;
    cursor: pointer;
}

.LlamaDatePicker__DateFilter--disabled {
    color: #919EAA;
    cursor: unset;
}

.LlamaDatePicker__DateFilter--disabled:hover {
    border-bottom: 2px solid transparent;
    color: #919EAA;
}

.LlamaDatePicker__PopoverLocator {
    position: relative;
    height: 0;
    align-self: flex-end;
}

.LlamaDatePicker__DatePopover {
    position: absolute;
    z-index: 100;
    top: 0.6rem;
    right: 0;
    background: #ffffff;
    box-shadow: 0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
    border: 1px solid #EBE8ED;
}

.LlamaDatePicker__DatePicker {
    padding: 2rem;
}

.LlamaDatePicker__NamedDates {
    border-bottom: 1px solid #EBE8ED;
    margin: 0;
    padding: 1rem 2rem;
}

.LlamaDatePicker__NamedDates li {
    list-style: none;
    padding: 1rem 0;
}

.LlamaDatePicker__Icon {
    display: block;
    height: 2rem;
    width: 2rem;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
}