.PerformanceMain__Header .Polaris-SkeletonDisplayText--sizeMedium{
    margin: 0 auto;
    width: 40%;
    max-width: none;
}
#performance-reports .skeleton .ambassador-name .Polaris-SkeletonDisplayText--sizeMedium{
    height: 2rem;
    width: 12rem;
    margin-bottom: 0.5rem;
}
#performance-reports .skeleton .ambassador-name .Polaris-SkeletonBodyText{
    height: 1.3rem;
    width: 10rem;
}

#performance-reports .skeleton .breakdown .Polaris-SkeletonBodyText{
    width: 10rem;
}
#performance-reports .skeleton .offers .Polaris-SkeletonBodyText__SkeletonBodyTextContainer{
    padding: 3px 0 0;
}
#performance-reports .skeleton .offers .Polaris-SkeletonBodyText{
    width: 14rem;
    height: 1rem;
    margin: auto 0;
}

#performance-reports .skeleton .graph-heading .Polaris-SkeletonDisplayText--sizeMedium{
    margin-bottom: 8px;
    width: 30%;
    max-width: none;
}
#performance-reports .skeleton .graph-heading .Polaris-SkeletonBodyText{
    margin-bottom: 2rem;
    width: 60%;
}

#performance-reports .skeleton .bar-graph{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 2rem;
    align-items: end;
    padding: 0 3rem;
    border-bottom: 1px solid #D6D6D6;
    border-left: 1px solid #D6D6D6;
}

#performance-reports .skeleton .bar-graph .Polaris-SkeletonDisplayText--sizeMedium:nth-child(1){
    height: 60%;
}
#performance-reports .skeleton .bar-graph .Polaris-SkeletonDisplayText--sizeMedium:nth-child(2){
    height: 80%;
}
#performance-reports .skeleton .bar-graph .Polaris-SkeletonDisplayText--sizeMedium:nth-child(3){
    height: 30%;
}
#performance-reports .skeleton .bar-graph .Polaris-SkeletonDisplayText--sizeMedium:nth-child(4){
    height: 10%;
}
#performance-reports .skeleton .bar-graph .Polaris-SkeletonDisplayText--sizeMedium:nth-child(5){
    height: 70%;
}
#performance-reports .skeleton .bar-graph .Polaris-SkeletonDisplayText--sizeMedium:nth-child(6){
    height: 90%;
}