.InviteSidedrawer__ModalText {
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
}

.InviteSidedrawer__ModalText:last-child{
    margin-bottom: 0;
}

.InviteSidedrawer__ModalText a {
    color: #fd2a54
}