.TransactionReports {
    padding: 2rem;
}

.TransactionReports__Table {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.TransactionReports__TableHeader {
    background: rgb(230, 237, 243);
    font-weight: bold;
    border-bottom: 2px solid #828282;
}

.TransactionReports__Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    width: 100%;
}

.TransactionReports__Row:last-child {
    border-bottom: 1px solid #828282;
}

.TransactionReports__Cell {
    padding: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 10%;

    /* border-right: 1px solid #E0E1E2; */
}

.TransactionReports__Cell:last-child {
    border-right: none;
}

.TransactionReports__DataName {
    width: 18%;
    justify-content: flex-start;
}

.TransactionReports__DataNumber,
.TransactionReports__DataDate {
    justify-content: flex-start;
}

.TransactionReports__DataBadge,
.TransactionReports__DataCurrency {
    justify-content: flex-end;
}

.TransactionReports__DataLookForward {
    width: 14%;
    justify-content: flex-end;
}

.TransactionReports__Link {
    cursor: pointer;
}

.TransactionReports__Link:hover {
    color: #FB2956;
}

.TransactionReports__Filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;

    padding: 2rem 0;
}


@media only screen and (max-width: 600px) {

    .TransactionReports__Cell {
        min-width: 12rem;
    }

    .TransactionReports__Row {
        width: auto;
    }
}