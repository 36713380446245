.date-comparison-graph-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.9rem 1.2rem;
    background: #F5F6FA;
    margin-bottom: 2rem;
}

.date-comparison-graph-heading .comparison{
    display: flex;
    gap: .9rem;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-right: 0;
}

.date-comparison-graph-heading .comparison p{
    font-weight: bold;
}

.date-comparison-graph-heading .comparison > div{
    width: calc(50% - 2rem);
}

.date-comparison-graph-heading p:first-child,
.date-comparison-graph-heading .Polaris-Select{
    width: 100%;
}

.date-comparison-graph-heading .LlamaDatePicker__DateFilter{
    margin-top: 1rem;
}

.date-comparison-graph{
    width: 100%;
    height: 22rem;
}

.date-comparison-graph.skeleton{
    position: relative;
    opacity: .7;
}

.date-comparison-graph.skeleton::after{
    content: 'Loading data...';
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content:center;
    position: absolute;
    top: 0;
    left: 0;
    animation: polaris-SkeletonShimmerAnimation 800ms linear infinite alternate;
    font-weight: bold;
    color: #212B36;
}

.date-comparison-graph.skeleton path[stroke],
.date-comparison-graph.skeleton circle[stroke]:not([stroke="transparent"]){
    stroke: #dfe3e8;
    animation: polaris-SkeletonShimmerAnimation 800ms linear infinite alternate;
}

@media screen and (min-width: 540px) and (max-width: 599px),
       screen and (min-width: 750px) and (max-width: 859px),
       screen and (min-width: 1060px){
    .date-comparison-graph-heading p:first-child,
    .date-comparison-graph-heading .comparison > div{
        width: auto;
    }
}

@media screen and (min-width: 830px) and (max-width: 859px),
       screen and (min-width: 1240px){
    .date-comparison-graph-heading .comparison{
        width: auto;
    }
    .date-comparison-graph-heading .LlamaDatePicker__DateFilter{
        margin-top: 0;
    }
}

@media screen and (min-width: 830px) and (max-width: 859px){
    .date-comparison-graph-heading p:first-child{
        display: none;
    }
}