.affiliate-youtube .Polaris-Banner{
    margin: 0 3rem;
    transform: translateY(-2rem);
}

.affiliate-youtube .more-posts img{
    display: none;
}

.affiliate-tab__youtube-posts{
    list-style: none;
    padding: 0;
    margin-top: 1rem;
}

.affiliate-tab__youtube-posts .post{
    padding: 3rem;
    border-bottom: 1px solid #e0e0e0;
}

.affiliate-tab__youtube-posts .post:first-child{
    padding-top: 0;
    background-position: calc(100% - 3rem) 0;
}

.affiliate-tab__youtube-posts .name span{
    font-size: 1.2rem;
    color: #637381;
}

.affiliate-tab__youtube-posts .label{
    margin-bottom: 0.2rem;
    display: flex;
    align-items: center;
}

.affiliate-tab__youtube-posts .label img{
    border-radius: 100%;
    border: 1px solid #E0E0E0;
    margin-right: .5rem;
}

.affiliate-tab__youtube-posts .label a,
.affiliate-tab__youtube-posts .content a,
.affiliate-tab__youtube-posts .more-description{
    font-weight: bold;
    color: #fd2a54;
    cursor: pointer;
}

.affiliate-tab__youtube-posts .content{
    font-size: 1.9rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    line-height: 1.4em;
}

.affiliate-tab__youtube-posts iframe{
    margin-bottom: 1.5rem;
}

.affiliate-tab__youtube-posts .description{
    line-height: 1.3em;
    max-height: calc(1.3em * 8);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    max-width: 700px;
}

.affiliate-tab__youtube-posts .description[data-is-expanded="true"]{
    max-height: none;
    -webkit-line-clamp: revert;
}

.affiliate-tab__youtube-posts .more-description{
    margin-bottom: 1.5rem;
}

.affiliate-tab__youtube-posts .metrics{
    display: flex;
    justify-content: space-between;
}

.affiliate-tab__youtube-posts .metrics ul{
    list-style: none;
    padding-left: 0;
    margin: 0 0 1.5rem;
    display: flex;
}

.affiliate-tab__youtube-posts .metrics li{
    margin-right: 2.4rem;
}

.affiliate-tab__youtube-posts .metrics a{
    color: var(--p-text);
}

@media only screen and (max-width: 1430px) and (min-width: 1065px){
    .affiliate-tab__stats-youtube{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }
    .affiliate-tab__stats-youtube .affiliate-tab__stat-item{
        margin-bottom: 0;
        padding-left: 0;
    }
}

@media only screen and (max-width: 1270px) {
    .affiliate-tab__stats-youtube{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
    .affiliate-tab__stats-youtube .affiliate-tab__stat-item{
        margin-bottom: 0;
        padding-left: 0;
    }
}

@media only screen and (max-width: 1130px) and (min-width: 1065px) {
    .affiliate-tab__youtube-posts iframe{
        width: calc(100vw - 20rem - 2.5rem - 30rem - 3rem - 3rem);
        height: calc((9/16) * (100vw - 20rem - 2.5rem - 30rem - 3rem - 3rem));
    }
}
@media only screen and (max-width: 770px) and (min-width: 600px) {
    .affiliate-tab__youtube-posts iframe{
        width: calc(100vw - 20rem - 2.5rem - 1.5rem - 1.5rem);
        height: calc((9/16) * (100vw - 20rem - 2.5rem - 1.5rem - 1.5rem));
    }
}
@media only screen and (max-width: 570px) {
    .affiliate-tab__youtube-posts iframe{
        width: calc(100vw - 5rem);
        height: calc((9/16) * (100vw - 5rem));
    }
}

@media only screen and (max-width: 1064px) {
    .affiliate-tab__youtube-posts .post{
        padding: 3rem 0;
    }
}

@media only screen and (max-width: 730px) and (min-width: 600px){
    .affiliate-tab__stats-youtube{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 470px) {
    .affiliate-tab__stats-youtube{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 450px) {
    .affiliate-tab__youtube-posts .metrics li{
        margin-right: 1.5rem;
    }
    .affiliate-tab__youtube-posts .metrics{
        flex-wrap: wrap;
    }

    .affiliate-tab__youtube-posts .metrics ul{
        width: 100%;
    }
}