.OfferCreatives {
    padding: 2rem;
    /* display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap; */
}

.OfferCreatives .creatives-list{
    list-style: none;
    padding-left: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
}

.OfferCreatives .creatives-list button{
    margin: 1rem 1rem 1rem auto;
}

.OfferCreatives .tab-content-heading{
    position: relative;
    margin-bottom: 2rem;
}

.OfferCreatives .tab-content-heading button{
    position: absolute;
    right: 0; 
    top: calc(50% - 15px);
    font-size: 1.6rem;
}

/* .OfferCreatives__image_thumb {
    padding: 15px;
} */

.OfferCreatives__image_thumb img {
    /* max-height: 20vh;
    max-width: 20vw; */
    width: 100%;
}

.OfferCreatives_imagePreview {
    max-width: 100%;
    margin: 0 auto;
    display: block;
}

.OfferCreatives__Submit {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.campaign-creatives__heading {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.campaign-creatives__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.campagin-creatives__subtitle {
    padding-left: none;
}

.OfferCreatives__image_container.empty{
    margin: 0 auto;
}

.image-sizes li{
    margin-bottom: 0.5rem;
}

@media screen and (max-width: 1270px){
    .OfferCreatives .creatives-list{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 599px){
    .App__Main .OfferCreatives .Polaris-Card{
        box-shadow: 0rem 1rem 2rem rgba(0, 0, 0, 0.1);
        border-radius: .5rem;
    }
}

@media screen and (max-width: 930px) and (min-width: 861px), screen and (max-width: 660px) and (min-width: 600px), screen and (max-width: 510px){
    .OfferCreatives .creatives-list{
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 930px) and (min-width: 861px), screen and (max-width: 660px) and (min-width: 600px), screen and (max-width: 460px){
    .OfferCreatives .tab-content-heading button{
        position: static;
        margin-top: 1.5rem;
        width: 100%;
        padding: .7rem 1.6rem;
    }
}