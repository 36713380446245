.affiliate-detail .Polaris-Page {
    max-width: 1440px;
}

.affiliate-detail__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
}

.affiliate-sidebar__sidebar {
    width: 30rem;
    background: #EFEFEF;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.affiliate-sidebar__sidebar-section {
    align-self: center;
    padding: 4rem 2rem;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    border-bottom: solid 1px #D8D8D8;
    width: 100%;
}

.affiliate-sidebar__sidebar-section-top {
    align-items: center;
}

.affiliate-sidebar__sidebar-section:last-child {
    border-bottom: none;
}

.affiliate-sidebar__avatar {
    height: 12rem;
    width: 12rem;
    overflow: hidden;
    border-radius: 999px;
    margin-bottom: 4rem;

    display: flex;
    align-items: center;
    background: rgba(53, 25, 95, 0.2);
}

.affiliate-sidebar__avatar-img {
    max-width: 100%;
    max-height: 100%;
    background: #6e4d91
}

.affiliate-sidebar__name {
    font-weight: bold;
    font-size: 2.6rem;
    margin-bottom: 1rem;  
}

.affiliate-sidebar__category {
    color: #6E6E6E;
    line-height: 2rem;
    margin-bottom: 0.6rem;
}

.affiliate-sidebar__social-icon {
    height: 2rem;
}

.affiliate-sidebar__social-link {
    text-decoration: none;
    cursor: pointer;
    margin-right: 1rem;
    display: inline-block;
    margin-top: .6rem;
    margin-bottom: 1.2rem;
}

.affiliate-sidebar__social-link:last-child {
    margin-right: 0;
}

/* .affiliate-sidebar__social-icon:last-child {
    margin-right: 0;
} */

.affiliate-sidebar__seciton-header {
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 2rem;
}

.affiliate-sidebar__sidebar-table {
    width: 100%;
}

.affiliate-sidebar__table-body,
.affiliate-sidebar__table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.affiliate-sidebar__table-body div,
.affiliate-sidebar__table-header div {
    flex: 1;
    text-align: right;
    line-height: 3rem;
}

.affiliate-sidebar__table-body div:first-child,
.affiliate-sidebar__table-header div:first-child {
    text-align: left;
}

.affiliate-sidebar__table-body {
    color: #707070;
}

.affiliate-sidebar__table-header {
    font-weight: bold;
    margin-bottom: -.8rem;
}

.affiliate-dashboard__quicklook-section {
    display: none;
}




.affiliate-main {
    flex: 1;
}

.affiliate-main .account-switcher{
    margin-top: -2rem;
    padding: 1.2rem 3rem;
    background: #EEF9F9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 0 rgba(63, 63, 68, .05), 0 1px 3px rgba(63, 63, 68, .15);
    margin-bottom: 1rem;
    position: sticky;
    top: 0;
    z-index: 300;
}

.affiliate-main .account-switcher .num-pages{
    margin-right: 1.5rem;
}

.affiliate-main .account-switcher select{
    cursor: pointer;
}

.affiliate-main .account-switcher .Polaris-Select__Backdrop{
    display: none;
}
.affiliate-main .account-switcher .Polaris-Select__Content{
    background: none;
    border: none;
    padding: 0;
    min-height: auto;
}
.affiliate-main .account-switcher .Polaris-Select__SelectedOption{
    font-weight: bold;
}

.affiliate-main .account-switcher .Polaris-Select__Icon{
    position: relative;
    width: 2rem;
    height: 2rem;
}
.affiliate-main .account-switcher .Polaris-Select__Icon::after{
    content: '';
    position: absolute;
    border-right: 2px solid #4d4d4d;
    border-bottom: 2px solid #4d4d4d;
    width: 6px;
    height: 6px;
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    transform: rotate(45deg);
}

.affiliate-main .account-switcher .Polaris-Icon{
    display: none;
}

.affiliate-main .Polaris-Card__Section{
    padding: 2rem 0 0;
}

.affiliate-main .Polaris-Card__Section:nth-child(2){
    border-top: none;
    padding-top: 0;
}

.affiliate-main__tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    border-bottom: 2px solid #EFEFEF;
    margin-bottom: 2rem;
}

.affiliate-main__tabs--mobile {
    display: none;
}

.affiliate-main__tab-mobile-active {
    display: none;
}

.affiliate-main__tab {
    padding: 2rem 0;
    width: 20%;
    min-width: 12rem;
    /* max-width: 12rem; */

    margin-right: 1rem;

    text-align: center;
    color: #BEBEBE;

    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}

.affiliate-main__tab:hover {
    cursor: pointer;
}





.affiliate-tab__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3rem 3rem;
}

.affiliate-tab__stats {
    display: flex;
}

.affiliate-tab__stat-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 2rem;
}

.affiliate-tab__stat-item:first-child {
    padding-left: 0;
}

.affiliate-tab__stat-num {
    font-weight: bold;
    font-size: 2rem;
    line-height: 3.2rem;
    min-width: 3rem;
}

.affiliate-tab__stat-label {
    color: #637381;
    width: 100%;
    min-width: 3rem;
}

.affiliate-tab__link-button {
    display: block;
    border-radius: 50px;
    background-color: #ffa72c;
    color: #ffffff;
    font-size: 1.2rem;
    padding: 4px 16px;
    font-weight: bold;
    text-decoration: none;
}

.affiliate-facebook .affiliate-tab__link-button{
    background-color: #416AB0;
}
.affiliate-twitter .affiliate-tab__link-button{
    background-color: #1EA4F1;
}
.affiliate-youtube .affiliate-tab__link-button{
    background-color: #FF0000;
}

.affiliate-tab__link-button .Polaris-Icon{
    display: inline-block;
    vertical-align: bottom;
}
.affiliate-tab__link-button .Polaris-Icon__Svg{
    fill: #fff;
}

.affiliate-tab__post:first-child {
    padding: 1rem 4rem 2rem;
    background-color: #ffffff;
    transition: all 300ms ease-out;
}

.affiliate-tab__post {
    display: flex;

    height: 200px;
    border-bottom: 2px solid #efefef;
    padding: 2rem 4rem;
    background-color: #ffffff;
    transition: all 300ms ease-out;
}

 .affiliate-tab__empty{
    text-align: center;
 }

.affiliate-tab__post-expanded{
    height: auto;
    transition: all 300ms ease-out;
    background-color: #ffffff;
    padding: 2rem 0;
}

.affiliate-tab__post-info {
    padding: 0 2rem;
}

.affiliate-tab__fbpost-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 2rem;
}

.affiliate-tab__post-title {
    font-weight: bold;
}

.affiliate-tab__info-row {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
}

.affiliate-tab__reach-row {
    display: flex;
}

.affiliate-tab__reach-num {
    padding: 0 2rem 0 0;
    min-width: 6rem;
}

.affiliate-tab__post-date {
    font-size: .75rem;
    text-transform: uppercase;
    color: #b4b4b4;
}

.affiliate-tab__description {
    color: #9d9d9d;
}

.affiliate-tab__expanded-description {
    text-decoration: underline;
    color: #000000;
}

.affiliate-tab__video-thumb {
    max-width: 200px;
    height: 150px;
}

.affiliate-tab__ig-thumb {
    width: 150px;
    height: 150px;
}

.affiliate-tab__page-nums {
    padding: 0 .75rem;
}

.affiliate-tab__page-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 0;
}

.affiliate-tab__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 3rem 1.5rem;
}

.affiliate-tab__top img{
    width: 48px;
    height: 48px;
    border-radius: 100%;
    margin-right: 15px;
}

.affiliate-tab__top img.twitter-photo{
    border: 2px solid #1EA4F1;
}
.affiliate-tab__top img.facebook-photo{
    border: 2px solid #416AB0;
}

.affiliate-tab__select {
    min-width: 20rem;
}

.affiliate-tab__top .Polaris-Label__Text {
    color: #494949;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
}

.affiliate-tab__name {
    font-weight: bold;
    font-size: 2.4rem;
    color: #222222;
    margin-bottom: 0.6rem;
    width: 100%;
}

.affiliate-tab__user-info{
    display: flex;
    align-items: center;
}

.affiliate-tab__title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 12rem;
}

.affiliate-loading {
    min-width: 20rem;
}

.affiliate-tab__post-wrapper {
    min-width: 20rem;
}




.empty-affiliate__wrapper {
    max-width: 700px;
    margin: 0 auto;
    padding: 4rem;
    text-align: center;
}

.empty-affiliate__subheader {
    font-size: 2.25rem;
    line-height: 2.85rem;
}

.empty-affiliate__link-navlink {
    margin-top: 1rem;
}

.empty-affiliate__link {
    text-decoration: none;
}

.empty-affiliate__link-wrapper{
    margin: 1.2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.affiliate-detail-breadcrumb {
    padding: 15px;
}

.affiliate-detail-link{
    cursor: pointer;
}

.affiliate-detail-breadcrumb-img{
    height: 9px;
    width: 9px;
}





.affiliate-dashboard {
    width: 100%;
    padding-left: 3rem;
}

.affiliate-dashboard__graphs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
    margin-bottom: 6rem;
}

.affiliate-dashboard__graphs--mobile {
    display: none;
}

.affilaite-dashboard__filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    margin-bottom: 2rem;
}

.affilaite-dashboard__header {
    color: #494949;
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 2rem;
}




.affiliate-hype__layout{
    border-top: 1px solid #dfe3e8;
}

.affiliate-hype__section {
    margin: 2.5rem 4rem;
    max-width: 1080px;
    /* max-width: 100%; */
}

.affiliate-hype__rank-row {
    display: flex;
    justify-content: space-between;
    width: 26rem;
    padding: 1rem 0;
    border-top: 1px solid #e9f0f8;
}

.affiliate-hype__rank-col {
    display: flex;
    flex-direction: column;
}

.affiliate-hype__rank-val {
    font-size: 2rem;
    font-weight: bold;
}

.affiliate-hype__quality-eval {
    border: 1px solid #e9f0f8;
    border-radius: 3px;
    padding: 2rem 3.4rem;
    margin-left: 4rem;
}

.affiliate-hype__quality-desc {
    color: #7F8C97;
    font-size: 1.6rem;
    margin-bottom: 0.6rem;
}

.affiliate-hype__quality-title {
    font-size: 3rem;
    font-weight: bold;
}

.affiliate-hype__header {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.6rem;
}

.affiliate-hype__caption {
    color: #7f7f7f;
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

.affiliate-hype__section-score {
    background-color: #efefef;
    padding: 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
}

.affiliate-hype__score-wrapper {
    border-radius: 8px;
    background-color: #2dcc71;
    width: 70px;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.affiliate-hype__score {
    font-size: 3rem;
    color: #fff;
    font-weight: bold;
}

.affiliate-hype__score-of {
    color: #fff;
    font-size: 1rem;
}

.affiliate-hype__score-detail {
    margin: 1rem 0 0 2rem;
}

.affiliate-hype__eval {
    font-size: 1.75rem;
    font-weight: bold;
}

.affiliate-hype__section-audience {
    background-color: #efefef;
    padding: 1.5rem 2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.affiliate-hype__audience-row {
    display: flex;
}

.affiliate-hype__audience-location {
    flex: 1;
}

.affiliate-hype__audience-type {
    flex: 1;
}

.affiliate-hype__audience-demographic {
    flex: 1;
    margin-top: 1.5rem;
}

.affiliate-hype__overview-type {
    margin: 2rem 0;
    display: flex;
}

.affiliate-hype__overview-left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    margin-right: 1.5rem;
    flex: 2;
}

.affiliate-hype__overview-right {
    flex: 4;
    width: 100%;
}

.affiliate-hype__overview-title {
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 0.6rem;
}

.affiliate-hype__overview-help {
    font-size: 1rem;
    color: #CBCDD1;
    cursor: pointer;
}

.affiliate-hype__help-row {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.affiliate-hype__help-icon {
    height: 14px;
    margin: 0 0 0 0.5rem;
    cursor: pointer;
}

.affiliate-hype__help-section {
    background-color: #584375;
    color: #D6D6D6;
    font-size: 1rem;
    line-height: 1.36rem;
    padding: 1.2rem;
    border-radius: 6px;
    text-align: left;
}

.affiliate-hype__overview-eval {
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;
}

.affiliate-type__eval-color {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    margin-right: 0.5rem;
}

.affiliate-type__eval-color--verygood {
    background-color: #23A455;
}

.affiliate-type__eval-span--verygood {
    color: #23A455;
}

.affiliate-type__eval-color--bad {
    background-color: #E44235;
}

.affiliate-type__eval-color--good {
    background-color: #29C566;
}

.affiliate-type__eval-color--gray {
    background-color: #7B8894;
}

.affiliate-hype__overview-val {
    font-weight: bold;
    font-size: 2rem;
    margin-right: 0.5rem;
}

.affiliate-hype__overview-desc {
    color: #7F8C97;
}

.affiliate-hype__audience-interest {
    display: flex;
    margin: 0.6rem 0;
}

.affiliate-hype__interest-bar-row {
    display: flex;
    align-items: center;
}

.affiliate-hype__interest-name {
    width: 16rem;
}

.affiliate-hype__interest-bar {
    background-color: #EEF1F5;
    height: 1.2rem;
    width: 10rem;
    margin-left: 1rem;
    position: relative;
}

.affiliate-hype__interest-fill {
    background-color: #34135D;
    height: 1.2rem;
    position: absolute;
    z-index: 100;
}

.affiliate-hype__info-col {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
}

.affiliate-hype__info-row {
    display: flex;
    align-items: center;
}

.affiliate-hype__brands {
    display: flex;
    flex-wrap: wrap;
}

.affiliate-hype__brand {
    background-color: #EEF1F5;
    color: #7B8894;
    padding: 0.5rem 1.2rem;
    border-radius: 6px;
    margin: 0.4rem;
    font-size: 1.3rem;
}

.affiliate-hype__brand--selected {
    background-color: #7B8894;
    color: #ffffff;
}

.affiliate-hype__brand--total {
    color: #B5BCC4;
}



.affiliate-ig__locked-header {
    display: flex;
}

.affiliate-hype__locked-heading {
    color: #747474;
    font-size: 1.6rem;
    font-weight: bold;
}

.affiliate-hype__locked-cta {
    cursor: pointer;
    color: #fd2954;
    margin-left: 1rem;
}

.affiliate-hype__section-heading {
    font-weight: bold;
    font-size: 1.6rem;
}

.affiliate-hype__locked-section {
    background-color: #ffffff;
    width: 400px;
    margin: 2rem 2rem 2rem 0;
}

.affiliate-hype__locked-top {
    border-bottom: 2px solid #f0f0f0;
    padding: 1.2rem 0;
}

.affiliate-hype__locked-top-h {
    font-weight: bold;
    margin-left: 2rem;
}

.affiliate-hype__locked-preview {
    display: flex;
    margin: 1rem 2rem;
}

.affiliate-hype__locked-item {
    margin: 0.5rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.affiliate-hype__lock-icon {
    height: 14px;
}

.affiliate-hype__button-wrapper {
    margin: 1.5rem 0 2rem 2rem;
}

.affiliate-hype__locked-items {
    padding-bottom: 1rem;
}

.affiliate-hype__section-audience--flex {
    display: flex;
}

.affiliate-sidebar__invite-button {
    padding-top: 10px;
    width: 100%;
}

.affiliate-sidebar__invite-button .llama-button{
    font-size: 1.6rem;
    padding: .8rem 2.2rem;
    width: 90%;
    margin: 0 auto;
}

@media only screen and (min-width: 600px) {

    .affiliate-main__tab-facebook.affiliate-main__tab--active {
        color: #416AB0;
        border-bottom: 4px solid #416AB0;
    }
    
    .affiliate-main__tab-youtube.affiliate-main__tab--active {
        color: #FC0011;
        border-bottom: 4px solid #FC0011;
    }
    
    .affiliate-main__tab-twitter.affiliate-main__tab--active {
        color: #1EA4F1;
        border-bottom: 4px solid #1EA4F1;
    }
    
    .affiliate-main__tab-instagram.affiliate-main__tab--active {
        color: #FB584C;
        border-bottom: 4px solid #FB584C;
    }
    
    .affiliate-main__tab-tiktok.affiliate-main__tab--active {
        color: #69C9D0;
        border-bottom: 4px solid #69C9D0;
    }

    .affiliate-main__tab-dashboard.affiliate-main__tab--active {
        color: #FB2956;
        border-bottom: 4px solid #FB2956;
    }

}

@media only screen and (max-width: 1064px) {

    .affiliate-main .account-switcher{
        margin: -2.5rem -2.5rem 2rem -2.5rem;
        padding: 1.2rem 2rem;
    }

    .affiliate-detail-breadcrumb{
        padding-left: 0;
        padding-top: 0;
    }

    .empty-affiliate__wrapper{
        padding: 2rem 0;
    }

    .affiliate-detail__wrapper {
        /* flex-wrap: wrap; */
        flex-direction: column;
    }

    .affiliate-sidebar__sidebar {
        width: 100%;
    }

    .affiliate-sidebar__avatar {
        margin-bottom: 2rem;
    }

    .affiliate-sidebar__name {
        font-size: 2rem;
        margin-bottom: 0.5rem;
    }

    .affiliate-sidebar__sidebar-section-top {
        padding: 2rem 1rem;
    }

    .affiliate-sidebar__sidebar-section {
        padding: 2rem 1rem;
    }

    .affiliate-sidebar__seciton-header {
        font-size: 1.75rem;
        margin-bottom: 1rem;
    }

    .affiliate-sidebar__table-header {
        margin-bottom: 0;
        font-size: 1.4rem;
    }

    .affiliate-sidebar__table-body {
        font-size: 1.4rem;
    }

    .affiliate-sidebar__sidebar-section-quicklook {
        display: none;
    }

    .affiliate-dashboard__quicklook-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
    }




    .affiliate-main__tabs {
        display: none;
    }

    .affiliate-main__tabs--mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: -2rem;
        margin-bottom: 2rem;
    }

    .affiliate-main__tab-mobile-active {
        color: #FB2956;
        border-bottom: 4px solid #FB2956;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 0rem 1.5rem;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 2.5rem;
    }

    .affiliate-main__tab--arrow {
        height: 12px;
        transition: all 300ms ease-in-out;
    }

    .affiliate-main__tab--arrow-flipped {
        transform: rotate(180deg);
        transition: all 300ms ease-in-out;
    }

    .affiliate-main__tab {
        width: 100%;
        margin-right: 0;
        border-bottom: 1px solid #EFEFEF;
    }

    .affiliate-main .Polaris-Card__Section:first-child{
        padding-top: 0;
    }

    .affiliate-tab__name {
        font-size: 2rem;
    }

    .affiliate-tab__row {
        flex-wrap: wrap;
    }

    .affiliate-tab__stat-num {
        font-size: 1.75rem;
    }

    .affiliate-tab__post {
        flex-direction: column;
        height: auto;
    }

    .affiliate-tab__thumb-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .affiliate-tab__ig-thumb {
        width: 86%;
        height: auto;
    }

    .affiliate-tab__description {
        margin: 1rem 0 0 0;
        font-size: 1.2rem;
    }

    .affiliate-tab__stat-label {
        font-size: 1.4rem;
    }

    .affiliate-tab__reach-num {
        font-size: 1.2rem;
    }


    .empty-affiliate__subheader {
        font-size: 2rem;
    }

    .empty-affiliate__llama-head {
        width: 120px;
    }

    .affiliate-tab__top{
        padding: 0;
    }

    .affiliate-tab__row{
        padding: 0 0 2.5rem;
        margin: 0;
    }

    .affiliate-tab__stats{
        width: 100%;
        /* white-space: nowrap */
    }

    .affiliate-tab__stat-item{
        margin: 1rem 0;
        width: auto;
        padding: 1rem 1.5rem;
    }

    .affiliate-tab__stat-item:last-child{
        padding-right: 0;
    }

    .affiliate-tab__link{
        width: 100%;
    }

    .affiliate-hype__section{
        margin-left: 0;
        margin-right: 0;
    }

    .affiliate-hype__section-score {
        padding: 1rem 1.5rem;
    }
    .affiliate-hype__score-wrapper {
        width: auto;
        height: auto;
        padding: 1.3rem 1.5rem;
    }

    .affiliate-hype__interest-name {
        font-size: 1.2rem;
    }

    .affiliate-hype__region-percent {
        font-size: 1.2rem;
    }

    .affiliate-hype__section-audience {
        padding: 1.2rem 1rem;
    }


    /* .affiliate-hype__info-row {
        flex-direction: column;
    } */

    .affiliate-dashboard__graphs--mobile {
        display: flex;
    }

    .affiliate-dashboard__graphs {
        display: none;
    }

    .affiliate-hype__overview-left{
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }
    .affiliate-hype__overview-title{
        margin-right: 1rem;
    }
    .affiliate-hype__help-row{
        margin-bottom: 0.6rem;
    }
}

@media only screen and (max-width: 830px) {
    .affiliate-main .account-switcher{
        flex-wrap: wrap;
    }
    .affiliate-main .account-switcher .num-pages{
        width: 100%;
        padding-bottom: 1rem;
        margin-bottom: 0.5rem;
    }
}

@media only screen and (max-width: 600px) {
    .affiliate-tab__stats{
        /* justify-content: center; */
    }
    /* .affiliate-tab__stat-num,
    .affiliate-tab__stat-label{
        margin: 0 auto;
        text-align: center;
    } */
    .affiliate-tab__link-button{
        text-align: center;
        /* margin: 1rem auto; */
        padding: .6rem 1.5rem;
        /* width: 90%; */
    }

    .affiliate-main .account-switcher .num-pages{
        border-bottom: 1px solid #E0E0E0;
    }
}

@media only screen and (max-width: 490px) {
    .affiliate-tab__stats{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .affiliate-tab__stats .affiliate-tab__stat-item{
        margin-bottom: 0;
        padding-left: 0;
    }

    .affiliate-tab__top{
        flex-direction: column;
        align-items: flex-start;
    }

    .affiliate-tab__link-button{
        margin-top: 1.5rem;
    }
}

@media only screen and (max-width: 400px) {
    .affiliate-tab__link-button{
        text-align: center;
        width: 100%;
    }
}