.LlamaSelect {
    margin: 0 2rem;
    cursor: pointer;
}

.LlamaSelect:first-child {
    margin-left: 0;
}

.LlamaSelect:last-child {
    margin-right: 0;
}

.LlamaSelect .Polaris-Select__Content {
    font-weight: bold;
    color: #222222;
    padding: 0;
    border: none;
    line-height: 2rem;
    min-height: auto;
}

.LlamaSelect .Polaris-Select {
    border-bottom: 2px solid transparent;
    cursor: pointer;
}

.LlamaSelect .Polaris-Select:hover {
    border-bottom: 2px solid #FB2956;
}

.LlamaSelect .Polaris-Select__Backdrop {
    display: none;
}

.LlamaSelect .Polaris-Select--disabled:hover {
    border-bottom: 2px solid transparent;
}