.InviteSetup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;
}

.InviteSetup__Form,
.InviteSetup__Preview {
    flex: 1;
    display: block;
}

.InviteSetup__Form {
    padding-right: 2rem;
}
.InviteSetup__Preview {
    padding-left: 2rem;
    max-width: 460px;
}

.InviteSetup__Heading {
    width: 100%;
    background-color: #F1F1F1;
    color: #7e8f9f;
    text-transform: uppercase;
    padding: .3rem 1.5rem;
}

.InviteSetup__Form fieldset{
    border: none;
    padding: 0;
    padding-top: 1rem;
    margin-bottom: 2rem;
}

.InviteSetup .Polaris-Label__Text {
    color: #494949;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 1.2rem;
    display: block;
}

.InviteSetup__OffersNullState {
    color: #494949;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 1.2rem;
    display: block;
}

.InviteSetup__CreateOfferContainer {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}

.InviteSetup__InputSection.slug {
    padding-top: 1rem
}
.InviteSetup__InputSection.shopify-promo {
    border-bottom: 1px solid #F1F1F1;
    margin-bottom: 1rem;
}

.InviteSetup__InputSection.shopify-promo .recruit-cta {
    background: #EEF9F9;
    border-radius: .5rem;
    padding: 1.5rem 2rem;
}

.InviteSetup__InputSection.shopify-promo .recruit-cta p {
    margin-bottom: 2rem;
    color: #637381;
}

.InviteSetup__InputSection.shopify-promo .recruit-cta p.heading {
    margin-bottom: .6rem;
    color: #212B36;
    font-weight: bold;
    font-size: 1.6rem;
}

.InviteSetup__InputSection.shopify-promo .recruit-cta button {
    margin-top: 0.9rem;
    font-size: 1.2rem;
    font-weight: bold;
}

.InviteSetup__recruit-video {
    width: 100%;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.15);
    margin-bottom: 2rem;
    border-radius: .5rem;
}

.InviteSetup__InputSection {
    padding: 1rem 1.5rem;
}

.InviteSetup__InputSectionRow {
    display: flex;
}

.InviteSetup__slugError {
    color: red;
}

.InviteSetup__ImageUpload {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
 }

.InviteSetup__ImageUpload img {
    max-height: 60px;
    max-width: 190px;
}

.InviteSetup__ImageUpload span {
    color:#494949;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    display: block;
}

.InviteSetup__BrandColor{
    position: relative;
}

.InviteSetup__BrandColor .Polaris-TextField__Input{
    padding-left: 3.8rem;
    max-width: 130px;
}

.InviteSetup__BrandColor .color-swatch{
    position: absolute;
    border-radius: 3px;
    border: 1px solid #c4cdd5;
    width: 2.4rem;
    height: 2.4rem;
    z-index: 40;
    top: 4rem;
    left: 2.2rem;
}

.InviteSetup__BrandColor .chrome-picker{
    position: absolute;
    left: 1.5rem;
    top: 7.5rem;
    z-index: 200;
    margin-bottom: 2rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px !important;
}

/* hide color picker mode selection */
/* .InviteSetup__BrandColor .chrome-picker .flexbox-fix:nth-child(2){
    display: none !important;
} */
/* hide swatch circle */
.InviteSetup__BrandColor .chrome-picker .flexbox-fix:nth-child(1) > div:nth-child(1){
    display: none !important;
}
.InviteSetup__BrandColor .chrome-picker .flexbox-fix .flexbox-fix:nth-child(1) > div:nth-child(1){
    display: block !important;
}
/* decrease margin bottom from hue slider */
.InviteSetup__BrandColor .chrome-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:nth-child(1){
    margin-bottom: 4px !important;
}
/* round out hue slider */
.InviteSetup__BrandColor .chrome-picker .hue-horizontal{
    border-radius: 20px;
}

.InviteSetup__InputSection:last-child {
    border-bottom: none;
}

.InviteSetup__PreviewSection {
    padding: 2rem 1.5rem;
}

.InviteSetup__PreviewURL {
    text-decoration: none;
    color: #FB2955;
    cursor: pointer;
}

/* .InviteSetup__PreviewURL:hover {
    text-decoration: none;
    color: #FB2955;
} */

.InviteSetup__FieldDescription {
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-top: 1rem;
}

.InviteSetup__PreviewButton {
    display: none;
}

.InviteSetup__Form .availability,
.InviteSetup__Form .availability-loading{
    display: block;
    margin-top: 1rem;
    font-weight: bold;
    position: relative;
}

.InviteSetup__Form .availability-loading .Polaris-Spinner{
    margin-right: .5rem;
    vertical-align: middle;
}

.InviteSetup__Form .availability[data-is-available="true"]{
    color: #009e22;
    padding-left: 1.6rem;
}
.InviteSetup__Form .availability[data-is-available="true"]::before{
    content: '';
    width: 11px;
    height: 6px;
    border-left: 2px solid #009e22;
    border-bottom: 2px solid #009e22;
    transform: rotate(-45deg);
    position: absolute;
    top: 5px;
    left: 0;
}

.InviteSetup__Form .availability[data-is-available="false"]{
    color: #df0000;
    padding-left: 1.8rem;
}
.InviteSetup__Form .availability[data-is-available="false"]::before,
.InviteSetup__Form .availability[data-is-available="false"]::after{
    content: '';
    width: 12px;
    height: 2px;
    background: #df0000;
    position: absolute;
    top: 9px;
    left: 0;
}
.InviteSetup__Form .availability[data-is-available="false"]::before{
    transform: rotate(45deg);
}
.InviteSetup__Form .availability[data-is-available="false"]::after{
    transform: rotate(-45deg);
}

.InviteSetup__MerchantCardWrapper{
    position: relative;
    padding: 2.5rem 0;
    background: #f4f6f8;
}

.InviteSetup__MerchantCardWrapper .backdrop{
    position: absolute;
    width: 100%;
    height: 14rem;
    background: #000;
    top: 0;
    left: 0;
    clip-path: ellipse(72% 100% at 50% 0)
}

.InviteSetup__Preview .merchant-invite-card{
    border: 1px solid #dce5ed;
    box-shadow: 0 0 11px rgba(0, 0, 0, .15);
    margin: 0 2rem;
    background: #fff;
}

.InviteSetup__MerchantCardWrapper .merchant-invite-card{
    margin: 0 2rem;
    background: #fff;
}

@media screen and (max-width: 1033px) {
    .InviteSetup__Form{
        padding-right: 1.2rem;
    }
    .InviteSetup__Preview{
        padding-left: 1.2rem;
    }
}

@media screen and (max-width: 1050px) and (min-width: 800px){
    .InviteSetup{
        flex-direction: column;
    }

    .InviteSetup__Form,
    .InviteSetup__Preview{
        padding: 0;
        max-width: 60rem;
        margin: 0 auto 2rem;
        width: 100%;
    }

    .InviteSetup__Preview .merchant-invite-card{
        width: calc(100% - 3rem);
        margin: 0 auto;
    }
}

@media screen and (max-width: 799px) {
    .InviteSetup__Preview {
        display: none;
    }

    .InviteSetup__MerchantCardWrapper .merchant-invite-card{
        margin: 0 auto;
    }

    .InviteSetup__Preview--Mobile {
        display: block;
        /* padding: 0.6rem; */
    }

    .InviteSetup__ModalLabel {
        color: #494949;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.2rem;
    }

    .InviteSetup__PreviewButton {
        display: block;
    }
}