.OfferCoupons__Heading {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.OfferCoupons__Heading[data-has-coupons="false"]{
    margin-bottom: .5rem;
}

.OfferCoupons__Heading .Polaris-Heading {
    margin-bottom: 1rem;
}

.OfferCoupons__Form .selected-ambassadors{
    margin: .5rem 0 1rem;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    gap: 1rem;
}

.OfferCoupons__InputField {
    margin: 0 0 2rem 0;
    flex: 1;
}

.OfferCoupons__ValueInput {
    margin: 0 1rem 2rem 0;
}

.OfferCoupons__Product{
    margin-bottom: 2rem;
}

.OfferCoupons__ValueInput:last-child {
    margin-right: 0;
}

.OfferCoupons__Value {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.OfferCoupons__InputField .Polaris-Label__Text,
.OfferCoupons__Product .Polaris-ChoiceList__Title {
    color: #494949;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    display: block;
}

/* coupons table */
.OfferCoupons__coupons-table {
    width: 100%;
    border-spacing: 0;
    border-radius: .5rem;
    border: 2px solid #34135D;
}
.OfferCoupons__coupons-table * {
    border-collapse: collapse;
    border: none;
    line-height: normal;
}
.OfferCoupons__coupons-table th {
    text-transform: uppercase;
    text-align: left;
    font-size: 1.2rem;
    color: #fff;
    background: #34135D;
    line-height: normal;
}

.OfferCoupons__coupons-table th,
.OfferCoupons__coupons-table td{
    padding: 1rem;
    vertical-align: top;
}

.OfferCoupons__coupons-table td{
    padding: .9rem 1rem;
}

.OfferCoupons__coupons-table tr:hover td{
    background: #f0f0f0;
}

.OfferCoupons__coupons-table .code{
    font-weight: bold;
}

.OfferCoupons__coupons-table .validity,
.OfferCoupons__coupons-table .products{
    font-size: 1.15rem;
    margin-top: 0.2rem;
    color: #637381;
}

.OfferCoupons__coupons-table .details{
    white-space: nowrap;
}

.OfferCoupons__coupons-table tr[data-expired="true"]{
    opacity: .5;
}

.OfferCoupons__Empty {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.OfferCoupons__ErrorBanner {
    margin-bottom: 2rem;
}

.OfferCoupons__Submit {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.OfferCoupons__NoAffiliates {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
}

.OfferCoupons__LlamaImage {
    width: 10rem;
    height: auto;
    margin-bottom: 2rem;
}

.OfferCoupons__NoAffiliatesMessage {
    margin-bottom: 2rem;
}

.OfferCoupons__Form {
    padding: 1rem 0 3rem 3rem;
    border-left: 1px solid #E0E0E0;
    width: 33rem;
}

.OfferCoupons__Wrapper {
    display: flex;
    flex-direction: row;
}

.OfferCoupons__Data {
    padding: 1rem 3rem 2rem 0;
    width: calc(100% - 33rem);
}

.OfferCoupons__InputTime {
    margin:  2rem 0 2rem 0;
}

@media only screen and (min-width: 1440px){
    .OfferCoupons__Form{
        width: auto;
        flex: 2
    }
    .OfferCoupons__Data {
        width: auto;
        flex: 3;
    }
}

@media only screen and (max-width: 1390px) {
    .OfferCoupons__Wrapper {
        flex-direction: column;
    }

    .OfferCoupons__Data,
    .OfferCoupons__Form {
        width: 100%;
    }

    .OfferCoupons__Codes {
        margin-bottom: 0;
    }

    .OfferCoupons__Data{
        padding-right: 0;
    }

    .OfferCoupons__Form {
        border-left: none;
        padding-left: 0;
    }

    .OfferCoupons__Heading[data-has-coupons="false"]{
        display: none;
    }
}

@media only screen and (max-width: 1050px){
    .OfferCoupons__coupons-table{
        border: none;
        max-height: 436px;
        overflow-y: auto;
    }
    
    .OfferCoupons__coupons-table thead{
        display: none;
    }

    .OfferCoupons__coupons-table tr{
        display: block;
        border-bottom: 1px solid #dfe3e8;
        padding: 1rem 0;
    }

    .OfferCoupons__coupons-table tr:last-child{
        border-bottom: none;
    }

    .OfferCoupons__coupons-table th,
    .OfferCoupons__coupons-table td{
        padding: 0;
    }

    .OfferCoupons__coupons-table td{
        margin-bottom: .5rem;
        display: block;
    }

    .OfferCoupons__coupons-table td.code{
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .OfferCoupons__coupons-table .ambassador:before{
        content: 'Assigned To: ';
        margin-right: 0.4rem;
    }

    .OfferCoupons__coupons-table .details{
        white-space: normal;
    }

    .OfferCoupons__coupons-table .discount-value{
        display: inline;
    }

    .OfferCoupons__coupons-table .discount-value:before{
        content: 'Details: ';
        margin-right: 0.4rem;
    }

    .OfferCoupons__coupons-table .products{
        color: #212B36;
        font-size: 1.4rem;
        margin-top: 0;
        display: inline;
    }

    .OfferCoupons__coupons-table .products:before{
        content: ', ';
    }
}

@media only screen and (max-width: 600px) {
    .OfferCoupons__Data,
    .OfferCoupons__Form{
        padding: 2rem 0;
    }
}

@media only screen and (max-width: 450px) {
    .OfferCoupons__Submit button{
        width: 100%;
        padding: 1rem;
    }
}