.campaign-name {
    padding: 24px;
}

.campaign-name__heading {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-transform: uppercase;
}

.campaign-name__input-field {
    margin-bottom: 2rem;
    width: 99.8%;
}

.campaign-name__input-field:last-child {
    margin-bottom: 0;
}

.campaign-name__field-description {
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #29323d;
    margin-top: 0.6rem;
}

.form-header {
    display: flex;
    justify-content: space-between;
}