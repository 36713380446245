.Performance {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
}

.PerformanceMain {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.PerformanceMain__Header {
    padding: 4rem 2rem;
    font-size: 2rem;
    line-height: 1.4em;
    font-weight: normal;
    text-align: center;
}

.PerformanceMain__Header span {
    font-weight: bold;
}

/* LIST OF REPORTS */
#performance-reports, #performance-reports ul{
    list-style: none;
    padding-left: 0;
}

#performance-reports{
    margin-right: 3rem;
    margin-bottom: 3rem;
}

/* INDIVIDUAL REPORT */
#performance-reports > li{
    box-shadow: 0 0 7px rgba(0, 0, 0, .15);
    border: 1px solid #DCE5ED;
    border-radius: 7px;
    margin-bottom: 4rem;
}

#performance-reports > li:last-child{
    margin-bottom: 0;
}

/* AMBASSADOR HEADER BAR */
#performance-reports .ambassador-name-header{
    background: #EBECF0;
    border-radius: 5px 5px 0 0;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
}

#performance-reports .ambassador-name-header .ambassador-name{
    margin-right: auto;
    line-height: 1.6em;
}

#performance-reports .ambassador-name-header .ambassador-name span{
    font-weight: bold;
    display: block;
    font-size: 1.4em;
}

#performance-reports .ambassador-name-header img{
    width: 5rem;
    height: 5rem;
    margin-right: 1.2rem;
    vertical-align: middle;
}

/* UNDER AMBASSADOR HEADER BAR */
#performance-reports .report{
    display: flex;
}

/* OFFERS LIST */
#performance-reports .offers{
    width: 216px;
    border-right: 1px solid #D6D6D6;
    padding-bottom: 3rem;
}

#performance-reports .offers .current-offer{
    display: none;
}

#performance-reports .offers h4{
    text-transform: uppercase;
    font-weight: bold;
    padding: 1.9rem 1.6rem .7rem;
}

#performance-reports .offers li{
    padding: .7rem 1.5rem;
    font-size: 1.2rem;
    cursor: pointer;
}

#performance-reports .offers li[data-selected="true"]{
    font-weight: bold;
}

#performance-reports .offers li[data-selected="true"],
#performance-reports .offers li:hover{
    background: #EBECF0;
    position: relative;
}

#performance-reports .offers li[data-selected="true"]::after{
    content: '';
    position: absolute;
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    width: 6px;
    height: 6px;
    transform: rotate(-45deg);
    top: calc(50% - 3px);
    right: 12px;
}

/* STATS */
#performance-reports .stats{
    padding: 2rem;
    width: 100%;
}

#performance-reports .offers + .stats{
    width: calc(100% - 216px);
}

/* EMPTY STATE OVERRIDE (PER REPORT) */
#performance-reports .stats .empty-state__subheader{
    font-size: 1.9rem;
}
#performance-reports .stats .empty-state__paragraph{
    font-size: 1.4rem;
    margin-top: 0.3rem;
}

/* OVERVIEW */
#performance-reports .stats h2{
    font-weight: bold;
    font-size: 1.6rem;
}

#performance-reports .stats .date-range{
    font-size: 1.2rem;
    color: #637481;
    margin-bottom: 3rem;
}

/* GRAPH TYPE SELECT BOX OVERRIDE */
#performance-reports .stats .graph-heading{
    position: relative;
}

#performance-reports .stats .graph-type{
    position: absolute;
    top: -3px;
    right: 0;
}

#performance-reports .stats .graph-type div{
    display: flex;
    align-items: center;
}

#performance-reports .stats .graph-type .Polaris-Labelled__LabelWrapper{
    margin-bottom: 0;
    margin-right: 6px;
}

#performance-reports .stats .graph-type .Polaris-Label__Text{
    font-size: 1.2rem
}
#performance-reports .stats .graph-type .Polaris-Select__Content{
    padding: 0 1.2rem 0 0;
    border: none;
    box-shadow: none;
    font-size: 1.2rem;
    font-weight: bold;
    position: relative;
    min-height: 0;
}
#performance-reports .stats .graph-type .Polaris-Select__Content::after{
    content: '';
    position: absolute;
    top: calc(50% - 4px);
    right: 0;
    width: 6px;
    height: 6px;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    transform: rotate(-45deg);
}

#performance-reports .stats .graph-type .Polaris-Select__Backdrop,
#performance-reports .stats .graph-type .Polaris-Select__Icon{
    display: none;
}

/* BAR GRAPH WRAPPER */
#performance-reports .bar-graph{
    width: 100%;
    height: 120px;
    margin-bottom: 3rem;
}

/* BREADOWN */
#performance-reports .breakdown{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 7rem;
}

#performance-reports .breakdown h3{
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 3px;
}

#performance-reports .breakdown p{
    font-size: 1.2rem;
    color: #637481;
    line-height: 1.4em;
}

#performance-reports .breakdown ul{
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
}

#performance-reports .breakdown li{
    font-weight: bold;
    display: flex;
    flex-direction: column; 
}

#performance-reports .breakdown span{
    text-transform: uppercase;
    font-size: 1rem;
    margin-left: 0;
    margin-bottom: 1.5rem;
    font-weight: normal;
    order: 1;
    line-height: 1.5em;
}

#performance-reports .breakdown .info-tooltip{
    text-transform: lowercase;
    margin-bottom: 0;
    line-height: 1rem;
    font-weight: bold;
    vertical-align: super;
    cursor: default;
}

/* PAGINATION */
.PerformanceMain .llama-pagination{
    width: auto;
    margin-right: 3rem;
}

/* thinner offer column */
@media only screen and (max-width: 1370px) and (min-width: 1221px),
    screen and (max-width: 1118px){
    #performance-reports .offers{
        width: 170px;
    }
    #performance-reports .offers + .stats{
        width: calc(100% - 170px);
    }
    #performance-reports .breakdown{
        column-gap: 4rem;
    }
}

/* graph type select box wrap */
@media only screen and (max-width: 1250px) and (min-width: 1221px),
            screen and (max-width: 1010px) and (min-width: 991px),
            screen and (max-width: 820px){
    #performance-reports .stats .date-range{
        margin-bottom: 1.5rem;
    }
    #performance-reports .stats .graph-type div{
        justify-content: flex-end
    }
    #performance-reports .stats .graph-type{
        position: static;
        margin-bottom: 0rem;
    }
}

/* one column */
@media only screen and (max-width: 1220px) {
    .Performance{
        flex-direction: column;
    }
    .PerformanceMain{
        order: 1;
    }
    .PerformanceMain__Header{
        padding-bottom: 1rem;
    }
    #performance-reports{
        margin-right: 0;
    }
}

/* offers across the top */
@media only screen and (max-width: 990px) {
    #performance-reports .report{
        flex-direction: column;
    }
    #performance-reports .offers,
    #performance-reports .offers + .stats{
        width: 100%;
    }

    #performance-reports .offers{
        border-right: none;
        padding-bottom: 0;
    }

    #performance-reports .offers h4{
        display: none;
    }

    #performance-reports .offers .current-offer{
        display: block;
    }
    
    #performance-reports .offers .current-offer button{
        padding: 1.3rem 2rem;
        width: 100%;
        text-align: left;
        border: none;
        border-bottom: 1px solid #D6D6D6;
        border-top: 1px solid #D6D6D6;
        background: #f3f3f3;
        position: relative;
    }

    #performance-reports .offers .current-offer button::after{
        content: '';
        position: absolute;
        border-left: 2px solid #000;
        border-bottom: 2px solid #000;
        width: 6px;
        height: 6px;
        transform: rotate(-45deg);
        top: calc(50% - 3px);
        right: 2rem;
    }

    #performance-reports .offers ul{
        display: none;
    }
    #performance-reports .offers ul[data-open="true"]{
        display: block;
        margin-bottom: 0.7rem;
    }

    #performance-reports .offers li{
        padding: 0.7rem 2rem;
        border-bottom: 1px solid #D6D6D6;
    }

    #performance-reports .offers li[data-selected="true"]{
        background: none;
    }

    #performance-reports .offers li[data-selected="true"]::after{
        display: none;
    }
}

/* slightly smaller fonts and avatar */
@media only screen and (max-width: 830px) {
    .PerformanceMain__Header{
        font-size: 1.7rem;
    }
    #performance-reports .ambassador-name-header{
        padding: 1.3rem 1.5rem;
    }
    #performance-reports .ambassador-name-header .ambassador-name{
        font-size: 1.3rem;
    }
    #performance-reports .ambassador-name-header img{
        width: 4rem;
        height: 4rem;
    }
}

/* profile button wrap */
@media only screen and (max-width: 768px) {
    #performance-reports .ambassador-name-header{
        flex-wrap: wrap;
    }
    #performance-reports .ambassador-name-header .ambassador-name{
        width: calc(100% - 5.2rem);
    }
    #performance-reports .ambassador-name-header button{
        margin-top: 1rem;
        margin-left: 5.2rem;
    }
}

/* 2 column breakdown */
@media only screen and (max-width: 768px) and (min-width: 721px),
            screen and (max-width: 599px) and (min-width: 481px){
    #performance-reports .breakdown{
        grid-template-columns: repeat(2, 1fr);
    }

    #performance-reports .breakdown section:last-child {
        padding-top: 2rem;
        border-top: 1px solid #D6D6D6;
        margin-top: 1rem;
        grid-column: 1 / 3;
    }

    #performance-reports .breakdown ul{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2.2rem;
    }
    
    #performance-reports .breakdown li:last-child{
        margin-right: 0;
    }
}

/* 1 column breakdown */
@media only screen and (max-width: 720px) and (min-width: 600px), 
            screen and (max-width: 480px){
    #performance-reports .breakdown{
        grid-template-columns: 1fr;
    }

    #performance-reports .breakdown ul{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    #performance-reports .breakdown section{
        padding-bottom: 1rem;
        border-bottom: 1px solid #D6D6D6;
        margin-bottom: 2rem;
    }
    #performance-reports .breakdown section:last-child{
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: .5rem;
    }
}