/*PROGRESS BAR*/
.Polaris-ProgressBar__Indicator {
     background-color:#fd2954 !important;
}

/*MAIN ONBOARDING TEMPLATE STYLES*/
.Onboarding__Card {
    margin-top: 3rem;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 1rem 2rem;
 }
 
 .Onboarding__Llama {
    height: 150px;
    width: auto;
 }
 
 .Onboarding__StepTracker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 2rem;
    color: #939393;
 }

 .Onboarding__BackButton{
    height: 25px;
    width: auto;
    transform: rotate(180deg);
 }

 .Onboarding__Header {
    font-size: 3rem;
    margin-bottom: 2rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.1em;
 }
 
 .Onboarding__Paragraph {
    font-size: 2rem;
    color: #404040;
    text-align: center;
    margin-bottom: 2rem;
    line-height: 1.25em;
 }

 .Onboarding__Paragraph--Stacked {
    font-size: 2rem;
    color: #404040;
    text-align: center;
    line-height: 1.25em;
    /* margin-bottom: 0.6rem; */
 }

 .Onboarding__Paragraph--left {
    line-height: 2.5rem;
    text-align: left;
 }

 .Onboarding__HiddenDiv {
     background-color: #f2f2f2;
     padding: 1.25rem;
     margin: 0.6rem 0;
 }

 .Onboarding__Paragraph--link {
    color: #ff2754;
    text-decoration: underline;
    cursor: pointer;
 }

 .Onboarding__SubHeader {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;
    margin-bottom: 0.6rem;
 }

 .Onboarding__Paragraph--bold {
     font-weight: bold;
 }

 .Onboarding__TextWrapper {
     max-width: 700px;
 }

 .Onboarding__ParagraphSmall {
     font-size: 1.5rem;
     color: #404040;
 }

 .Onboarding__ParagraphSmall--bold {
     font-weight: bold;
 }
 
 .Onboarding__SubParGender-Wrapper {
    max-width: 500px;
    text-align: center;
 }
 
 .Onboarding__SubParagraph {
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #404040;
 }

 .Onboarding__SubParagraph--left {
     text-align: left;
 }

 .Onboarding__SubParagraph--margin {
     margin-bottom: 0.6rem;
 }

 .Onboarding__SubParagraph--bold {
     font-weight: bold;
 }

 .Onboarding__DropZone {
    margin: 1rem 0;
 }

 .Onboarding__DropZone .Polaris-DropZone__Container{
    width: 200px;
}

.Onboarding__AvatarWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
}

.Onboarding__AvatarExample {
    background-color: #000000;
    color: #ffffff;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    font-size: 3rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
}

.Onboarding__AvatarName {
    font-size: 2rem;
}
 
 .Onboarding__MainCTA {
    background-color: #ff2754;
    display: block;
    text-decoration: none;
    color: #ffffff;
    text-align: center;
    padding: 2rem 4rem;
    border-radius: 50px;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
    margin: 2rem 0rem 1rem;
 }

 .Onboarding__SecondaryCTA {
    border: 2px solid #ff2754;
    color: #ff2754;
    display: block;
    text-decoration: none;
    text-align: center;
    padding: 2rem 4rem;
    border-radius: 50px;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
    margin: 2rem 0rem 1rem 1rem;
 }

 .Onboarding__ButtonGroup {
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .hello-llama-skip {
    text-decoration: underline;
    color: #101010;
    text-align: center;
    font-size: 12px;
    font-weight: 100;
    cursor: pointer;
    margin: 0 0 2rem 0;
    font-family: Helvetica, Arial, sans-serif;
}

/* lookback settings */
.hello-llama-lookback, 
.hello-llama-lookforward{
    display: none;
}

.hello-llama-lookback-labels{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
    padding-left: 0;
}

.hello-llama-lookforward-labels{
    max-width: 600px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 30px;
    padding-left: 0;
    margin: 30px 0;
}

.hello-llama-lookback-labels li{
    margin-right: 50px;
}

.hello-llama-lookback-labels li,
.hello-llama-lookforward-labels li{
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    width: 166px;
    height: 166px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    line-height: 40px;
    cursor: pointer;
}

.hello-llama-lookback-labels li:last-child{
    margin-right: 0;
}

.hello-llama-lookback-labels b,
.hello-llama-lookforward-labels b{
    font-size: 2.6em;
}

.hello-llama-lookback-labels li:hover, 
.hello-llama-lookback-labels li.selected,
.hello-llama-lookforward-labels li:hover, 
.hello-llama-lookforward-labels li.selected{
    background: #f0f4fa;
    border-color: #c4c4c4;
}

.hello-llama-lookback-example{
    background: #f2f2f2;
    margin: 0 0 30px;
    padding: 15px;
}

.hello-llama-lookback-example[data-show="false"]{
    display: none;
}

.hello-llama-lookback-example p:first-child{
    margin-bottom: 8px;
}

.hello-llama-message-sub a{
    color: #fc2c58;
}

.Onboarding__Card .settings-ltv__slider-wrap{
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.Onboarding__Card .breakdown{
    padding: 1.5rem;
}

.Onboarding__Card .breakdown h3{
    font-weight: bold;
    font-size: 1.4rem;
}
.Onboarding__Card .breakdown>h3{
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.Onboarding__lookforward-first{
    margin-bottom: 1.4rem;
}

@media screen and (min-width: 983px){
    .Onboarding__Card .settings-ltv__slider-wrap{
        width: 700px;
    }
}

@media screen and (max-width: 902px){
    .hello-llama-message-sub, .hello-llama-message{
        padding: 15px 0;
    }
    .hello-llama-lookback-example{
        margin: 0 0 30px
    }
}


 

 /*DISPLAY TOGGLES*/
.onboarding-display-none {
    display: none;
}

.onboarding-display-true{
    display: block;
}




 /*CATEGORIES*/
 .Onboarding__CategoriesChoiceList .Polaris-ChoiceList__Choices { 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 30px 50px 30px 60px;
 }    
 
 .Onboarding__CategoriesChoiceList li {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
 }
 
 .Onboarding__CategoriesChoiceList .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop {
    background: #fd2954;
 }
 
 .Polaris-Checkbox__Icon svg {
    fill: #fd2954 !important;
 }



/*RADIO BUTTONS*/

.Onboarding__RadioButtonsRow {
    margin-bottom: 1rem;
}

.Onboarding__RadioButtons {
    display: flex;
    align-items: center;
    margin: 2rem 0 1rem 0;
    /* padding: 25px; */
}

.Onboarding__RadioButtonsItem {
    padding: 1rem 1.75rem;
    display: flex;
    align-items: center;
    border: 1px solid #c4c4c4;
    font-weight: 400;
    font-size: 18px;
    max-width: 200px;
    flex: 1;
}

.Onboarding__RadioButtonsItem .Polaris-Choice__Control {
    width: 2.7rem;
    height: 2.7rem;
}

.Onboarding__RadioButtonsItem .Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop {
    background: #fd2954;
}

.Onboarding__RadioButtonsItem .Polaris-RadioButton__Icon {
    background: #fd2954;
    height: 60%;
    width: 60%;
}

.Onboarding__RadioButtonsItem .Polaris-Choice__Label {
    font-size: 2rem;
    font-weight: 300;
}



/*LLAMA CHAT*/
.Onboarding__ChatContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 1rem 0 0 0;
}

.Onboarding__ChatIcon {
    height: 50px;
    width: 50px;
}

.Onboarding__ChatBg{
    display: flex;
    flex-basis: row;
    justify-content: center;
    background-color: #e8e8e8;
    width: 350px;
    /*height: 60px;*/
    border-radius: 15px 15px 5px 5px;
    font-family: Helvetica, Arial, sans-serif;
    position: relative;
    margin-left: 10px;
} 

.Onboarding__ChatMessage {
    font-size: 12px;
    font-weight: 100;
    padding: 10px 15px;
    text-align: left;
    line-height: 18px;
}

@media screen and (max-width: 500px){
    .Onboarding__ChatBg{
        width: calc(100% - 50px - 10px);
    }
    .Onboarding__ChatContainer{
        align-items: center;
    }
}


/*PAYMENT SECTION*/
.Onboarding__PaypalWrapper {
    width: 430px;
    padding: 1.5rem;
    border: 3px solid #EBE8ED;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
 }

@media screen and (max-width: 799px) {
    .Onboarding__Header {
       font-size: 2.25rem;
       margin-bottom: 0.6rem;
    }
 
    .Onboarding__Paragraph {
       font-size: 1.75rem;
    }
 
    .Onboarding__CategoriesChoiceList .Polaris-ChoiceList__Choices {
       margin: 1rem;
    }
 
    .Onboarding__CategoriesChoiceList .Polaris-Choice__Label {
       font-size: 1.125rem;
       line-height: 1.5rem;
    }



    .Onboarding__RadioButtonsItem .Polaris-Choice__Label {
        font-size: 1.5rem;
    }

    .Onboarding__RadioButtonsItem .Polaris-Choice__Control {
        width: 2rem;
        height: 2rem;
    }



    .Onboarding__PaypalWrapper {
        width: 100%;
        padding: 1rem;
        margin-top: 1rem;
     }
}

/* @media (max-width: 720px) {

.Onboarding__DropZone {
    width: 500px;
    padding: 25px;
}

.hello-llama-skip {
    text-decoration: underline;
    color: grey;
    text-align: center;
    padding: 2rem 4rem;
    font-size: 12px;
    font-weight: 100;
    cursor: pointer;
}


.step2-example-image {
    width: 70%;
    height: 250px;

}

.hello-llama-back {
    height: 25px;
    width: 25px;
    transform: rotate(180deg);
    position: relative;
    top: 35px;
    right: 46%;
}

.template-download {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 10px;
}

.template-download-icon {
    height: 50px;
    width: 50px;
    padding: 5px;
}

.template-download-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
} */



@media screen and (max-width: 599px) {
    .Onboarding__Card {
        box-shadow: none;
        margin-top: 0rem;
     }
  
     .Onboarding__Llama {
        height: 100px;
     }
  
     .Onboarding__Header {
        font-size: 1.9rem;
        margin-bottom: 1rem;
     }

     .Onboarding__SubHeader {
         font-size: 1.75rem;
     }
     
     .Onboarding__Paragraph {
        font-size: 1.4rem;
        line-height: 1.3em;
        text-align: center;
     }

     .Onboarding__Paragraph--left {
        text-align: left;
     }
  
     .Onboarding__MainCTA {
        font-size: 2rem;
        padding: 1.25rem 3rem;
     }

     .Onboarding__ParagraphSmall {
         font-size: 1.2rem;
         line-height: 1.75rem;
     }

     .Onboarding__DropZone .Polaris-DropZone__Container {
        width: 100%;
     }
  
     .Polaris-Layout {
        margin-left: 0 !important;
        margin-top: 2rem;
     }
  
     .Onboarding__BackButton {
        height: 16px;
     }

     .Onboarding__AvatarWrapper {
         margin: 0.6rem 0;
     }

     .Onboarding__AvatarExample {
         width: 40px;
         height: 40px;
         font-size: 2rem;
     }

     .Onboarding__AvatarName {
         font-size: 1.5rem;
     }



  
     .Onboarding__CategoriesChoiceList li {
        width: 50%;
     }
  
     .Onboarding__CategoriesChoiceList .Polaris-ChoiceList__Choices {
        margin: 1rem;
     }


     .Onboarding__RadioButtonsItem .Polaris-Choice__Label {
         /* font-size: 1.2rem; */
         /* line-height: 1.2rem; */
     }

     .Onboarding__RadioButtonsItem {
        /* padding: 0.6rem 1rem; */
     }

     .hello-llama-lookback-example{
         font-size: 1.3rem;
         line-height: 1.35em;
     }
  
}